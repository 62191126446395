import { assertNever } from "../../utils/assertNever";
import { Sex } from "../WordBase";
import { LatinNoun, nominative } from "./LatinNoun";
import { LatinVerb } from "./LatinVerb";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface LatinAdjective {
  type: "adjective";
  latin: string;
  latinNS: [ string, string, string ];
  root: string;
  dutch: string;
  klass: 1 | "1r" | "1er" | 2;
}

export interface LatinFixedAdjective {
  type: "fixedAdjective";  // onverbuigbaar adjectief
  latin: string;
  dutch: string;
}

export interface LatinPluralAdjective {
  type: "pluralAdjective";
  latin: string;
  root: string;
  dutch: string;
  klass: 1 | "1r" | "1er" | 2;
}

export interface LatinConjugatedVerb {
  type: "conjugatedVerb";
  latin: string;
  dutch: string;
}

export interface LatinAdverb {
  type: "adverb";  // "bijwoord".
  latin: string;
  dutch: string;
}

export interface LatinConjunction {
  type: "conjunction";  // "voegwoord"
  latin: string;
  dutch: string;
}

export interface LatinNegation {
  type: "negation";  // "ontkenning"
  latin: string;
  dutch: string;
}

export interface LatinPassiveVerb {
  type: "passiveVerb";
  latin: string;
  dutch: string;
}

export interface LatinCountingWord {
  type: "countingWord";  // "telwoord"
  latin: string;
  conjugations?: [ string, string, string ];
  dutch: string;
}

export interface LatinQuestionParticle {
  type: "questionParticle";  // "vraagpartikel"
  latin: string;
  dutch: string;
}

export interface LatinIndefinitePronoun {
  type: "indefinitePronoun";  // "onbepaald voornaamwoord"
  latin: string;
  dutch: string;
  sex: Sex;
}

export interface LatinInterjection {
  type: "interjection";  // "tussenwerpsel"
  latin: string;
  dutch: string;
}

export interface LatinPersonalPronoun {
  type: "personalPronoun";  // "persoonlijk voornaamwoord"
  latin: string;
  dutch: string;
}

export interface LatinPossessivePronoun {
  type: "possessivePronoun";  // "bezittelijk voornaamwoord"
  latin: string;
  dutch: string;
}

export interface LatinInterrogativePronoun {
  type: "interrogativePronoun";  // "vragend voornaamwoord"
  latin: string;
  dutch: string;
}

export interface LatinDemonstrativePronoun {
  type: "demonstrativePronoun";  // "aanwijzend voornaamwoord"
  latin: string;
  dutch: string;
}

export interface LatinPrepositionAccusative {
  type: "prepositionAccusative";  // "voorzetsel (accusatief)"
  latin: string;
  dutch: string;
}

export interface LatinPrepositionAblative {
  type: "prepositionAblative";  // "voorzetsel (ablatief)"
  latin: string;
  dutch: string;
}

export interface LatinPrepositionAccusative {
  type: "prepositionAccusative";  // "voorzetsel (accusatief)"
  latin: string;
  dutch: string;
}

export interface LatinToFixIrregularWord {
  type: "toFixIrregularWord";  // "onregelmatig voornaamwoord", TODO: not clear!!!
  latin: string;
  dutch: string;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type LatinWord =
  | LatinNoun
  | LatinAdjective
  | LatinFixedAdjective
  | LatinPluralAdjective
  | LatinConjugatedVerb
  | LatinAdverb
  | LatinConjunction
  | LatinNegation
  | LatinVerb
  | LatinPassiveVerb
  | LatinCountingWord
  | LatinQuestionParticle
  | LatinIndefinitePronoun
  | LatinInterjection
  | LatinPersonalPronoun
  | LatinPossessivePronoun
  | LatinInterrogativePronoun
  | LatinDemonstrativePronoun
  | LatinPrepositionAccusative
  | LatinPrepositionAblative
  | LatinToFixIrregularWord
  ;

export function toDutchType(word: LatinWord): string {
  switch (word.type) {
    case "noun":
      return "Substantief";
    case "adjective":
      return "Adjectief";
    case "fixedAdjective":
      return "Onverbuigbaar adjectief";
    case "pluralAdjective":
      return "Adjectief (altijd meervoud)";
    case "conjugatedVerb":
      return "Vervoegd werkwoord";
    case "adverb":
      return "Bijwoord";
    case "conjunction":
      return "Voegwoord";
    case "negation":
      return "Ontkenning";
    case "verb":
      return "Werkwoord";
    case "passiveVerb":
      return "Werkwoord (?)";
    case "countingWord":
      return "Telwoord";
    case "questionParticle":
      return "Vraagpartikel";
    case "indefinitePronoun":
      return "Onbepaald voornaamwoord";
    case "interjection":
      return "Tussenwerpsel";
    case "personalPronoun":
      return "Persoonlijk voornaamwoord";
    case "possessivePronoun":
      return "Bezittelijk voornaamwoord";
    case "interrogativePronoun":
      return "Vragend voornaamwoord";
    case "demonstrativePronoun":
      return "Aanwijzend voornaamwoord";
    case "prepositionAccusative":
      return "Voorzetsel (accusatief)";
    case "prepositionAblative":
      return "Voorzetsel (ablatief)";
    case "toFixIrregularWord":
      return "Nog te fixen (?)";
    default:
      return assertNever(word);
  }
}

export function toForeignTitle(word: LatinWord): string {
  switch (word.type) {
    case "noun":
      return nominative(word);
    case "adjective":
    case "fixedAdjective":
    case "pluralAdjective":
    case "conjugatedVerb":
    case "adverb":
    case "conjunction":
    case "negation":
      return word.latin;
    case "verb":
      return word.infinitive;
    case "passiveVerb":
    case "countingWord":
    case "questionParticle":
    case "indefinitePronoun":
    case "interjection":
    case "personalPronoun":
    case "possessivePronoun":
    case "interrogativePronoun":
    case "demonstrativePronoun":
    case "prepositionAccusative":
    case "prepositionAblative":
    case "toFixIrregularWord":
      return word.latin;
    default:
      return assertNever(word);
  }
}

export function toDutchTitle(word: LatinWord): string {
  return word.dutch;
}

export const searchify = (str: string): string => (
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[\s\p{P}]/gu, "")
    .toLowerCase()
);

export function isVerb<E>(word: LatinWord & E): word is LatinVerb & E {
  return (word.type === "verb");
}

export function isNoun<E>(word: LatinWord & E): word is LatinNoun & E {
  return word.type === "noun";
}