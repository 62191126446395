import { LatinAdverb } from "../LatinWord";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_ADVERBS: Array<LatinAdverb> = [
  {
    type: "adverb",
    latin: "deinde",
    dutch: "daarna",
  }, {
    type: "adverb",
    latin: "dēnique",
    dutch: "ten slotte",
  }, {
    type: "adverb",
    latin: "domum",
    dutch: "naar huis",
  }, {
    type: "adverb",
    latin: "etiam",
    dutch: "ook; zelfs",
  }, {
    type: "adverb",
    latin: "hīc",
    dutch: "hier",
  }, {
    type: "adverb",
    latin: "iam",
    dutch: "al; dadelijk",
  }, {
    type: "adverb",
    latin: "ibi",
    dutch: "daar",
  }, {
    type: "adverb",
    latin: "ideō",
    dutch: "daarom",
  }, {
    type: "adverb",
    latin: "saepe",
    dutch: "dikwijls",
  }, {
    type: "adverb",
    latin: "semper",
    dutch: "altijd",
  }, {
    type: "adverb",
    latin: "sīc",
    dutch: "zo",
  }, {
    type: "adverb",
    latin: "simul",
    dutch: "tegelijk",
  }, {
    type: "adverb",
    latin: "statim",
    dutch: "dadelijk",
  }, {
    type: "adverb",
    latin: "tamen",
    dutch: "toch",
  }, {
    type: "adverb",
    latin: "tandem",
    dutch: "(uit)eindelijk",
  }, {
    type: "adverb",
    latin: "tum of tunc",
    dutch: "op dat moment",
  }, {
    type: "adverb",
    latin: "ubi?",
    dutch: "waar?; (daar) waar",
  }, {
    type: "adverb",
    latin: "maximē",
    dutch: "zeer; het meest",
  }, {
    type: "adverb",
    latin: "subitō",
    dutch: "plotseling",
  }, {
    type: "adverb",
    latin: "itaque",
    dutch: "daarom; en zo",
  },
  {
    type: "adverb",
    latin: "prīmum of prīmō",
    dutch: "eerst; voor het eerst",
  },
  {
    type: "adverb",
    latin: "quidem",
    dutch: "wel(iswaar); tenminste; meer bepaald",
  },
  {
    type: "adverb",
    latin: "quam",
    dutch: "dan; zo ... mogelijk",
  },
  {
    type: "adverb",
    latin: "tam",
    dutch: "zo; zozeer",
  },
  {
    type: "adverb",
    latin: "numquam",
    dutch: "nooit",
  },
  {
    type: "adverb",
    latin: "procul",
    dutch: "ver; van ver",
  },
  {
    type: "adverb",
    latin: "haud",
    dutch: "helemaal niet",
  },
  {
    type: "adverb",
    latin: "ūnā",
    dutch: "samen",
  },
  {
    type: "adverb",
    latin: "undique",
    dutch: "van overal; aan alle kanten",
  },
  {
    type: "adverb",
    latin: "fortasse",
    dutch: "misschien",
  },
  {
    type: "adverb",
    latin: "forte",
    dutch: "toevallig",
  },
  {
    type: "adverb",
    latin: "frūstrā",
    dutch: "tevergeefs",
  },
  {
    type: "adverb",
    latin: "multum (multō)",
    dutch: "veel",
  },
  {
    type: "adverb",
    latin: "bene",
    dutch: "goed",
  },
  {
    type: "adverb",
    latin: "immō",
    dutch: "integendeel; sterker nog",
  },
  {
    type: "adverb",
    latin: "prope",
    dutch: "dichtbij",
  },
  {
    type: "adverb",
    latin: "hinc",
    dutch: "van hier",
  },
  // {
  //   type: "adverb",
  //   latin: "",
  //   dutch: "",
  // },
];

export function searchLatinAdverb(latin: string): LatinAdverb {
  const results = LATIN_ADVERBS.filter((adverb) => adverb.latin === latin);
  if (results.length !== 1) {
    console.error(results);
    throw new Error(`No single Latin adverb found for: ${latin}`);
  }
  return results[0];
}