import { searchWord } from "../WordBase";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekParticle {
  type: "particle";  // "partikel"
  ground: string;
  dutch: string;
}

export const GREEK_PARTICLE: GreekParticle[] = [
  {
    type: "particle",
    ground: "οὐ / οὐκ / οὐχ",
    dutch: "niet",
  },
  {
    type: "particle",
    ground: "ἦ",
    dutch: "(wordt niet vertaald)",
  },
  {
    type: "particle",
    ground: "διότι",
    dutch: "daarom",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BD%CE%B1%CE%AF
    type: "particle",
    ground: "ναί",
    dutch: "ja, waarachtig",
  },
  // {
  //   type: "particle",
  //   ground: "",
  //   dutch: "",
  // },
];

export const searchGreekParticle = searchWord(GREEK_PARTICLE, "Greek particle", "ground");