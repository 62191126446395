import { searchWord } from "../WordBase";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekInterrogativePronoun {
  type: "interrogativePronoun";  // "vraagwoord"
  ground: string;
  dutch: string;
  passport?: string;
}

export const GREEK_INTERROGATIVE_PRONOUNS: GreekInterrogativePronoun[] = [
  {
    type: "interrogativePronoun",
    ground: "τί",
    dutch: "wat? waarom?",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%84%CE%AF%CF%82
    type: "interrogativePronoun",
    ground: "τίς",
    dutch: "zelfs.: wie? wat?; bijv.: welke ...?",
    passport: "τίς, τί (vr. vnw.)",
  },
  // {
  //   type: "interrogativePronoun",
  //   ground: "",
  //   dutch: "",
  //   extras: {},
  // },
];

export const searchGreekInterrogativePronoun = searchWord(GREEK_INTERROGATIVE_PRONOUNS, "Greek interrogative pronoun", "ground");

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekDemonstrativePronoun {
  type: "demonstrativePronoun";  // "aanwijzend voornaamwoord"
  ground: string;
  passport: string;
  dutch: string;
}

export const GREEK_DEMONSTRATIVE_PRONOUNS: GreekDemonstrativePronoun[] = [
  {
    type: "demonstrativePronoun",
    ground: "αὐτός",
    passport: "αὐτή, αὐτό",
    dutch: "dezelfde, hetzelfde; zelf, precies, eigenlijk",
  },
  // {
  //   type: "demonstrativePronoun",
  //   ground: "",
  //   passport: "",
  //   dutch: "",
  //   extras: {},
  // },
];

export const searchGreekDemonstrativePronoun = searchWord(GREEK_DEMONSTRATIVE_PRONOUNS, "Greek demonstrative pronoun", "ground");