import { Session } from "../components/SessionContext";
import { BakedVocabulary, VocabularyCode } from "../data/Data";
import * as Scores from "../server/Scores";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface SchedulerWord {
  foreign: string,
  schedulerIndex: number;
  dbIndex: number;
  score: Scores.Score;
  lastTurn: number;  // Turn when it was last selected.
}

export interface SchedulerConfig {
  probabilities: Record<Scores.Score, number>;
  skipInterval: number;
}

export class Scheduler {
  private code: VocabularyCode;
  private bakedVocabulary: BakedVocabulary;
  private schedulerConfig: SchedulerConfig;
  private session: Session | null;
  private debug: boolean = false;
  private turn: number = 0;
  private history: number[] = [];
  private words: SchedulerWord[] = [];

  constructor(
    code: VocabularyCode,
    bakedVocabulary: BakedVocabulary,
    schedulerConfig: SchedulerConfig,
    session: Session | null,
    debug: boolean = false
  ) {
    this.code = code;
    this.bakedVocabulary = bakedVocabulary;
    this.schedulerConfig = schedulerConfig;
    this.session = session;
    this.debug = debug;

    this.turn = 0;
    this.words = this.bakedVocabulary.map(({ foreign, dbIndex }, schedulerIndex) => ({
      foreign,
      schedulerIndex,
      dbIndex,
      score: 0,
      lastTurn: this.turn,
    }));
  }

  public status(): Record<Scores.Score, number> {
    const result = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    }
    this.words.forEach(word => {
      result[word.score]++;
    });
    return result;
  }

  public next(): number | null {
    // Increase the turn.
    this.turn++;

    // Eligibility: filter out words that have been selected too recently.
    const eligibleWords = this.words.filter(word => this.isEligible(word));
    if (eligibleWords.length === 0) {
      return null;
    }

    // Compute weights for all eligible words.
    const weights = eligibleWords.map(word => this.computeWeight(word));
    const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);
    if (this.debug) {
      const weightMap: Record<string, { weight: number, score: number, lastTurn: number }> = {};
      eligibleWords.forEach(({ foreign, score, lastTurn }, i) => {
        weightMap[foreign] = { weight: weights[i], score, lastTurn };
      });
      console.log(weightMap);
    }

    // Normalize weights and select a word using a random number.
    const randomValue = Math.random() * totalWeight;
    let cumulativeWeight = 0;

    for (let i = 0; i < eligibleWords.length; i++) {
      cumulativeWeight += weights[i];
      if (randomValue <= cumulativeWeight) {
        const selectedWord = eligibleWords[i];
        this.pushHistory(selectedWord.schedulerIndex);
        selectedWord.lastTurn = this.turn;
        return selectedWord.schedulerIndex;
      }
    }

    // Could not find anything.
    return null;
  }

  public async reloadFromServer(): Promise<void> {
    // Silent refusal if there is no user session...
    if (this.session === null) return;

    this.words = [];
    this.history = [];
    this.turn = 0;
    const [ scoreMap, ] = await Scores.forCode(this.code);
    this.words = this.bakedVocabulary.map(({ foreign, dbIndex }, schedulerIndex) => ({
      foreign,
      schedulerIndex,
      dbIndex,
      score: scoreMap[schedulerIndex] ?? 0,
      lastTurn: 0,
    }));
  }

  public async update(schedulerIndex: number, score: Scores.Score) {
    const word = this.words[schedulerIndex];
    word.score = score;

    // Only update the server if we have a user session.
    if (this.session !== null) {
      await Scores.update(this.code, this.words[schedulerIndex].dbIndex, score);
    }
  }

  private isEligible({ schedulerIndex }: SchedulerWord): boolean {
    return !this.history.includes(schedulerIndex);
  }

  private computeWeight({ score, lastTurn }: SchedulerWord): number {
    const baseProbability = this.schedulerConfig.probabilities[score];
    const turns = this.turn - lastTurn;
    return turns * baseProbability;
  }

  private pushHistory(schedulerIndex: number): void {
    let { skipInterval } = this.schedulerConfig;

    // Little guard that would still allow for useless situations.
    if (skipInterval >= this.words.length) {
      skipInterval = Math.max(0, this.words.length - 1);
    }

    this.history.push(schedulerIndex);
    // Forget earlier history if necessary.
    if (this.history.length > skipInterval) {
      this.history.splice(0, this.history.length - skipInterval);
    }
  }
}