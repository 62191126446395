import { Optional } from "../../../utils/Optional";
import { Vocabulary } from "../../Vocabulary.tsx";
import { LatinWord } from "../LatinWord.tsx";
import { searchLatinAdjective, searchLatinPluralAdjective } from "../shared/LatinAdjectives.tsx";
import { searchLatinAdverb } from "../shared/LatinAdverbs.tsx";
import { searchLatinConjunction } from "../shared/LatinConjunction.tsx";
import { searchLatinInterjection } from "../shared/LatinInterjection.tsx";
import { searchLatinNegation } from "../shared/LatinNegations.tsx";
import { searchLatinNoun } from "../shared/LatinNouns.tsx";
import { searchLatinPrepositionAblative, searchLatinPrepositionAccusative } from "../shared/LatinPrepositions.tsx";
import { searchLatinDemonstrativePronoun, searchLatinIndefinitePronoun, searchLatinPersonalPronoun, searchLatinPossessivePronoun } from "../shared/LatinPronouns.tsx";
import { searchLatinToFixIrregularWord } from "../shared/LatinToFixes.tsx";
import { searchLatinConjugatedVerb, searchLatinVerb } from "../shared/LatinVerbs.tsx";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface Latin2024PegasusNovus2Extras {
  _2024_pegasus_novus_2: {
    section: string;
    logicalIndex: number;
    page: number;
  };
}

function _search<A, T>(
  section: string,
  logicalIndex: number,
  page: number,
  f: (root: A) => T,
  root: A,
  extras?: Optional<T>,
): T & Latin2024PegasusNovus2Extras {
  return {
    ...f(root),
    ...extras ?? {},
    _2024_pegasus_novus_2: {
        section,
        logicalIndex,
        page,
    },
  };
}

export const LATIN_2024_PEGASUS_NOVUS_2_VOCABULARY: Vocabulary<"la", LatinWord & Latin2024PegasusNovus2Extras> = {
  language: "la",
  vocabulary: [
    _search("1.0",   1,   3, searchLatinNoun, "avus"),
    _search("1.0",   2,   3, searchLatinNoun, "rosa"),
    _search("1.0",   3,   3, searchLatinNoun, "dōnum"),
    _search("1.0",   4,   3, searchLatinNoun, "amīcus"),
    _search("1.0",   5,   3, searchLatinNoun, "deus"),
    _search("1.0",   6,   3, searchLatinNoun, "dominus"),
    _search("1.0",   7,   3, searchLatinNoun, "fīlius"),
    _search("1.0",   8,   3, searchLatinNoun, "locus"),
    _search("1.0",   9,   3, searchLatinNoun, "populus"),
    _search("1.0",  10,   3, searchLatinNoun, "puer"),
    _search("1.0",  11,   3, searchLatinNoun, "servus"),
    _search("1.0",  12,   3, searchLatinNoun, "īnsula"),
    _search("1.0",  13,   3, searchLatinNoun, "puella"),
    _search("1.0",  14,   3, searchLatinNoun, "pugna"),
    _search("1.0",  15,   3, searchLatinNoun, "terra"),
    _search("1.0",  16,   3, searchLatinNoun, "vīta"),
    _search("1.0",  17,   3, searchLatinNoun, "castra"),
    _search("1.0",  18,   3, searchLatinNoun, "forum"),
    _search("1.0",  19,   3, searchLatinNoun, "templum"),
    _search("1.0",  20,   3, searchLatinNoun, "vīnum"),
    _search("1.0",  21,   4, searchLatinAdjective, "bon"),
    _search("1.0",  22,   4, searchLatinAdjective, "Graec"),
    _search("1.0",  23,   4, searchLatinAdjective, "īrāt"),
    _search("1.0",  24,   4, searchLatinAdjective, "long"),
    _search("1.0",  25,   4, searchLatinAdjective, "magn"),
    _search("1.0",  26,   4, searchLatinAdjective, "medi"),
    _search("1.0",  27,   4, searchLatinAdjective, "nov"),
    _search("1.0",  28,   4, searchLatinAdjective, "prīm"),
    _search("1.0",  29,   4, searchLatinAdjective, "Rōmān"),
    _search("1.0",  30,   4, searchLatinAdjective, "sōl"),
    _search("1.0",  31,   4, searchLatinAdjective, "tōt"),
    _search("1.0",  32,   4, searchLatinAdjective, "nūll"),
    _search("1.0",  33,   4, searchLatinAdjective, "pulchr"),
    _search("1.0",  34,   4, searchLatinNoun, "dux"),
    _search("1.0",  35,   4, searchLatinNoun, "māter"),
    _search("1.0",  36,   4, searchLatinNoun, "corpus"),
    _search("1.0",  37,   4, searchLatinNoun, "cīvis"),
    _search("1.0",  38,   4, searchLatinNoun, "frāter"),
    _search("1.0",  39,   4, searchLatinNoun, "homō"),
    _search("1.0",  40,   4, searchLatinNoun, "hostis"),
    _search("1.0",  41,   4, searchLatinNoun, "mīles"),
    _search("1.0",  42,   4, searchLatinNoun, "mōns"),
    _search("1.0",  43,   4, searchLatinNoun, "pater"),
    _search("1.0",  44,   4, searchLatinNoun, "mors"),
    _search("1.0",  45,   4, searchLatinNoun, "mulier"),
    _search("1.0",  46,   4, searchLatinNoun, "nox"),
    _search("1.0",  47,   4, searchLatinNoun, "pars"),
    _search("1.0",  48,   4, searchLatinNoun, "soror"),
    _search("1.0",  49,   4, searchLatinNoun, "urbs"),
    _search("1.0",  50,   4, searchLatinNoun, "uxor"),
    _search("1.0",  51,   4, searchLatinNoun, "vōx"),
    _search("1.0",  52,   4, searchLatinNoun, "flūmen"),
    _search("1.0",  53,   5, searchLatinNoun, "lītus"),
    _search("1.0",  54,   5, searchLatinNoun, "nōmen"),
    _search("1.0",  55,   5, searchLatinNoun, "tempus"),
    _search("1.0",  56,   5, searchLatinAdjective, "fort"),
    _search("1.0",  57,   5, searchLatinAdjective, "atrōc"),
    _search("1.0",  58,   5, searchLatinAdjective, "ingent"),
    _search("1.0",  59,   5, searchLatinAdjective, "sapient"),
    _search("1.0",  60,   5, searchLatinAdjective, "grav"),
    _search("1.0",  61,   5, searchLatinAdjective, "omn"),
    _search("1.0",  62,   5, searchLatinPluralAdjective, "omni"),
    _search("1.0",  63,   5, searchLatinAdjective, "trīst"),
    _search("1.0",  64,   5, searchLatinAdjective, "celer"),
    _search("1.0",  65,   5, searchLatinNoun, "fructus"),
    _search("1.0",  66,   5, searchLatinNoun, "rēs"),
    _search("1.0",  67,   5, searchLatinNoun, "domus"),
    _search("1.0",  68,   5, searchLatinNoun, "manus"),
    _search("1.0",  69,   5, searchLatinNoun, "diēs"),
    _search("1.0",  70,   5, searchLatinPersonalPronoun, "ego"),
    _search("1.0",  71,   5, searchLatinPersonalPronoun, "tū"),
    _search("1.0",  72,   5, searchLatinPersonalPronoun, "sē"),
    _search("1.0",  73,   5, searchLatinPersonalPronoun, "is, ea, id"),
    _search("1.0",  74,   5, searchLatinPersonalPronoun, "nōs"),
    _search("1.0",  75,   5, searchLatinPersonalPronoun, "vōs"),
    _search("1.0",  76,   5, searchLatinPersonalPronoun, "iī, eae, ea"),
    _search("1.0",  77,   5, searchLatinPossessivePronoun, "meus, ~a, ~um"),
    _search("1.0",  78,   5, searchLatinPossessivePronoun, "tuus, ~a, ~um"),
    _search("1.0",  79,   5, searchLatinPossessivePronoun, "suus, ~a, ~um"),
    _search("1.0",  80,   5, searchLatinPossessivePronoun, "noster, nostra, nostrum"),
    _search("1.0",  81,   5, searchLatinPossessivePronoun, "vester, vestra, vestrum"),
    _search("1.0",  82,   5, searchLatinDemonstrativePronoun, "hic, haec, hoc; huius"),
    _search("1.0",  83,   5, searchLatinDemonstrativePronoun, "iste, ista, istud; istīus"),
    _search("1.0",  84,   5, searchLatinDemonstrativePronoun, "ille, illa, illud; illīus"),
    _search("1.0",  85,   5, searchLatinDemonstrativePronoun, "is, ea, id"),
    _search("1.0",  86,   5, searchLatinDemonstrativePronoun, "īdem, eadem, idem; eiusdem"),
    _search("1.0",  87,   5, searchLatinDemonstrativePronoun, "ipse, ipsa, ipsum; ipsīus"),
    _search("1.0",  88,   6, searchLatinIndefinitePronoun, "nēmō"),
    _search("1.0",  89,   6, searchLatinIndefinitePronoun, "nihil"),
    _search("1.0",  90,   6, searchLatinVerb, "intrāre"),
    _search("1.0",  91,   6, searchLatinVerb, "portāre"),
    _search("1.0",  92,   6, searchLatinVerb, "rogāre"),
    _search("1.0",  93,   6, searchLatinVerb, "aedificāre"),
    _search("1.0",  94,   6, searchLatinVerb, "clāmāre"),
    _search("1.0",  95,   6, searchLatinVerb, "dormīre"),
    _search("1.0",  96,   6, searchLatinVerb, "habēre"),
    _search("1.0",  97,   6, searchLatinConjugatedVerb, "inquit"),
    _search("1.0",  98,   6, searchLatinAdverb, "deinde"),
    _search("1.0",  99,   6, searchLatinAdverb, "dēnique"),
    _search("1.0", 100,   6, searchLatinAdverb, "domum"),
    _search("1.0", 101,   6, searchLatinAdverb, "etiam"),
    _search("1.0", 102,   6, searchLatinAdverb, "hīc"),
    _search("1.0", 103,   6, searchLatinAdverb, "iam"),
    _search("1.0", 104,   6, searchLatinAdverb, "ibi"),
    _search("1.0", 105,   6, searchLatinAdverb, "ideō"),
    _search("1.0", 106,   6, searchLatinAdverb, "itaque"),
    _search("1.0", 107,   6, searchLatinAdverb, "saepe"),
    _search("1.0", 108,   6, searchLatinAdverb, "semper"),
    _search("1.0", 109,   6, searchLatinAdverb, "sīc"),
    _search("1.0", 110,   6, searchLatinAdverb, "simul"),
    _search("1.0", 111,   6, searchLatinAdverb, "statim"),
    _search("1.0", 112,   6, searchLatinAdverb, "tamen"),
    _search("1.0", 113,   6, searchLatinAdverb, "tandem"),
    _search("1.0", 114,   6, searchLatinAdverb, "tum of tunc"),
    _search("1.0", 115,   6, searchLatinAdverb, "ubi?"),
    _search("1.0", 116,   6, searchLatinAdverb, "maximē"),
    _search("1.0", 117,   6, searchLatinAdverb, "subitō"),
    _search("1.0", 118,   7, searchLatinConjunction, "ac (of atque)"),
    _search("1.0", 119,   7, searchLatinConjunction, "at"),
    _search("1.0", 120,   7, searchLatinConjunction, "aut"),
    _search("1.0", 121,   7, searchLatinConjunction, "autem"),
    _search("1.0", 122,   7, searchLatinConjunction, "enim"),
    _search("1.0", 123,   7, searchLatinConjunction, "et"),
    _search("1.0", 124,   7, searchLatinConjunction, "nam"),
    _search("1.0", 125,   7, searchLatinConjunction, "nec of neque"),
    _search("1.0", 126,   7, searchLatinConjunction, "-que"),
    _search("1.0", 127,   7, searchLatinConjunction, "sed"),
    _search("1.0", 128,   7, searchLatinConjunction, "cum"),
    _search("1.0", 129,   7, searchLatinConjunction, "dum"),
    _search("1.0", 130,   7, searchLatinConjunction, "quamquam"),
    _search("1.0", 131,   7, searchLatinConjunction, "quia"),
    _search("1.0", 132,   7, searchLatinConjunction, "quod"),
    _search("1.0", 133,   7, searchLatinConjunction, "sī"),
    _search("1.0", 134,   7, searchLatinConjunction, "ubi"),
    _search("1.0", 135,   7, searchLatinNegation, "nōn"),
    _search("1.2", 136,   8, searchLatinNoun, "flamma"),
    _search("1.2", 137,   8, searchLatinNoun, "rēgīna"),
    _search("1.2", 138,   8, searchLatinNoun, "grex"),
    _search("1.2", 139,   8, searchLatinNoun, "pāstor"),
    _search("1.2", 140,   8, searchLatinNoun, "vātēs"),
    _search("1.2", 141,   8, searchLatinNoun, "famēs"),
    _search("1.2", 142,   8, searchLatinAdjective, "incolum"),
    _search("1.2", 143,   8, searchLatinVerb, "cōgitāre"),
    _search("1.2", 144,   8, searchLatinVerb, "pāscere"),
    _search("1.2", 145,   8, searchLatinAdverb, "prīmum of prīmō"),
    _search("1.2", 146,   8, searchLatinAdverb, "quidem"),
    _search("1.2", 147,   8, searchLatinAdverb, "quam"),
    _search("1.4", 148,   8, searchLatinNoun, "campus"),
    _search("1.4", 149,   8, searchLatinNoun, "silva"),
    _search("1.4", 150,   8, searchLatinNoun, "studium"),
    _search("1.4", 151,   8, searchLatinDemonstrativePronoun, "alius, alia, aliud"),
    // _search("1.4", 151, 8, searchLatinAdjective, ""),
    _search("1.4", 152,   8, searchLatinToFixIrregularWord, "alius ..., alius ..."),
    _search("1.4", 153,   8, searchLatinToFixIrregularWord, "aliī ..., aliī"),
    _search("1.4", 154,   8, searchLatinDemonstrativePronoun, "alter, altera, alterum"),
    // _search("1.4", 154, 8, searchLatinAdjective, ""),
    _search("1.4", 155,   8, searchLatinToFixIrregularWord, "alter ..., alter ..."),
    _search("1.4", 156,   8, searchLatinAdjective, "dīvit"),
    _search("1.4", 157,   8, searchLatinAdjective, "veter"),
    _search("1.4", 158,   8, searchLatinAdjective, "crūdēl"),
    _search("1.4", 159,   8, searchLatinVerb, "augēre"),
    _search("1.5", 160,   9, searchLatinPrepositionAccusative, "ad"),
    _search("1.5", 161,   9, searchLatinPrepositionAccusative, "ante"),
    _search("1.5", 162,   9, searchLatinPrepositionAccusative, "apud"),
    _search("1.5", 163,   9, searchLatinPrepositionAccusative, "circum"),
    _search("1.5", 164,   9, searchLatinPrepositionAccusative, "inter"),
    _search("1.5", 165,   9, searchLatinPrepositionAccusative, "ob"),
    _search("1.5", 166,   9, searchLatinPrepositionAccusative, "per"),
    _search("1.5", 167,   9, searchLatinPrepositionAccusative, "post"),
    _search("1.5", 168,   9, searchLatinPrepositionAccusative, "praeter"),
    _search("1.5", 169,   9, searchLatinPrepositionAccusative, "trāns"),
    _search("1.5", 170,   9, searchLatinPrepositionAblative, "ā of ab"),
    _search("1.5", 171,   9, searchLatinPrepositionAblative, "cum"),
    _search("1.5", 172,   9, searchLatinPrepositionAblative, "dē"),
    _search("1.5", 173,   9, searchLatinPrepositionAblative, "ē of ex"),
    _search("1.5", 174,   9, searchLatinPrepositionAblative, "prō"),
    _search("1.5", 175,   9, searchLatinPrepositionAblative, "sine"),
    _search("1.5", 176,   9, searchLatinPrepositionAccusative, "in"),
    _search("1.5", 176,   9, searchLatinPrepositionAblative, "in"),
    _search("1.5", 177,   9, searchLatinPrepositionAccusative, "sub"),
    _search("1.5", 177,   9, searchLatinPrepositionAblative, "sub"),
    _search("1.6", 178,  10, searchLatinNoun, "discordia"),
    _search("1.6", 179,  10, searchLatinNoun, "fōrma"),
    _search("1.6", 180,  10, searchLatinNoun, "nuptiae"),
    _search("1.6", 181,  10, searchLatinNoun, "iūdicium"),
    _search("1.6", 182,  10, searchLatinAdjective, "potent"),
    _search("1.6", 183,  10, searchLatinVerb, "iūdicāre"),
    _search("1.6", 184,  10, searchLatinVerb, "parāre"),
    _search("1.6", 185,  10, searchLatinVerb, "rēgnāre"),
    _search("1.7", 186,  10, searchLatinNoun, "officium"),
    _search("1.7", 187,  10, searchLatinNoun, "fraus"),
    _search("1.7", 188,  10, searchLatinNoun, "ōs"),
    _search("1.7", 189,  10, searchLatinVerb, "audēre"),
    _search("1.7", 190,  10, searchLatinVerb, "dēbēre"),
    _search("1.7", 191,  10, searchLatinVerb, "iacēre"),
    _search("1.7", 192,  10, searchLatinVerb, "fīnīre"),
    _search("1.7", 193,  10, searchLatinConjunction, "nōn solum ... sed etiam ..."),
    _search("2.0", 194,  13, searchLatinVerb, "amāre"),
    _search("2.0", 195,  13, searchLatinVerb, "monēre"),
    _search("2.0", 196,  13, searchLatinVerb, "tegere"),
    _search("2.0", 197,  13, searchLatinVerb, "audīre"),
    _search("2.0", 198,  13, searchLatinVerb, "capere"),
    _search("2.0", 199,  13, searchLatinVerb, "dīcere"),
    _search("2.0", 200,  13, searchLatinVerb, "dūcere"),
    _search("2.0", 201,  13, searchLatinVerb, "facere"),
    _search("2.0", 202,  13, searchLatinVerb, "esse"),
    _search("2.0", 203,  13, searchLatinVerb, "posse"),
    _search("2.0", 204,  13, searchLatinVerb, "īre"),
    _search("2.0", 205,  13, searchLatinVerb, "velle"),
    _search("2.0", 206,  13, searchLatinVerb, "nōlle"),
    _search("2.0", 207,  13, searchLatinVerb, "mālle"),
    _search("2.0", 208,  13, searchLatinVerb, "ferre"),
    _search("2.1", 209,  13, searchLatinNoun, "dolus"),
    _search("2.1", 210,  13, searchLatinNoun, "ventus"),
    _search("2.1", 211,  13, searchLatinNoun, "āra"),
    _search("2.1", 212,  13, searchLatinNoun, "grātia"),
    _search("2.1", 213,  13, searchLatinNoun, "ōrāculum"),
    _search("2.1", 214,  13, searchLatinAdjective, "laet"),
    _search("2.1", 215,  13, searchLatinAdjective, "maest"),
    _search("2.1", 216,  13, searchLatinAdjective, "sacr"),
    _search("2.1", 217,  13, searchLatinNoun, "auctor"),
    _search("2.1", 218,  13, searchLatinNoun, "sacerdōs"),
    _search("2.1", 219,  13, searchLatinNoun, "condiciō"),
    _search("2.1", 220,  13, searchLatinAdjective, "difficil"),
    _search("2.1", 221,  13, searchLatinVerb, "stāre"),
    _search("2.1", 222,  13, searchLatinVerb, "errāre"),
    _search("2.1", 223,  13, searchLatinVerb, "nāvigāre"),
    _search("2.1", 224,  13, searchLatinVerb, "respondēre"),
    _search("2.1", 225,  13, searchLatinVerb, "dēsinere"),
    _search("2.1", 226,  13, searchLatinVerb, "exigere"),
    _search("2.1", 227,  13, searchLatinVerb, "pōnere"),
    _search("2.1", 228,  13, searchLatinVerb, "solvere"),
    _search("2.1", 229,  13, searchLatinVerb, "trahere"),
    _search("2.1", 230,  13, searchLatinVerb, "caedere"),
    _search("2.1", 231,  13, searchLatinVerb, "iacere"),
    _search("2.1", 232,  13, searchLatinVerb, "rapere"),
    _search("2.1", 233,  13, searchLatinAdverb, "tam"),
    _search("2.2", 234,  14, searchLatinNoun, "glōria"),
    _search("2.2", 235,  14, searchLatinVerb, "appellāre"),
    _search("2.2", 236,  14, searchLatinVerb, "dare"),
    _search("2.2", 237,  14, searchLatinVerb, "iuvāre"),
    _search("2.2", 238,  14, searchLatinVerb, "ōrnāre"),
    _search("2.2", 239,  14, searchLatinVerb, "vetāre"),
    _search("2.2", 240,  14, searchLatinVerb, "appārēre"),
    _search("2.2", 241,  14, searchLatinVerb, "complēre"),
    _search("2.2", 242,  14, searchLatinVerb, "exercēre"),
    _search("2.2", 243,  14, searchLatinVerb, "iubēre"),
    _search("2.2", 244,  14, searchLatinVerb, "manēre"),
    _search("2.2", 245,  14, searchLatinVerb, "movēre"),
    _search("2.2", 246,  14, searchLatinVerb, "tenēre"),
    _search("2.2", 247,  14, searchLatinVerb, "abdere"),
    _search("2.2", 248,  14, searchLatinVerb, "agere"),
    _search("2.2", 249,  14, searchLatinVerb, "cēdere"),
    _search("2.2", 250,  14, searchLatinVerb, "cōgere"),
    _search("2.2", 251,  14, searchLatinVerb, "currere"),
    _search("2.2", 252,  14, searchLatinVerb, "mittere"),
    _search("2.2", 253,  14, searchLatinVerb, "committere"),
    _search("2.2", 254,  14, searchLatinVerb, "prōmittere"),
    _search("2.2", 255,  14, searchLatinVerb, "tendere"),
    _search("2.2", 256,  14, searchLatinVerb, "dēfendere"),
    _search("2.2", 257,  14, searchLatinVerb, "sentīre"),
    _search("2.2", 258,  14, searchLatinVerb, "fugere"),
    _search("2.2", 259,  14, searchLatinConjunction, "sīve"),
    _search("2.3", 260,  15, searchLatinNoun, "taurus"),
    _search("2.3", 261,  15, searchLatinNoun, "vir"),
    _search("2.3", 262,  15, searchLatinNoun, "hasta"),
    _search("2.3", 263,  15, searchLatinNoun, "patria"),
    _search("2.3", 264,  15, searchLatinNoun, "membrum"),
    _search("2.3", 265,  15, searchLatinAdjective, "miser"),
    _search("2.3", 266,  15, searchLatinAdjective, "parv"),
    _search("2.3", 267,  15, searchLatinAdjective, "quant"),
    _search("2.3", 268,  15, searchLatinNoun, "socius"),
    _search("2.3", 268,  15, searchLatinNoun, "socia"),
    _search("2.3", 268,  15, searchLatinNoun, "socium"),
    _search("2.3", 269,  15, searchLatinNoun, "arx"),
    _search("2.3", 270,  15, searchLatinNoun, "classis"),
    _search("2.3", 271,  15, searchLatinNoun, "vīs"),
    _search("2.3", 272,  15, searchLatinNoun, "mare"),
    _search("2.3", 273,  15, searchLatinNoun, "moenia"),
    _search("2.3", 274,  15, searchLatinNoun, "scelus"),
    _search("2.3", 275,  15, searchLatinVerb, "certāre"),
    _search("2.3", 276,  15, searchLatinVerb, "iactāre"),
    _search("2.3", 277,  15, searchLatinVerb, "solēre"),
    _search("2.3", 278,  15, searchLatinVerb, "vidēre"),
    _search("2.3", 279,  15, searchLatinConjugatedVerb, "accidere; accidit"),
    _search("2.3", 280,  15, searchLatinVerb, "incendere"),
    _search("2.3", 281,  15, searchLatinVerb, "occīdere"),
    _search("2.3", 282,  15, searchLatinVerb, "petere"),
    _search("2.3", 283,  15, searchLatinAdverb, "numquam"),
    _search("2.3", 284,  15, searchLatinAdverb, "procul"),
    _search("2.3", 285,  15, searchLatinPrepositionAccusative, "contrā"),
    _search("2.3", 286,  15, searchLatinPrepositionAccusative, "intrā"),
    _search("2.3", 287,  15, searchLatinInterjection, "ecce!"),
    _search("2.3", 288,  15, searchLatinConjunction, "postquam"),
    _search("2.4", 289,  16, searchLatinNoun, "invidia"),
    _search("2.4", 290,  16, searchLatinVerb, "vexāre"),
    _search("2.4", 291,  16, searchLatinVerb, "metuere"),
    _search("2.4", 292,  16, searchLatinVerb, "ostendere"),
    _search("2.4", 293,  16, searchLatinVerb, "ruere"),
    _search("2.4", 294,  16, searchLatinVerb, "vincere"),
    _search("2.4", 295,  16, searchLatinVerb, "invenīre"),
    _search("2.4", 296,  16, searchLatinVerb, "venīre"),
    _search("2.4", 297,  16, searchLatinPrepositionAccusative, "propter"),
    _search("2.4", 298,  16, searchLatinVerb, "compōnere"),
    _search("2.4", 299,  16, searchLatinVerb, "cōnsīdere"),
    _search("2.4", 300,  16, searchLatinVerb, "convenīre"),
    _search("2.4", 301,  16, searchLatinVerb, "corripere"),
    _search("2.4", 302,  16, searchLatinVerb, "prōvidēre"),
    _search("2.4", 303,  16, searchLatinVerb, "prōcēdere"),
    _search("2.4", 304,  16, searchLatinVerb, "prōdesse"),
    _search("2.4", 305,  16, searchLatinVerb, "reddere"),
    _search("2.4", 306,  16, searchLatinVerb, "redīre"),
    _search("2.4", 307,  16, searchLatinVerb, "referre"),
    _search("2.7", 308,  17, searchLatinAdjective, "callid"),
    _search("2.7", 309,  17, searchLatinAdjective, "timid"),
    _search("2.7", 310,  17, searchLatinNoun, "caput"),
    _search("2.7", 311,  17, searchLatinVerb, "damnāre"),
    _search("2.7", 312,  17, searchLatinAdverb, "haud"),
    _search("2.7", 313,  17, searchLatinAdjective, "reliqu"),
    _search("2.7", 314,  17, searchLatinVerb, "appropinquāre"),
    _search("2.7", 315,  17, searchLatinVerb, "vincīre"),
    _search("2.7", 316,  17, searchLatinAdverb, "ūnā"),
    _search("2.7", 317,  17, searchLatinNoun, "rēx"),
    _search("2.7", 317,  17, searchLatinNoun, "rēgina"),
    _search("2.7", 318,  17, searchLatinNoun, "rēgia"),
    _search("2.7", 319,  17, searchLatinNoun, "rēgnum"),
    _search("2.7", 320,  17, searchLatinAdjective, "rēgi"),
    _search("2.7", 321,  17, searchLatinNoun, "regiō"),
    _search("2.7", 321,  17, searchLatinVerb, "rēgnāre"),
    _search("3.2", 322,  23, searchLatinNoun, "numerus"),
    _search("3.2", 323,  23, searchLatinNoun, "oculus"),
    _search("3.2", 324,  23, searchLatinNoun, "umbra"),
    _search("3.2", 325,  23, searchLatinNoun, "fātum"),
    _search("3.2", 326,  23, searchLatinNoun, "somnium"),
    _search("3.2", 327,  23, searchLatinAdjective, "aequ"),
    _search("3.2", 328,  23, searchLatinAdjective, "aliēn"),
    _search("3.2", 329,  23, searchLatinAdjective, "parāt"),
    _search("3.2", 330,  23, searchLatinAdjective, "patri"),
    _search("3.2", 331,  23, searchLatinNoun, "comes"),
    _search("3.2", 332,  23, searchLatinNoun, "coniūnx"),
    _search("3.2", 333,  23, searchLatinNoun, "salūs"),
    _search("3.2", 334,  23, searchLatinAdjective, "facil"),
    _search("3.2", 335,  23, searchLatinVerb, "dēlēre"),
    _search("3.2", 336,  23, searchLatinVerb, "dēserere"),
    _search("3.2", 337,  23, searchLatinVerb, "quaerere"),
    _search("3.2", 338,  23, searchLatinVerb, "repetere"),
    _search("3.2", 339,  23, searchLatinVerb, "tangere"),
    _search("3.2", 340,  23, searchLatinVerb, "tollere"),
    _search("3.2", 341,  23, searchLatinVerb, "cōnspicere"),
    _search("3.2", 342,  23, searchLatinVerb, "interficere"),
    _search("3.2", 343,  23, searchLatinAdverb, "undique"),
    _search("3.3", 344,  24, searchLatinNoun, "equus"),
    _search("3.3", 345,  24, searchLatinNoun, "porta"),
    _search("3.3", 346,  24, searchLatinNoun, "statua"),
    _search("3.3", 347,  24, searchLatinNoun, "exemplum"),
    _search("3.3", 348,  24, searchLatinNoun, "arma"),
    _search("3.3", 349,  24, searchLatinNoun, "fīnis"),
    _search("3.3", 350,  24, searchLatinVerb, "servāre"),
    _search("3.3", 351,  24, searchLatinVerb, "claudere"),
    _search("3.3", 352,  24, searchLatinVerb, "crēdere"),
    _search("3.3", 353,  24, searchLatinVerb, "relinquere"),
    _search("3.3", 354,  24, searchLatinVerb, "scrībere"),
    _search("3.3", 355,  24, searchLatinVerb, "trādere"),
    _search("3.3", 356,  24, searchLatinVerb, "reperīre"),
    _search("3.3", 357,  24, searchLatinVerb, "accipere"),
    _search("3.3", 358,  24, searchLatinVerb, "recipere"),
    _search("3.3", 359,  25, searchLatinNoun, "praeda"),
    _search("3.3", 360,  25, searchLatinNoun, "imperium"),
    _search("3.3", 361,  25, searchLatinAdjective, "cert"),
    _search("3.3", 362,  25, searchLatinNoun, "tempestās"),
    _search("3.3", 363,  25, searchLatinVerb, "possidēre"),
    _search("3.3", 364,  25, searchLatinAdverb, "fortasse"),
    _search("3.3", 365,  25, searchLatinVerb, "accēdere", {
      dutch: "naderen + dat.",
    }),
    _search("3.3", 366,  25, searchLatinVerb, "addūcere"),
    _search("3.3", 367,  25, searchLatinVerb, "adspicere"),
    _search("3.3", 368,  25, searchLatinVerb, "adesse", {
      dutch: "aanwezig zijn; helpen (+ dat.)",
    }),
    _search("3.3", 369,  25, searchLatinVerb, "adīre"),
    _search("3.3", 370,  25, searchLatinVerb, "afferre", {
      dutch: "brengen (naar)",
    }),
    _search("3.3", 371,  25, searchLatinVerb, "āmittere"),
    _search("3.3", 372,  25, searchLatinVerb, "abicere"),
    _search("3.3", 373,  25, searchLatinVerb, "abesse"),
    _search("3.3", 374,  25, searchLatinVerb, "abīre"),
    _search("3.3", 375,  25, searchLatinVerb, "auferre", {
      dutch: "wegnemen",
    }),
    _search("3.3", 376,  25, searchLatinVerb, "trādere"),
    _search("3.3", 377,  25, searchLatinVerb, "trānsferre"),
    _search("3.3", 378,  25, searchLatinVerb, "trānsīre"),
    _search("3.5", 379,  26, searchLatinNoun, "spēlunca"),
    _search("3.5", 380,  26, searchLatinNoun, "saxum"),
    _search("3.5", 381,  26, searchLatinAdjective, "poster"),
    _search("3.5", 382,  26, searchLatinAdjective, "valid"),
    _search("3.5", 383,  26, searchLatinNoun, "fūr"),
    _search("3.5", 384,  26, searchLatinNoun, "sanguis"),
    _search("3.5", 385,  26, searchLatinNoun, "mēns"),
    _search("3.5", 386,  26, searchLatinNoun, "vulnus"),
    _search("3.5", 387,  26, searchLatinNoun, "exitus"),
    _search("3.5", 388,  26, searchLatinVerb, "adiuvāre"),
    _search("3.5", 389,  26, searchLatinVerb, "placēre", {
      dutch: "(+ dat.) bevallen; aanstaan"
    }),
    _search("3.5", 390,  26, searchLatinVerb, "rīdēre"),
    _search("3.5", 391,  26, searchLatinVerb, "bibere"),
    _search("3.5", 392,  26, searchLatinVerb, "canere"),
    _search("3.5", 393,  26, searchLatinVerb, "fluere"),
    _search("3.5", 394,  26, searchLatinVerb, "intellegere"),
    _search("3.5", 395,  26, searchLatinVerb, "vīvere"),
    _search("3.5", 396,  26, searchLatinVerb, "offerre"),
    _search("3.5", 397,  26, searchLatinAdverb, "bene"),
    _search("3.5", 398,  26, searchLatinAdverb, "forte"),
    _search("3.5", 399,  26, searchLatinAdverb, "frūstrā"),
    _search("3.5", 400,  26, searchLatinAdverb, "multum (multō)"),
    _search("3.5", 401,  26, searchLatinConjunction, "sīcut"),
    _search("3.6", 402,  26, searchLatinNoun, "modus"),
    _search("3.6", 403,  26, searchLatinNoun, "annus"),
    _search("3.6", 404,  26, searchLatinAdjective, "fēlic"),
    _search("3.6", 405,  26, searchLatinVerb, "docēre"),
    _search("3.6", 406,  26, searchLatinVerb, "dēligere"),
    _search("3.6", 407,  26, searchLatinVerb, "frangere"),
    _search("3.6", 408,  26, searchLatinVerb, "sūmere"),
    _search("3.9", 409,  27, searchLatinNoun, "mōnstrum"),
    _search("3.9", 410,  27, searchLatinAdjective, "nigr"),
    _search("3.9", 411,  27, searchLatinAdjective, "alt"),
    _search("3.9", 412,  27, searchLatinAdjective, "crēbr"),
    _search("3.9", 413,  27, searchLatinVerb, "condere"),
    _search("3.9", 414,  27, searchLatinVerb, "legere"),
    _search("3.9", 415,  27, searchLatinVerb, "pervenīre"),
    _search("3.9", 416,  27, searchLatinVerb, "fīerī"),
    _search("4.2", 417,  31, searchLatinNoun, "ager"),
    _search("4.2", 418,  31, searchLatinNoun, "minister"),
    _search("4.2", 419,  31, searchLatinNoun, "aqua"),
    _search("4.2", 420,  31, searchLatinNoun, "lingua"),
    _search("4.2", 421,  31, searchLatinNoun, "rīpa"),
    _search("4.2", 422,  31, searchLatinNoun, "praemium"),
    _search("4.2", 423,  31, searchLatinAdjective, "proxim"),
    _search("4.2", 424,  31, searchLatinNoun, "Tiberis"),
    _search("4.2", 425,  31, searchLatinNoun, "caedēs"),
    _search("4.2", 426,  31, searchLatinNoun, "pecus"),
    _search("4.2", 427,  31, searchLatinVerb, "habitāre"),
    _search("4.2", 428,  31, searchLatinVerb, "mūtāre"),
    _search("4.2", 429,  31, searchLatinVerb, "necāre"),
    _search("4.2", 430,  31, searchLatinVerb, "nūntiāre"),
    _search("4.2", 431,  31, searchLatinVerb, "spectāre"),
    _search("4.2", 432,  31, searchLatinVerb, "haerēre"),
    _search("4.2", 433,  31, searchLatinVerb, "licēre"),
    _search("4.2", 434,  31, searchLatinVerb, "alere"),
    _search("4.2", 435,  31, searchLatinVerb, "fallere"),
    _search("4.2", 436,  31, searchLatinVerb, "perficere"),
    _search("4.2", 437,  31, searchLatinAdverb, "immō"),
    _search("4.2", 438,  31, searchLatinAdverb, "prope"),
    _search("4.2", 439,  31, searchLatinPrepositionAccusative, "super"),
    _search("4.3", 440,  32, searchLatinToFixIrregularWord, "hortāri, ~or"),  // Depondens
    _search("4.3", 441,  32, searchLatinToFixIrregularWord, "verēri, ~eor"),  // Depondens
    _search("4.3", 442,  32, searchLatinToFixIrregularWord, "loquī, ~or"),  // Depondens
    _search("4.3", 443,  32, searchLatinToFixIrregularWord, "potīrī, ~ior + abl."),  // Depondens
    _search("4.3", 444,  32, searchLatinToFixIrregularWord, "patī, ~ior"),  // Depondens
    _search("4.3", 445,  32, searchLatinToFixIrregularWord, "cōnārī, ~or"),  // Depondens
    _search("4.3", 446,  32, searchLatinToFixIrregularWord, "mīrārī, ~or"),  // Depondens
    _search("4.3", 447,  32, searchLatinToFixIrregularWord, "tuēri, ~eor"),  // Depondens
    _search("4.3", 448,  32, searchLatinToFixIrregularWord, "pollicērī, ~eor"),  // Depondens
    _search("4.3", 449,  32, searchLatinToFixIrregularWord, "fungi, ~or + abl."),  // Depondens
    _search("4.3", 450,  32, searchLatinToFixIrregularWord, "nāscī, ~or"),  // Depondens
    _search("4.3", 451,  32, searchLatinToFixIrregularWord, "proficīscī, ~or"),  // Depondens
    _search("4.3", 452,  32, searchLatinToFixIrregularWord, "querī, ~or"),  // Depondens
    _search("4.3", 453,  32, searchLatinToFixIrregularWord, "sequī, ~or"),  // Depondens
    _search("4.3", 454,  32, searchLatinToFixIrregularWord, "ūtī, ~or + abl."),  // Depondens
    _search("4.3", 455,  32, searchLatinToFixIrregularWord, "experīrī, ~iōr"),  // Depondens
    _search("4.3", 456,  32, searchLatinToFixIrregularWord, "orīrī, ~ior"),  // Depondens
    _search("4.3", 457,  32, searchLatinToFixIrregularWord, "ēgredī, ~iōr"),  // Depondens
    _search("4.3", 458,  32, searchLatinToFixIrregularWord, "morī, ~ior"),  // Depondens
    _search("4.3", 459,  32, searchLatinToFixIrregularWord, "prōgredī, ~ior"),  // Depondens
    _search("4.3", 460,  32, searchLatinAdjective, "barbar"),
    _search("4.3", 461,  32, searchLatinNoun, "sōl"),
    _search("4.3", 462,  32, searchLatinNoun, "genus"),
    _search("4.3", 463,  32, searchLatinNoun, "impetus"),
    _search("4.3", 464,  33, searchLatinVerb, "dēdūcere"),
    _search("4.3", 465,  33, searchLatinVerb, "dēpōnere"),
    _search("4.3", 466,  33, searchLatinVerb, "dēicere"),
    _search("4.3", 466,  33, searchLatinVerb, "dēligere"),
    _search("4.3", 467,  33, searchLatinVerb, "ēdūcere"),
    _search("4.3", 468,  33, searchLatinVerb, "expōnere"),
    _search("4.3", 469,  33, searchLatinVerb, "excipere"),
    _search("4.3", 470,  33, searchLatinVerb, "ēripere"),
    _search("4.3", 471,  33, searchLatinVerb, "exīre"),
    _search("4.3", 471,  33, searchLatinVerb, "impōnere"),
    _search("4.3", 472,  33, searchLatinToFixIrregularWord, "ingredī, ~ior"),
    _search("4.3", 473,  33, searchLatinVerb, "inīre"),
    _search("4.4", 474,  34, searchLatinNoun, "lūdus"),
    _search("4.4", 475,  34, searchLatinNoun, "causa"),
    _search("4.4", 476,  34, searchLatinNoun, "cūra"),
    _search("4.4", 477,  34, searchLatinNoun, "bellum"),
    _search("4.4", 478,  34, searchLatinNoun, "cōnsilium"),
    _search("4.4", 479,  34, searchLatinNoun, "mātrimōnium"),
    _search("4.4", 480,  34, searchLatinNoun, "proelium"),
    _search("4.4", 481,  34, searchLatinNoun, "signum"),
    _search("4.4", 482,  34, searchLatinNoun, "silentium"),
    _search("4.4", 483,  34, searchLatinNoun, "tēlum"),
    _search("4.4", 484,  34, searchLatinAdjective, "aegr"),
    _search("4.4", 485,  34, searchLatinAdjective, "magnific"),
    _search("4.4", 486,  34, searchLatinNoun, "hospes"),
    _search("4.4", 487,  34, searchLatinNoun, "gēns"),
    _search("4.4", 488,  34, searchLatinNoun, "quiēs"),
    _search("4.4", 489,  34, searchLatinNoun, "virgō"),
    _search("4.4", 490,  34, searchLatinAdjective, "audāc"),
    _search("4.4", 491,  34, searchLatinAdjective, "complūr"),
    _search("4.4", 492,  34, searchLatinNoun, "aciēs"),
    _search("4.4", 493,  34, searchLatinNoun, "spēs"),
    _search("4.4", 494,  34, searchLatinVerb, "volāre"),
    _search("4.4", 495,  34, searchLatinVerb, "crēscere"),
    _search("4.4", 496,  34, searchLatinVerb, "vertere"),
    _search("4.4", 497,  34, searchLatinVerb, "respicere"),
    _search("4.4", 498,  34, searchLatinVerb, "perīre"),
    _search("4.4", 499,  34, searchLatinAdverb, "hinc"),
    _search("4.4", 500,  34, searchLatinConjunction, "igitur"),
  ],
};