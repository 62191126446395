import React, { useEffect, useMemo, useState } from "react";
import { BakedVocabulary, VocabularyCode } from "../data/Data.tsx";
import * as Scores from "../server/Scores.tsx";
import { Scheduler, SchedulerConfig } from "../utils/Scheduler.tsx";
import "./Practice.scss";
import { useSession } from "./SessionContext.tsx";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface PracticeStateProps {
  status: Record<Scores.Score, number>;
}

function PracticeState({ status }: PracticeStateProps) {
  return (
    <>
      <span className={`text-${Scores.scoreToColor(0)}`}>{status[0]}</span>
      &nbsp;/&nbsp;
      <span className={`text-${Scores.scoreToColor(1)}`}>{status[1]}</span>
      &nbsp;/&nbsp;
      <span className={`text-${Scores.scoreToColor(2)}`}>{status[2]}</span>
      &nbsp;/&nbsp;
      <span className={`text-${Scores.scoreToColor(3)}`}>{status[3]}</span>
      &nbsp;/&nbsp;
      <span className={`text-${Scores.scoreToColor(4)}`}><b>{status[4]}</b></span>
    </>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PracticeState = "swirlIn" | "question" | "answer" | "swirlOut";

const isNotSwirling = (state: PracticeState) =>
  (state === "question") || (state === "answer");
const showAnswerSide = (state: PracticeState) =>
  (state === "answer") || (state === "swirlOut");

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const SCHEDULER_PARAMS: SchedulerConfig = {
  probabilities: {
    0: 1.0 / 10,
    1: 1.0 /  3,
    2: 1.0 / 10,
    3: 1.0 / 20,
    4: 1.0 / 50,
  },
  skipInterval: 2,
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// Transitions take 250 msec.
const FLIP_CARD_TRANSITION_DURATION = 250;

interface PracticeProps<W> {
  code: VocabularyCode;
  questionComponent: React.ComponentType<{ word: W }>;
  answerComponent: React.ComponentType<{ word: W }>;
  vocabulary: W[];
  bakedVocabulary: BakedVocabulary;
  answerBodyFn?: (word: W) => React.ReactNode;
}

export function Practice<W>({
  code,
  questionComponent,
  answerComponent,
  vocabulary,
  bakedVocabulary,
  answerBodyFn
}: PracticeProps<W>) {
  const session = useSession();
  const [ state, setState ] = useState<PracticeState>("swirlIn");
  const [ currentIndex, setCurrentIndex ] = useState<number | null>(null);
  const scheduler = useMemo(
    () => new Scheduler(code, bakedVocabulary, SCHEDULER_PARAMS, session),
    [ code, bakedVocabulary, session ]
  );
  useEffect(() => {
    setCurrentIndex(scheduler.next());
  }, [ scheduler ]);

  // Swirl in effect.
  useEffect(() => {
    if (state !== "swirlIn") return;
    const timer = setTimeout(() => {
      setState("question");
    }, FLIP_CARD_TRANSITION_DURATION);
    return () => {
      clearTimeout(timer);
    };
  }, [ state ]);

  // Swirl out effect.
  useEffect(() => {
    if (state !== "swirlOut") return;
    const timer = setTimeout(() => {
      setState("swirlIn");
    }, FLIP_CARD_TRANSITION_DURATION);
    return () => {
      clearTimeout(timer);
    };
  }, [ state ]);

  const selectNextIndex = () => {
    setCurrentIndex(scheduler.next());
  }

  const handleQuestionCardClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (isNotSwirling(state) && (state === "question")) {
      setState("answer");
    }
  }

  const handleAnswerClick = (score: Scores.Score): React.MouseEventHandler<HTMLButtonElement> => (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (isNotSwirling(state) && (state === "answer") && (currentIndex !== null)) {
      scheduler.update(currentIndex, score);
      selectNextIndex();
      setState("swirlOut");
    }
  }

  if (currentIndex === null) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="results">
          <div className="card">
            <div className="card-header">
              Fout!
            </div>
            <div className="card-body">
              Fout!
            </div>
          </div>
        </div>
      </div>
    );
  }

  const swirl = state === "swirlOut" ? "swirl-out" : state === "swirlIn" ? "swirl-in" : "swirl-none";
  const flip = showAnswerSide(state) ? "flipped" : "";
  const status = scheduler.status();
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className={`flip-card ${swirl}`}>
        <div className={`inner ${flip}`}>
          <div className="front">
            <div className="card shadow-lg"
                 onClick={handleQuestionCardClick}>
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-baseline">
                <span className="word-type">{bakedVocabulary[currentIndex].type}</span>
                  <span className="word-status">
                    <PracticeState status={status} />
                  </span>
                </div>
              </div>
              <div className="card-body">
                {React.createElement(questionComponent, { word: vocabulary[currentIndex] })}
              </div>
            </div>
          </div>
          <div className="back">
            <div className="card shadow-lg">
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-baseline">
                  <span className="word-type">{bakedVocabulary[currentIndex].type}</span>
                  <span className="word-status">
                    <PracticeState status={status} />
                  </span>
                </div>
              </div>
              <div className="card-body">
                {React.createElement(answerComponent, { word: vocabulary[currentIndex] })}
                {answerBodyFn?.(vocabulary[currentIndex])}
              </div>
              <div className="card-footer">
                <div className="d-flex justify-content-between">
                  <button className="btn btn-outline-danger"
                          onClick={handleAnswerClick(1)}>
                    Opnieuw
                  </button>
                  <button className="btn btn-outline-warning"
                          onClick={handleAnswerClick(2)}>
                    Moeilijk
                  </button>
                  <button className="btn btn-outline-success"
                          onClick={handleAnswerClick(3)}>
                    Goed
                  </button>
                  <button className="btn btn-outline-primary"
                          onClick={handleAnswerClick(4)}>
                    Eenvoudig
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}