import { Examples } from "../components/Examples";
import { GreekPractice } from "../components/greek/GreekPractice";
import { LatinPractice } from "../components/latin/LatinPractice";
import { bake, VOCABULARIES } from "../data/Data";
import { useDbIndicesFromParams } from "../hooks/useDbIndicesFromParams";
import { useVocabularyCodeFromParams } from "../hooks/useVocabularyCodeFromParams";
import { assertNever } from "../utils/assertNever";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function Range() {
  const code = useVocabularyCodeFromParams();
  const dbIndices = useDbIndicesFromParams();

  if ((code === null) || (dbIndices.length === 0)) {
    return <div />;
  }

  const { bakedVocabulary } = bake(code);

  switch(code) {
    case "2023-pegasus-novus-1": {
      const { vocabulary } = VOCABULARIES[code];
      return <LatinPractice code={code}
                            vocabulary={dbIndices.map((i) => vocabulary[i])}
                            bakedVocabulary={dbIndices.map((i) => bakedVocabulary[i])}
                            answerBodyFn={(w) => <Examples examples={w._2023_pegasus_novus_1.examples} />} />;
    }
    case "2024-pegasus-novus-2": {
      const { vocabulary } = VOCABULARIES[code];
      return <LatinPractice code={code}
                            vocabulary={dbIndices.map((i) => vocabulary[i])}
                            bakedVocabulary={dbIndices.map((i) => bakedVocabulary[i])} />;
    }
    case "2024-nea-thalassa-1": {
      const { vocabulary } = VOCABULARIES[code];
      return <GreekPractice code={code}
                            vocabulary={dbIndices.map((i) => vocabulary[i])}
                            bakedVocabulary={dbIndices.map((i) => bakedVocabulary[i])} />;
    }
    default:
      assertNever(code);
  }
}