import { searchWord } from "../WordBase";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekCountingWord {
  type: "countingWord";  // "telwoord"
  ground: string;
  dutch: string;
  passport?: string;
}

export const GREEK_COUNTING_WORDS: GreekCountingWord[] = [
  {
    type: "countingWord",
    ground: "πέντε",
    dutch: "vijf",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%84%CE%AD%CF%84%CF%84%CE%B1%CF%81%CE%B5%CF%82
    type: "countingWord",
    ground: "τέττᾰρες",
    passport: "τέττᾰρες, τέττᾰρᾰ (hfdtelw.)",
    dutch: "vier",
    // τέττᾰρες τέττᾰρες τέττᾰρᾰ
    // τεττᾰ́ρων τεττᾰ́ρων τεττᾰ́ρων
    // τέττᾰρσῐ/τέττᾰρσῐν τέττᾰρσῐ/τέττᾰρσῐν τέττᾰρσῐ/τέττᾰρσῐν
    // τέττᾰρᾰς τέττᾰρᾰς τέττᾰρᾰ
  },
  {
    // https://en.wiktionary.org/wiki/%CF%84%CF%81%CE%B5%E1%BF%96%CF%82
    type: "countingWord",
    ground: "τρεῖς",
    passport: "τρεῖς, τρῐ́α (hfdtelw.)",
    dutch: "drie",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%95%CE%BE
    type: "countingWord",
    ground: "ἕξ",
    dutch: "zes",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%91%CF%80%CF%84%CE%AC
    type: "countingWord",
    ground: "ἑπτά",
    dutch: "zeven",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BD%80%CE%BA%CF%84%CF%8E
    type: "countingWord",
    ground: "ὀκτώ",
    dutch: "acht",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%90%CE%BD%CE%BD%CE%AD%CE%B1
    type: "countingWord",
    ground: "ἐννέα",
    dutch: "negen",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%CE%AD%CE%BA%CE%B1
    type: "countingWord",
    ground: "δέκα",
    dutch: "tien",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B5%E1%BC%B4%CE%BA%CE%BF%CF%83%CE%B9%CE%BD
    type: "countingWord",
    ground: "εἴκοσι(ν)",
    dutch: "twintig",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%91%CE%BA%CE%B1%CF%84%CF%8C%CE%BD
    type: "countingWord",
    ground: "ἑκατόν",
    dutch: "honderd",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BC%CF%8D%CF%81%CE%B9%CE%BF%CE%B9
    type: "countingWord",
    ground: "μύριοι",
    passport: "μύριαι, μύρια",
    dutch: "tienduizend",
  },
  // {
  //   type: "countingWord",
  //   ground: "",
  //   dutch: "",
  // },
];

export const searchGreekCountingWord = searchWord(GREEK_COUNTING_WORDS, "Greek counting word", "ground");