import { LatinAdjective, LatinPluralAdjective } from "../LatinWord";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_ADJECTIVES: { [key: string]: LatinAdjective } = {
// export const LATIN_ADJECTIVES = {
  "bon": {
    type: "adjective",
    latin: "bonus",
    explicitDeclensions: {
      nomS: { m: "bonus", f: "bona", n: "bonum", },
    },
    root: "bon",
    dutch: "goed",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "Graec": {
    type: "adjective",
    latin: "Graecus",
    explicitDeclensions: {
      nomS: { m: "Graecus", f: "Graeca", n: "Graecum", },
    },
    root: "Graec",
    dutch: "Grieks",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "īrāt": {
    type: "adjective",
    latin: "īrātus + dat.",
    explicitDeclensions: {
      nomS: { m: "īrātus", f: "īrāta", n: "īrātum", },
    },
    root: "īrāt",
    dutch: "woedend",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "long": {
    type: "adjective",
    latin: "longus",
    explicitDeclensions: {
      nomS: { m: "longus", f: "longa", n: "longum", },
    },
    root: "long",
    dutch: "lang",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "magn": {
    type: "adjective",
    latin: "magnus",
    explicitDeclensions: {
      nomS: { m: "magnus", f: "magna", n: "magnum", },
    },
    root: "magn",
    dutch: "groot; belangrijk",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "medi": {
    type: "adjective",
    latin: "medius",
    explicitDeclensions: {
      nomS: { m: "medius", f: "media", n: "medium", },
    },
    root: "medi",
    dutch: "middelste; het midden van",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "nov": {
    type: "adjective",
    latin: "novus",
    explicitDeclensions: {
      nomS: { m: "novus", f: "nova", n: "novum", },
    },
    root: "nov",
    dutch: "nieuw",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "prīm": {
    type: "adjective",
    latin: "prīmus",
    explicitDeclensions: {
      nomS: { m: "prīmus", f: "prīma", n: "prīmum", },
    },
    root: "prīm",
    dutch: "eerste; de eerste van",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "Rōmān": {
    type: "adjective",
    latin: "Rōmānus",
    explicitDeclensions: {
      nomS: { m: "Rōmānus", f: "Rōmāna", n: "Rōmānum", },
    },
    root: "Rōmān",
    dutch: "Romeins",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "sōl": {
    type: "adjective",
    latin: "sōlus",
    explicitDeclensions: {
      nomS: { m: "sōlus", f: "sōla", n: "sōlum", },
    },
    root: "sōl",
    dutch: "alleen; eenzaam",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "tōt": {
    type: "adjective",
    latin: "tōtus",
    explicitDeclensions: {
      nomS: { m: "tōtus", f: "tōta", n: "tōtum", },
    },
    root: "tōt",
    dutch: "geheel",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "nūll": {
    type: "adjective",
    latin: "nūllus",
    explicitDeclensions: {
      nomS: { m: "nūllus", f: "nūlla", n: "nūllum", },
    },
    root: "nūll",
    dutch: "geen",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "pulchr": {
    type: "adjective",
    latin: "pulcher",
    explicitDeclensions: {
      nomS: { m: "pulcher", f: "pulchra", n: "pulchrum", },
    },
    root: "pulchr",
    dutch: "mooi",
    klass: "1er",
    hasSingular: true,
    hasPlural: true,
  },
  "fort": {
    type: "adjective",
    latin: "fortis",
    explicitDeclensions: {
      nomS: { m: "fortis", f: "fortis", n: "forte", },
    },
    root: "fort",
    dutch: "dapper; sterk",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "atrōc": {
    type: "adjective",
    latin: "atrōx",
    explicitDeclensions: {
      nomS: { m: "atrōx", f: "atrōx", n: "atrōx", },
    },
    root: "atrōc",
    dutch: "vreselijk",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "ingent": {
    type: "adjective",
    latin: "ingēns",
    explicitDeclensions: {
      nomS: { m: "ingēns", f: "ingēns", n: "ingēns", },
    },
    root: "ingent",
    dutch: "reusachtig",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "sapient": {
    type: "adjective",
    latin: "sapiēns",
    explicitDeclensions: {
      nomS: { m: "sapiēns", f: "sapiēns", n: "sapiēns", },
    },
    root: "sapient",
    dutch: "wijs; verstandig",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "grav": {
    type: "adjective",
    latin: "gravis",
    explicitDeclensions: {
      nomS: { m: "gravis", f: "gravis", n: "grave", },
    },
    root: "grav",
    dutch: "zwaar; ernstig",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "omn": {
    type: "adjective",
    latin: "omnis",
    explicitDeclensions: {
      nomS: { m: "omnis", f: "omnis", n: "omne", },
    },
    root: "omn",
    dutch: "geheel; elk",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "trīst": {
    type: "adjective",
    latin: "trīstis",
    explicitDeclensions: {
      nomS: { m: "trīstis", f: "trīstis", n: "trīste", },
    },
    root: "trīst",
    dutch: "droevig",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "celer": {
    type: "adjective",
    latin: "celer",
    explicitDeclensions: {
      nomS: { m: "celer", f: "celeris", n: "celere", },
    },
    root: "celer",
    dutch: "snel",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "incolum": {
    type: "adjective",
    latin: "incolumis",
    explicitDeclensions: {
      nomS: { m: "incolumis", f: "incolumis", n: "incolume", },
    },
    root: "incolum",
    dutch: "ongedeerd",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "ali": {
    type: "adjective",
    latin: "alius",
    explicitDeclensions: {
      nomS: { m: "alius", f: "alia", n: "aliud", },
    },
    root: "ali",
    dutch: "een andere",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "alter": {
    type: "adjective",
    latin: "alter",
    explicitDeclensions: {
      nomS: { m: "alter", f: "altera", n: "alterum", },
    },
    root: "alter",
    dutch: "de andere",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "dīvit": {
    type: "adjective",
    latin: "dīves",
    explicitDeclensions: {
      nomS: { m: "dīves", f: "dīves", n: "dīves", },
    },
    root: "dīvit",
    dutch: "rijk",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "veter": {
    type: "adjective",
    latin: "vetus",
    explicitDeclensions: {
      nomS: { m: "vetus", f: "vetus", n: "vetus", },
    },
    root: "veter",
    dutch: "oud",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "crūdēl": {
    type: "adjective",
    latin: "crūdēlis",
    explicitDeclensions: {
      nomS: { m: "crūdēlis", f: "crūdēlis", n: "crūdēle", },
    },
    root: "crūdēl",
    dutch: "wreed",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "potent": {
    type: "adjective",
    latin: "potēns",
    explicitDeclensions: {
      nomS: { m: "potēns", f: "potēns", n: "potēns", },
    },
    root: "potent",
    dutch: "machtig",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "laet": {
    type: "adjective",
    latin: "laetus",
    explicitDeclensions: {
      nomS: { m: "laetus", f: "laeta", n: "laetum", },
    },
    root: "laet",
    dutch: "vrolijk",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "maest": {
    type: "adjective",
    latin: "maestus",
    explicitDeclensions: {
      nomS: { m: "maestus", f: "maesta", n: "maestum", },
    },
    root: "maest",
    dutch: "treurig",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "sacr": {
    type: "adjective",
    latin: "sacer",
    explicitDeclensions: {
      nomS: { m: "sacer", f: "sacra", n: "sacrum", },
    },
    root: "sacr",
    dutch: "heilig; gewijd",
    klass: "1er",
    hasSingular: true,
    hasPlural: true,
  },
  "difficil": {
    type: "adjective",
    latin: "difficilis",
    explicitDeclensions: {
      nomS: { m: "difficilis", f: "difficilis", n: "difficile", },
    },
    root: "difficil",
    dutch: "moeilijk",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "lāt": {
    type: "adjective",
    latin: "lātus",
    explicitDeclensions: {
      nomS: { m: "lātus", f: "lāta", n: "lātum", },
    },
    root: "lāt",
    dutch: "breed; uitgestrekt",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "optim": {
    type: "adjective",
    latin: "optimus",
    explicitDeclensions: {
      nomS: { m: "optimus", f: "optima", n: "optimum", },
    },
    root: "optim",
    dutch: "beste; zeer goed",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "timid": {
    type: "adjective",
    latin: "timidus",
    explicitDeclensions: {
      nomS: { m: "timidus", f: "timida", n: "timidum", },
    },
    root: "timid",
    dutch: "bang",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "alt": {
    type: "adjective",
    latin: "altus",
    explicitDeclensions: {
      nomS: { m: "altus", f: "alta", n: "altum", },
    },
    root: "alt",
    dutch: "hoog; diep",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "magnific": {
    type: "adjective",
    latin: "magnificus",
    explicitDeclensions: {
      nomS: { m: "magnificus", f: "magnifica", n: "magnificum", },
    },
    root: "magnific",
    dutch: "prachtig",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "antīqu": {
    type: "adjective",
    latin: "antīquus",
    explicitDeclensions: {
      nomS: { m: "antīquus", f: "antīqua", n: "antīquum", },
    },
    root: "antīqu",
    dutch: "oud",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "mal": {
    type: "adjective",
    latin: "malus",
    explicitDeclensions: {
      nomS: { m: "malus", f: "mala", n: "malum", },
    },
    root: "mal",
    dutch: "slecht",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "stult": {
    type: "adjective",
    latin: "stultus",
    explicitDeclensions: {
      nomS: { m: "stultus", f: "stulta", n: "stultum", },
    },
    root: "stult",
    dutch: "dwaas",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "calid": {
    type: "adjective",
    latin: "calidus",
    explicitDeclensions: {
      nomS: { m: "calidus", f: "calida", n: "calidum", },
    },
    root: "calid",
    dutch: "warm",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "cert": {
    type: "adjective",
    latin: "certus",
    explicitDeclensions: {
      nomS: { m: "certus", f: "certa", n: "certum", },
    },
    root: "cert",
    dutch: "zeker",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "dign": {
    type: "adjective",
    latin: "dignus",
    explicitDeclensions: {
      nomS: { m: "dignus", f: "digna", n: "dignum", },
    },
    root: "dign",
    dutch: "waardig; passend",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "fess": {
    type: "adjective",
    latin: "fessus",
    explicitDeclensions: {
      nomS: { m: "fessus", f: "fessa", n: "fessum", },
    },
    root: "fess",
    dutch: "vermoeid",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "frīgid": {
    type: "adjective",
    latin: "frīgidus",
    explicitDeclensions: {
      nomS: { m: "frīgidus", f: "frīgida", n: "frīgidum", },
    },
    root: "frīgid",
    dutch: "koud; koel",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "grāt": {
    type: "adjective",
    latin: "grātus",
    explicitDeclensions: {
      nomS: { m: "grātus", f: "grāta", n: "grātum", },
    },
    root: "grāt",
    dutch: "dankbaar; aangenaam",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "iūcund": {
    type: "adjective",
    latin: "iūcundus",
    explicitDeclensions: {
      nomS: { m: "iūcundus", f: "iūcunda", n: "iūcundum", },
    },
    root: "iūcund",
    dutch: "aangenaam",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "iūst": {
    type: "adjective",
    latin: "iūstus",
    explicitDeclensions: {
      nomS: { m: "iūstus", f: "iūsta", n: "iūstum", },
    },
    root: "iūst",
    dutch: "rechtvaardig",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "sān": {
    type: "adjective",
    latin: "sānus",
    explicitDeclensions: {
      nomS: { m: "sānus", f: "sāna", n: "sānum", },
    },
    root: "sān",
    dutch: "gezond",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "superb": {
    type: "adjective",
    latin: "superbus",
    explicitDeclensions: {
      nomS: { m: "superbus", f: "superba", n: "superbum", },
    },
    root: "superb",
    dutch: "trots; prachtig",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "clār": {
    type: "adjective",
    latin: "clārus",
    explicitDeclensions: {
      nomS: { m: "clārus", f: "clāra", n: "clārum", },
    },
    root: "clār",
    dutch: "helder; beroemd",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "parv": {
    type: "adjective",
    latin: "parvus",
    explicitDeclensions: {
      nomS: { m: "parvus", f: "parva", n: "parvum", },
    },
    root: "parv",
    dutch: "klein",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "nūd": {
    type: "adjective",
    latin: "nūdus",
    explicitDeclensions: {
      nomS: { m: "nūdus", f: "nūda", n: "nūdum", },
    },
    root: "nūd",
    dutch: "naakt; beroofd van",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "līber": {
    type: "adjective",
    latin: "līber",
    explicitDeclensions: {
      nomS: { m: "līber", f: "lībera", n: "līberum", },
    },
    root: "līber",
    dutch: "vrij",
    klass: "1r",
    hasSingular: true,
    hasPlural: true,
  },
  "miser": {
    type: "adjective",
    latin: "miser",
    explicitDeclensions: {
      nomS: { m: "miser", f: "misera", n: "miserum", },
    },
    root: "miser",
    dutch: "ongelukkig",
    klass: "1r",
    hasSingular: true,
    hasPlural: true,
  },
  "aegr": {
    type: "adjective",
    latin: "aeger",
    explicitDeclensions: {
      nomS: { m: "aeger", f: "aegra", n: "aegrum", },
    },
    root: "aegr",
    dutch: "ziek",
    klass: "1er",
    hasSingular: true,
    hasPlural: true,
  },
  "nigr": {
    type: "adjective",
    latin: "niger",
    explicitDeclensions: {
      nomS: { m: "niger", f: "nigra", n: "nigrum", },
    },
    root: "nigr",
    dutch: "zwart",
    klass: "1er",
    hasSingular: true,
    hasPlural: true,
  },
  "maxim": {
    type: "adjective",
    latin: "maximus",
    explicitDeclensions: {
      nomS: { m: "maximus", f: "maxima", n: "maximum", },
    },
    root: "maxim",
    dutch: "zeer groot; grootste",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "nōt": {
    type: "adjective",
    latin: "nōtus",
    explicitDeclensions: {
      nomS: { m: "nōtus", f: "nōta", n: "nōtum", },
    },
    root: "nōt",
    dutch: "bekend",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "vēr": {
    type: "adjective",
    latin: "vērus",
    explicitDeclensions: {
      nomS: { m: "vērus", f: "vēra", n: "vērum", },
    },
    root: "vēr",
    dutch: "waar; echt",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "dūr": {
    type: "adjective",
    latin: "dūrus",
    explicitDeclensions: {
      nomS: { m: "dūrus", f: "dūra", n: "dūrum", },
    },
    root: "dūr",
    dutch: "hard",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "vīv": {
    type: "adjective",
    latin: "vīvus",
    explicitDeclensions: {
      nomS: { m: "vīvus", f: "vīva", n: "vīvum", },
    },
    root: "vīv",
    dutch: "levend",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "plēn": {
    type: "adjective",
    latin: "plēnus",
    explicitDeclensions: {
      nomS: { m: "plēnus", f: "plēna", n: "plēnum", },
    },
    root: "plēn",
    dutch: "vol",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "cupid": {
    type: "adjective",
    latin: "cupidus",
    explicitDeclensions: {
      nomS: { m: "cupidus", f: "cupida", n: "cupidum", },
    },
    root: "cupid",
    dutch: "vol verlangen naar",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "mīr": {
    type: "adjective",
    latin: "mīrus",
    explicitDeclensions: {
      nomS: { m: "mīrus", f: "mīra", n: "mīrum", },
    },
    root: "mīr",
    dutch: "wonderlijk",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "angust": {
    type: "adjective",
    latin: "angustus",
    explicitDeclensions: {
      nomS: { m: "angustus", f: "angusta", n: "angustum", },
    },
    root: "angust",
    dutch: "smal",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "tūt": {
    type: "adjective",
    latin: "tūtus",
    explicitDeclensions: {
      nomS: { m: "tūtus", f: "tūta", n: "tūtum", },
    },
    root: "tūt",
    dutch: "veilig",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "hūmān": {
    type: "adjective",
    latin: "hūmānus",
    explicitDeclensions: {
      nomS: { m: "hūmānus", f: "hūmāna", n: "hūmānum", },
    },
    root: "hūmān",
    dutch: "menselijk; beschaafd",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "aure": {
    type: "adjective",
    latin: "aureus",
    explicitDeclensions: {
      nomS: { m: "aureus", f: "aurea", n: "aureum", },
    },
    root: "aure",
    dutch: "gouden",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "obscūr": {
    type: "adjective",
    latin: "obscūrus",
    explicitDeclensions: {
      nomS: { m: "obscūrus", f: "obscūra", n: "obscūrum", },
    },
    root: "obscūr",
    dutch: "duister; onbeduidend",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "brev": {
    type: "adjective",
    latin: "brevis",
    explicitDeclensions: {
      nomS: { m: "brevis", f: "brevis", n: "breve", },
    },
    root: "brev",
    dutch: "kort",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "mortāl": {
    type: "adjective",
    latin: "mortālis",
    explicitDeclensions: {
      nomS: { m: "mortālis", f: "mortālis", n: "mortāle", },
    },
    root: "mortāl",
    dutch: "sterfelijk",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "nōbil": {
    type: "adjective",
    latin: "nōbilis",
    explicitDeclensions: {
      nomS: { m: "nōbilis", f: "nōbilis", n: "nōbile", },
    },
    root: "nōbil",
    dutch: "adellijk; beroemd",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "audāc": {
    type: "adjective",
    latin: "audāx",
    explicitDeclensions: {
      nomS: { m: "audāx", f: "audāx", n: "audāx", },
    },
    root: "audāc",
    dutch: "moedig; overmoedig",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "ferōc": {
    type: "adjective",
    latin: "ferōx",
    explicitDeclensions: {
      nomS: { m: "ferōx", f: "ferōx", n: "ferōx", },
    },
    root: "ferōc",
    dutch: "woest; uitdagend",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "facil": {
    type: "adjective",
    latin: "facilis",
    explicitDeclensions: {
      nomS: { m: "facilis", f: "facilis", n: "facile", },
    },
    root: "facil",
    dutch: "gemakkelijk",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "lev": {
    type: "adjective",
    latin: "levis",
    explicitDeclensions: {
      nomS: { m: "levis", f: "levis", n: "leve", },
    },
    root: "lev",
    dutch: "licht",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "simil": {
    type: "adjective",
    latin: "similis",
    explicitDeclensions: {
      nomS: { m: "similis", f: "similis", n: "simile", },
    },
    root: "simil",
    dutch: "gelijk aan; gelijkend op",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "ūtil": {
    type: "adjective",
    latin: "ūtilis",
    explicitDeclensions: {
      nomS: { m: "ūtilis", f: "ūtilis", n: "ūtile", },
    },
    root: "ūtil",
    dutch: "nuttig",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "fēlic": {
    type: "adjective",
    latin: "fēlix",
    explicitDeclensions: {
      nomS: { m: "fēlix", f: "fēlix", n: "fēlix", },
    },
    root: "fēlic",
    dutch: "gelukkig",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "pauper": {
    type: "adjective",
    latin: "pauper",
    explicitDeclensions: {
      nomS: { m: "pauper", f: "pauper", n: "pauper", },
    },
    root: "pauper",
    dutch: "arm",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "ācr": {
    type: "adjective",
    latin: "ācer",
    explicitDeclensions: {
      nomS: { m: "ācer", f: "ācris", n: "ācre", },
    },
    root: "ācr",
    dutch: "scherp; fel",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "aetern": {
    type: "adjective",
    latin: "aeternus",
    explicitDeclensions: {
      nomS: { m: "aeternus", f: "aeterna", n: "aeternum", },
    },
    root: "aetern",
    dutch: "eeuwig",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "ēgregi": {
    type: "adjective",
    latin: "ēgregius",
    explicitDeclensions: {
      nomS: { m: "ēgregius", f: "ēgregia", n: "ēgregium", },
    },
    root: "ēgregi",
    dutch: "buitengewoon",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "secund": {
    type: "adjective",
    latin: "secundus",
    explicitDeclensions: {
      nomS: { m: "secundus", f: "secunda", n: "secundum", },
    },
    root: "secund",
    dutch: "tweede; gunstig",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "tacit": {
    type: "adjective",
    latin: "tacitus",
    explicitDeclensions: {
      nomS: { m: "tacitus", f: "tacita", n: "tacitum", },
    },
    root: "tacit",
    dutch: "zwijgend",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "lent": {
    type: "adjective",
    latin: "lentus",
    explicitDeclensions: {
      nomS: { m: "lentus", f: "lenta", n: "lentum", },
    },
    root: "lent",
    dutch: "traag",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "quiēt": {
    type: "adjective",
    latin: "quiētus",
    explicitDeclensions: {
      nomS: { m: "quiētus", f: "quiēta", n: "quiētum", },
    },
    root: "quiēt",
    dutch: "rustig",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "saev": {
    type: "adjective",
    latin: "saevus",
    explicitDeclensions: {
      nomS: { m: "saevus", f: "saeva", n: "saevum", },
    },
    root: "saev",
    dutch: "woest; wreed",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "infer": {
    type: "adjective",
    latin: "inferus",
    explicitDeclensions: {
      nomS: { m: "inferus", f: "infera", n: "inferum", },
    },
    root: "infer",
    dutch: "laaggelegen",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "tant": {
    type: "adjective",
    latin: "tantus",
    explicitDeclensions: {
      nomS: { m: "tantus", f: "tanta", n: "tantum", },
    },
    root: "tant",
    dutch: "zo groot",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "dulc": {
    type: "adjective",
    latin: "dulcis",
    explicitDeclensions: {
      nomS: { m: "dulcis", f: "dulcis", n: "dulce", },
    },
    root: "dulc",
    dutch: "zoet; lief",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "aliēn": {
    type: "adjective",
    latin: "aliēnus",
    explicitDeclensions: {
      nomS: { m: "aliēnus", f: "aliēna", n: "aliēnum", },
    },
    root: "aliēn",
    dutch: "van een ander; vreemd",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "dext": {
    type: "adjective",
    latin: "dexter",
    explicitDeclensions: {
      nomS: { m: "dexter", f: "dextra", n: "dextrum", },
    },
    root: "dext",
    dutch: "rechts",
    klass: "1er",
    hasSingular: true,
    hasPlural: true,
  },
  "rēgi": {
    type: "adjective",
    latin: "rēgius",
    explicitDeclensions: {
      nomS: { m: "rēgius", f: "rēgia", n: "rēgium", },
    },
    root: "rēgi",
    dutch: "koninklijk",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "familiār": {
    type: "adjective",
    latin: "familiāris",
    explicitDeclensions: {
      nomS: { m: "familiāris", f: "familiāris", n: "familiāre", },
    },
    root: "familiār",
    dutch: "van het gezin; goede bekende",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "turp": {
    type: "adjective",
    latin: "turpis",
    explicitDeclensions: {
      nomS: { m: "turpis", f: "turpis", n: "turpe", },
    },
    root: "turp",
    dutch: "lelijk; schandelijk",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "tāl": {
    type: "adjective",
    latin: "tālis",
    explicitDeclensions: {
      nomS: { m: "tālis", f: "tālis", n: "tāle", },
    },
    root: "tāl",
    dutch: "zodanig",
    klass: 2,
    hasSingular: true,
    hasPlural: true,
  },
  "quant": {
    type: "adjective",
    latin: "quantus",
    explicitDeclensions: {
      nomS: { m: "quantus", f: "quanta", n: "quantum", },
    },
    root: "quant",
    dutch: "hoe groot?; (zo groot) als",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "callid": {
    type: "adjective",
    latin: "callidus",
    explicitDeclensions: {
      nomS: { m: "callidus", f: "callida", n: "callidum", },
    },
    root: "callid",
    dutch: "sluw",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "reliqu": {
    type: "adjective",
    latin: "reliquus",
    explicitDeclensions: {
      nomS: { m: "reliquus", f: "reliqua", n: "reliquum", },
    },
    root: "reliqu",
    dutch: "overig",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "aequ": {
    type: "adjective",
    latin: "aequus",
    explicitDeclensions: {
      nomS: { m: "aequus", f: "aequa", n: "aequum", },
    },
    root: "aequ",
    dutch: "gelijk; eerlijk",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "parāt": {
    type: "adjective",
    latin: "parātus",
    explicitDeclensions: {
      nomS: { m: "parātus", f: "parāta", n: "parātum", },
    },
    root: "parāt",
    dutch: "bereid",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "patri": {
    type: "adjective",
    latin: "patrius",
    explicitDeclensions: {
      nomS: { m: "patrius", f: "patria", n: "patrium", },
    },
    root: "patri",
    dutch: "vaderlijk; voorvaderlijk",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "poster": {
    type: "adjective",
    latin: "posterus",
    explicitDeclensions: {
      nomS: { m: "posterus", f: "postera", n: "posterum", },
    },
    root: "poster",
    dutch: "volgend",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "valid": {
    type: "adjective",
    latin: "validus",
    explicitDeclensions: {
      nomS: { m: "validus", f: "valida", n: "validum", },
    },
    root: "valid",
    dutch: "krachtig, sterk",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "crēbr": {
    type: "adjective",
    latin: "crēber",
    explicitDeclensions: {
      nomS: { m: "crēber", f: "crēbra", n: "crēbrum", },
    },
    root: "crēbr",
    dutch: "talrijk",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "proxim": {
    type: "adjective",
    latin: "proximus",
    explicitDeclensions: {
      nomS: { m: "proximus", f: "proxima", n: "proximum", },
    },
    root: "proxim",
    dutch: "dichtstbij; verwant",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "barbar": {
    type: "adjective",
    latin: "barbarus",
    explicitDeclensions: {
      nomS: { m: "barbarus", f: "barbara", n: "barbarum", },
    },
    root: "barbar",
    dutch: "vreemd; onbeschaafd",
    klass: 1,
    hasSingular: true,
    hasPlural: true,
  },
  "complūr": {
    type: "adjective",
    latin: "complūrēs",
    explicitDeclensions: {
      nomP: { m: "complūrēs", f: "complūrēs", n: "complūra", },
    },
    root: "complūr",
    dutch: "meerdere",
    klass: 2,
    hasSingular: false,
    hasPlural: true,
  },
  // "": {
  //   type: "adjective",
  //   latin: "",
  //   explicitDeclensions: {
  //     nomS: { m: "", f: "", n: "", },
  //   },
  //   root: "",
  //   dutch: "",
  //   klass: ,
  //   hasSingular: true,
  //   hasPlural: true,
  // },
};

export function searchLatinAdjective(root: keyof typeof LATIN_ADJECTIVES): LatinAdjective {
  if (!(root in LATIN_ADJECTIVES)) {
    throw new Error(`No Latin adjective found for root: ${root}`);
  }
  return LATIN_ADJECTIVES[root];
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_PLURAL_ADJECTIVES: Array<LatinPluralAdjective> = [
  {
    type: "pluralAdjective",
    latin: "omnēs",
    root: "omni",
    dutch: "alle",
    klass: 2,
  }
];

export function searchLatinPluralAdjective(root: string): LatinPluralAdjective {
  const results = LATIN_PLURAL_ADJECTIVES.filter((pluralAdjective) => pluralAdjective.root === root);
  if (results.length !== 1) {
    console.error(results);
    throw new Error(`No single Latin plural adjective found for root: ${root}`);
  }
  return results[0];
}