import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { T } from "../components/T";
import { VocabularyCode } from "../data/Data";
import { GREEK_2024_NEA_THALASSA_1_VOCABULARY } from "../data/greek/2024/NeaThalassa1";
import * as fromGreekWord from "../data/greek/GreekWord";
import { LATIN_2023_PEGASUS_NOVUS_1_VOCABULARY } from "../data/latin/2023/PegasusNovus1";
import { LATIN_2024_PEGASUS_NOVUS_2_VOCABULARY } from "../data/latin/2024/PegasusNovus2";
import * as fromLatinWord from "../data/latin/LatinWord";
import * as Scores from "../server/Scores";
import { IndicesByScoreMap, ScoreMap } from "../server/Scores";
import { toCsr } from "../utils/csr";
import { Optional } from "../utils/Optional";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type BakedWordSearchValues<K extends string> = Record<K, string>;
type BakedWordDisplayValues<K extends string> = Record<K, React.ReactNode>;

interface BakedWord<K extends string> {
  dbIndex: number;
  logicalIndex: number;
  searchValues: BakedWordSearchValues<K>;
  displayValues: BakedWordDisplayValues<K>;
}

type BakedVocabulary<K extends string> = BakedWord<K>[];

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface StarProps {
  score: Scores.Score;
}

function Star({ score }: StarProps) {
  const color = `text-${Scores.scoreToColor(score)}`;
  return (
    <i className={`bi bi-${score === 0 ? "star" : "star-fill"} ${color}`} />
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface ScoreSelectorProps {
  score: Scores.Score;
  onChange: (score: Scores.Score) => void;
}

function ScoreSelector({ score, onChange }: ScoreSelectorProps) {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <select className="form-control" value={score} onChange={(event) => onChange(Number(event.target.value) as any)}>
      <option value={0}>Onbekend</option>
      <option value={1}>&le; Opnieuw</option>
      <option value={2}>&le; Moeilijk</option>
      <option value={3}>&le; Goed</option>
      <option value={4}>&le; Eenvoudig</option>
    </select>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface SelectionProps<K extends string> {
  code: VocabularyCode;
  bakedVocabulary: BakedVocabulary<K>;
  indexWidth: number;
  scoreWidth: number;
  cols: {
    key: K;
    title: string;
    width: number;
    canSearch?: boolean;
    searchifyFn?: (value: string) => string;
  }[];
  navigateFn: (csr: string) => void;
}

interface SelectionState<K extends string> {
  from: string;
  to: string;
  selectionValues: Optional<BakedWordSearchValues<K>>;
  minLogicalIndex: number;
  maxLogicalIndex: number;
  scores: ScoreMap;
  indicesByScore: IndicesByScoreMap;
  selectionScore: Scores.Score
}

export class Selection<K extends string> extends React.Component<SelectionProps<K>, SelectionState<K>> {
  public state: SelectionState<K> = {
    from: "1",
    to: "0",
    selectionValues: {},
    minLogicalIndex: Number.POSITIVE_INFINITY,
    maxLogicalIndex: Number.NEGATIVE_INFINITY,
    scores: {},
    indicesByScore: Scores.createIndicesByScoreMap(),
    selectionScore: 4,
  }

  public constructor(props: SelectionProps<K>) {
    super(props);

    const [ minLogicalIndex, maxLogicalIndex, ] = this.searchMinMax();
    this.state.minLogicalIndex = minLogicalIndex;
    this.state.from = String(minLogicalIndex);
    this.state.maxLogicalIndex = maxLogicalIndex;
    this.state.to = String(maxLogicalIndex);
  }

  public override componentDidMount(): void {
    this.fetchScores();
  }

  public override render() {
    const { indexWidth, scoreWidth, cols } = this.props;
    const { from, to, selectionValues, selectionScore } = this.state;
    return (
      <>
        <form className="row align-items-center range-selector">
          <div className="col-auto">
            <label htmlFor="from" className="col-form-label">A</label>
          </div>
          <div className="col-auto">
            <input id="from"
                   name="from"
                   type="number"
                   className="form-control"
                   value={from}
                   onChange={this.handleChangeFrom}
                   required />
          </div>
          <div className="col-auto">
            <label htmlFor="to" className="col-form-label">Ad</label>
          </div>
          <div className="col-auto">
            <input id="to"
                   name="to"
                   type="number"
                   className="form-control"
                   value={to}
                   onChange={this.handleChangeTo}
                   required />
          </div>
          <div className="col-auto ms-auto">
            <button className={`btn btn-success`} onClick={this.handleNavigate}>
              Oefen!
            </button>
          </div>
        </form>
        <div className="row">
          <table className="table">
            <thead>
              <tr>
                <th scope="col" style={{ width: this.widthToPctString(indexWidth) }}>#</th>
                {
                  cols.map(({ key, title, width }) => (
                    <th key={String(key)}
                        scope="col"
                        style={{ width: this.widthToPctString(width) }}>
                      {title}
                    </th>
                  ))
                }
                <th scope="col" style={{ width: this.widthToPctString(scoreWidth) }}>Score</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td />
                {
                  cols.map(({ key, canSearch = true, }, index) => (
                    <td key={index}>
                      {
                        canSearch
                          ? <input id={String(key)}
                                   className="form-control"
                                   placeholder={"Zoek..."}
                                   value={selectionValues[key] ?? ""}
                                   onChange={this.handleChangeSelection(key)} />
                          : null
                      }
                    </td>
                  ))
                }
                <td>
                  <div className="col-form-label">
                    <ScoreSelector score={selectionScore} onChange={this.handleChangeSelectionScore} />
                  </div>
                </td>
              </tr>
              {
                this.selection()
                  .map(({ dbIndex, logicalIndex, displayValues }) => (
                    <tr key={dbIndex}>
                      <th scope="row">{logicalIndex}</th>
                      {
                        cols.map(({ key }, index) => (
                          <td key={index}>{displayValues[key]}</td>
                        ))
                      }
                      <td>
                        <Star score={this.state.scores[dbIndex] ?? 0} />
                      </td>
                    </tr>
                  ))
              }
            </tbody>
          </table>
        </div>
      </>
    );
  }

  private widthToPctString(width: number): string {
    const { indexWidth, cols, scoreWidth } = this.props;
    const totalWidth = cols.reduce((acc, { width }) => acc + width, indexWidth + scoreWidth);
    return `${Math.round((width / totalWidth) * 100)}%`;
  }

  private searchMinMax(): [ number, number ] {
    const { bakedVocabulary: vocabulary, } = this.props;
    let minimumLogicalIndex = Number.POSITIVE_INFINITY;
    let maximumLogicalIndex = Number.NEGATIVE_INFINITY;

    vocabulary.forEach(({ logicalIndex }) => {
      if (logicalIndex < minimumLogicalIndex) minimumLogicalIndex = logicalIndex;
      if (logicalIndex > maximumLogicalIndex) maximumLogicalIndex = logicalIndex;
    });

    return [ minimumLogicalIndex, maximumLogicalIndex ];
  }

  private handleChangeFrom: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({ from: event.target.value });
  }

  private handleChangeTo: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({ to: event.target.value });
  }

  private handleChangeSelection(key: K): React.ChangeEventHandler<HTMLInputElement> {
    return (event) => {
      const { selectionValues: selection } = this.state;
      selection[key] = event.target.value;
      this.setState({ selectionValues: selection });
    };
  }

  private handleChangeSelectionScore = (selectionScore: Scores.Score) => {
    this.setState({ selectionScore });
  }

  private handleNavigate: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    const csr = toCsr(this.selection().map(({ dbIndex }) => dbIndex));
    this.props.navigateFn(csr);
  }

  private safeFromLogicalIndex(): number {
    const { minLogicalIndex } = this.state;
    const fromLogicalIndex = Number(this.state.from);
    if (isNaN(fromLogicalIndex)) return minLogicalIndex;
    if (fromLogicalIndex < minLogicalIndex) return minLogicalIndex;
    const to = this.safeToLogicalIndex();
    if (fromLogicalIndex > to) return to;
    return fromLogicalIndex;
  }

  private safeToLogicalIndex(): number {
    const { minLogicalIndex, maxLogicalIndex } = this.state;
    const toLogicalIndex = Number(this.state.to);
    if (isNaN(toLogicalIndex)) return maxLogicalIndex;
    if (toLogicalIndex < minLogicalIndex) return minLogicalIndex;
    if (toLogicalIndex > maxLogicalIndex) return maxLogicalIndex;
    return toLogicalIndex;
  }

  private selection(): BakedWord<K>[] {
    const { scores, selectionScore } = this.state;

    const safeFromLogicalIndx = this.safeFromLogicalIndex();
    const safeToLogicalIndex = this.safeToLogicalIndex();

    const relevantFilters: { key: K, searchValue: string }[] = [ ];
    for (const col of this.props.cols) {
      const { key, searchifyFn = (x) => x } = col;
      const value: string = _.trim(this.state.selectionValues?.[key] ?? "").toLowerCase();
      if (value.length > 0) relevantFilters.push({ key, searchValue: searchifyFn(value) });
    }

    const selection: BakedWord<K>[] = [ ];
    this.props.bakedVocabulary.forEach((word) => {
      const { logicalIndex } = word;
      if ((logicalIndex < safeFromLogicalIndx) || (logicalIndex > safeToLogicalIndex)) return;
      for (const { key, searchValue } of relevantFilters) {
        if (!word.searchValues[key].includes(searchValue)) return;
      }
      if (selectionScore < (scores[word.dbIndex] ?? 0)) return;
      selection.push(word);
    });

    return selection;
  }

  private fetchScores = async () => {
    const { code } = this.props;
    const [ scores, indicesByScore ] = await Scores.forCode(code);
    this.setState({ scores, indicesByScore });
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function Latin2023PegasusNovus1Selection() {
  const navigate = useNavigate();

  const vocabulary = LATIN_2023_PEGASUS_NOVUS_1_VOCABULARY.vocabulary.map((word, dbIndex) => {
    const type = fromLatinWord.toDutchType(word);
    const foreign = fromLatinWord.toForeignTitle(word);
    const dutch = fromLatinWord.toDutchTitle(word);
    return {
      dbIndex,
      logicalIndex: dbIndex + 1,
      searchValues: {
        type: type.toLowerCase(),
        foreign: fromLatinWord.searchify(foreign).toLowerCase(),
        dutch: dutch.toLowerCase(),
      },
      displayValues: { type, foreign, dutch, },
    };
  });

  const navigateFn = (csr: string) => navigate(`/data/2023-pegasus-novus-1/range/${csr}`);
  return <Selection code="2023-pegasus-novus-1"
                    bakedVocabulary={vocabulary}
                    indexWidth={5}
                    scoreWidth={10}
                    cols={[
                      { key: "type", title: "Type", width: 20, },
                      { key: "foreign", title: "Latijns", width: 30, searchifyFn: fromLatinWord.searchify, },
                      { key: "dutch", title: "Nederlands", width: 35, },
                    ]}
                    navigateFn={navigateFn} />;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function Latin2024PegasusNovus2Selection() {
  const navigate = useNavigate();

  const vocabulary = LATIN_2024_PEGASUS_NOVUS_2_VOCABULARY.vocabulary.map((word, dbIndex) => {
    const { logicalIndex } = word._2024_pegasus_novus_2;
    const type = fromLatinWord.toDutchType(word);
    const foreign = fromLatinWord.toForeignTitle(word);
    const dutch = fromLatinWord.toDutchTitle(word);
    return {
      dbIndex,
      logicalIndex,
      searchValues: {
        type: type.toLowerCase(),
        foreign: fromLatinWord.searchify(foreign).toLowerCase(),
        dutch: dutch.toLowerCase(),
      },
      displayValues: { type, foreign, dutch, },
    };
  });

  const navigateFn = (csr: string) => navigate(`/data/2024-pegasus-novus-2/range/${csr}`);
  return <Selection code="2024-pegasus-novus-2"
                    bakedVocabulary={vocabulary}
                    indexWidth={5}
                    scoreWidth={10}
                    cols={[
                      { key: "type", title: "Type", width: 20, },
                      { key: "foreign", title: "Latijns", width: 30, searchifyFn: fromLatinWord.searchify, },
                      { key: "dutch", title: "Nederlands", width: 35, },
                    ]}
                    navigateFn={navigateFn} />;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function Greek2024NeaThalassa1Selection() {
  const navigate = useNavigate();

  const vocabulary = GREEK_2024_NEA_THALASSA_1_VOCABULARY.vocabulary.map((word, dbIndex) => {
    const { logicalIndex } = word._2024_nea_thalassa_1;
    const type = fromGreekWord.toDutchType(word);
    const foreign = fromGreekWord.toForeignTitle(word);
    const dutch = fromGreekWord.toDutchTitle(word);
    return {
      dbIndex,
      logicalIndex,
      searchValues: {
        type: type.toLowerCase(),
        foreign: fromGreekWord.searchify(foreign).toLowerCase(),
        dutch: dutch.toLowerCase(),
      },
      displayValues: { type, foreign: <T l="gr">{foreign}</T>, dutch, },
    };
  });

  const navigateFn = (csr: string) => navigate(`/data/2024-nea-thalassa-1/range/${csr}`);
  return <Selection code="2024-nea-thalassa-1"
                    bakedVocabulary={vocabulary}
                    indexWidth={5}
                    scoreWidth={10}
                    cols={[
                      { key: "type", title: "Type", width: 20, },
                      { key: "foreign", title: "Grieks", width: 30, searchifyFn: fromGreekWord.searchifySearchTerm },
                      { key: "dutch", title: "Nederlands", width: 35, },
                    ]}
                    navigateFn={navigateFn} />;
}