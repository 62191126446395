import { searchWord } from "../../WordBase";
import { LatinToFixIrregularWord } from "../LatinWord";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_TO_FIXES: Array<LatinToFixIrregularWord> = [
  {
    type: "toFixIrregularWord",
    latin: "alius ..., alius ...",
    dutch: "sommige(n) ..., andere(n) ...",
  },
  {
    type: "toFixIrregularWord",
    latin: "aliī ..., aliī",
    dutch: "sommige(n) ..., andere(n) ...",
  },
  {
    type: "toFixIrregularWord",
    latin: "alter ..., alter ...",
    dutch: "de ene ..., de andere ...",
  },
  {
    type: "toFixIrregularWord",
    latin: "hortāri, ~or",
    dutch: "aansporen",
  },
  {
    type: "toFixIrregularWord",
    latin: "verēri, ~eor",
    dutch: "vrezen",
  },
  {
    type: "toFixIrregularWord",
    latin: "loquī, ~or",
    dutch: "spreken",
  },
  {
    type: "toFixIrregularWord",
    latin: "potīrī, ~ior + abl.",
    dutch: "bemachtigen; beheersen",
  },
  {
    type: "toFixIrregularWord",
    latin: "patī, ~ior",
    dutch: "verdragen",
  },
  {
    type: "toFixIrregularWord",
    latin: "cōnārī, ~or",
    dutch: "proberen",
  },
  {
    type: "toFixIrregularWord",
    latin: "mīrārī, ~or",
    dutch: "zich verwonderen over; bewonderen",
  },
  {
    type: "toFixIrregularWord",
    latin: "tuēri, ~eor",
    dutch: "bekijken, beschermen",
  },
  {
    type: "toFixIrregularWord",
    latin: "pollicērī, ~eor",
    dutch: "beloven",
  },
  {
    type: "toFixIrregularWord",
    latin: "fungi, ~or + abl.",
    dutch: "verrichten; uitoefenen",
  },
  {
    type: "toFixIrregularWord",
    latin: "nāscī, ~or",
    dutch: "geboren worden",
  },
  // {
  //   type: "toFixIrregularWord",
  //   latin: "",
  //   dutch: "",
  // },
];

export const searchLatinToFixIrregularWord = searchWord(LATIN_TO_FIXES, "Latin to-fix-word", "latin");