import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import "./main.scss";
import { Range } from "./routes/Range";
import Root from "./routes/Root";
import { Greek2024NeaThalassa1Selection, Latin2023PegasusNovus1Selection, Latin2024PegasusNovus2Selection } from "./routes/Selection";
import "./scss/styles.scss";
import { SessionProvider } from "./components/SessionContext";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "data/:code/range/:csr",
        element: <Range />,
      },
      {
        path: "data/:code/range",
        element: <Range />,
      },
      {
        path: "data/2023-pegasus-novus-1",
        element: <Latin2023PegasusNovus1Selection />,
      },
      {
        path: "data/2024-pegasus-novus-2",
        element: <Latin2024PegasusNovus2Selection />,
      },
      {
        path: "data/2024-nea-thalassa-1",
        element: <Greek2024NeaThalassa1Selection />,
      },
    ],
  },
], {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_relativeSplatPath: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  }
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <SessionProvider>
      <RouterProvider router={router}
                      future={{
                        v7_startTransition: true,
                      }} />
    </SessionProvider>
  </React.StrictMode>,
);