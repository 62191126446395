import { GreekWord, toDutchTitle, toForeignTitle } from "../../data/greek/GreekWord";
import "../../main.scss";
import { Gr } from "../T";
import "../WordFull.scss";
import * as rt from "runtypes";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface GreekDefaultFullProps {
  word: GreekWord;
}

const HasPassport = rt.Record({
  passport: rt.String
});

function GreekDefaultFull({ word }: GreekDefaultFullProps) {
  return (
    <div className="word-full">
      <div className="word-full-title">
        Grondwoord: <Gr>{toForeignTitle(word)}</Gr>
        {
          // TODO: split off into different components.
          (word.type === "verb") || (word.type === "noun") || HasPassport.guard(word)
            ? <span className="float-end">Paspoort: <Gr>{word.passport}</Gr></span>
            : null
        }
      </div>
      <div className="word-full-translation">
        {toDutchTitle(word)}
      </div>
    </div>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export default GreekDefaultFull;