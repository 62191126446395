import { useSession } from "./SessionContext";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function SessionLinks() {
  const session = useSession();
  if (session === null) {
    return (
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a href="/users/sign_in" className="nav-link">Inloggen</a>
        </li>
      </ul>
    );
  } else {
    return (
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a href="/users/edit" className="nav-link">{session.email}</a>
        </li>
        <li className="nav-item">
          <a href="/users/sign_out" className="nav-link"><i className="bi bi-box-arrow-right" /></a>
        </li>
      </ul>
    );
  }
}