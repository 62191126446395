import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { fromCsr } from "../utils/csr";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function useDbIndicesFromParams() {
  const params = useParams();
  const [ dbIndices, setDbIndices ] = useState<number[]>([]);
  useEffect(() => {
    const csr = params?.csr ?? "";
    const parsedDbIndices = fromCsr(csr);
    setDbIndices(parsedDbIndices);
  }, [ params ]);
  return dbIndices;
}