import { BakedVocabulary, VocabularyCode } from "../../data/Data.tsx";
import { nominative } from "../../data/latin/LatinNoun.tsx";
import { LatinWord } from "../../data/latin/LatinWord.tsx";
import { assertNever } from "../../utils/assertNever.tsx";
import "../Practice.scss";
import { Practice } from "../Practice.tsx";
import LatinAdjectiveFull from "./LatinAdjectiveFull.tsx";
import LatinDefaultFull from "./LatinDefaultFull.tsx";
import LatinIndefinitePronounFull from "./LatinIndefinitePronounFull.tsx";
import LatinNounFull from "./LatinNounFull.tsx";
import LatinPluralAdjectiveFull from "./LatinPluralAdjectiveFull.tsx";
import LatinVerbFull from "./LatinVerbFull.tsx";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

function LatinQuestionBody({ word }: { word: LatinWord }): React.ReactNode {
  switch (word.type) {
    case "noun": {
      return <div>{nominative(word)}</div>;
    }
    case "adjective": {
      return <div>{word.latin}</div>;
    }
    case "fixedAdjective": {
      return <div>{word.latin}</div>;
    }
    case "pluralAdjective": {
      return <div>{word.latin}</div>;
    }
    case "conjugatedVerb": {
      return <div>{word.latin}</div>;
    }
    case "adverb": {
      return <div>{word.latin}</div>;
    }
    case "conjunction": {
      return <div>{word.latin}</div>;
    }
    case "negation": {
      return <div>{word.latin}</div>
    }
    case "verb": {
      return <div>{word.infinitive}</div>;
    }
    case "passiveVerb": {
      return <div>{word.latin}</div>
    }
    case "countingWord": {
      return <div>{word.latin}</div>;
    }
    case "questionParticle": {
      return <div>{word.latin}</div>;
    }
    case "indefinitePronoun": {
      return <div>{word.latin}</div>;
    }
    case "interjection": {
      return <div>{word.latin}</div>;
    }
    case "personalPronoun": {
      return <div>{word.latin}</div>;
    }
    case "possessivePronoun": {
      return <div>{word.latin}</div>;
    }
    case "interrogativePronoun": {
      return <div>{word.latin}</div>;
    }
    case "demonstrativePronoun": {
      return <div>{word.latin}</div>;
    }
    case "prepositionAccusative": {
      return <div>{word.latin} + <em>acc.</em></div>;
    }
    case "prepositionAblative": {
      return <div>{word.latin} + <em>abl.</em></div>;
    }
    case "toFixIrregularWord": {
      return <div>{word.latin}</div>;
    }
    default:
      return assertNever(word);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

function LatinAnswerBody({ word }: { word: LatinWord }): React.ReactNode {
  switch (word.type) {
    case "noun": {
      return <LatinNounFull noun={word} />;
    }
    case "adjective": {
      return <LatinAdjectiveFull word={word} />;
    }
    case "fixedAdjective": {
      return <LatinDefaultFull word={word} />;
    }
    case "pluralAdjective": {
      return <LatinPluralAdjectiveFull word={word} />;
    }
    case "conjugatedVerb": {
      return <LatinDefaultFull word={word} />;
    }
    case "adverb": {
      return <LatinDefaultFull word={word} />;
    }
    case "conjunction": {
      return <LatinDefaultFull word={word} />;
    }
    case "negation": {
      return <LatinDefaultFull word={word} />;
    }
    case "verb": {
      return <LatinVerbFull verb={word} />;
    }
    case "passiveVerb": {
      return <LatinDefaultFull word={word} />;
    }
    case "countingWord": {
      return <LatinDefaultFull word={word} />;
    }
    case "questionParticle": {
      return <LatinDefaultFull word={word} />;
    }
    case "indefinitePronoun": {
      return <LatinIndefinitePronounFull pronoun={word} />;
    }
    case "interjection": {
      return <LatinDefaultFull word={word} />;
    }
    case "personalPronoun": {
      return <LatinDefaultFull word={word} />;
    }
    case "possessivePronoun": {
      return <LatinDefaultFull word={word} />;
    }
    case "interrogativePronoun": {
      return <LatinDefaultFull word={word} />;
    }
    case "demonstrativePronoun": {
      return <LatinDefaultFull word={word} />;
    }
    case "prepositionAccusative": {
      return <LatinDefaultFull word={word} />;
    }
    case "prepositionAblative": {
      return <LatinDefaultFull word={word} />;
    }
    case "toFixIrregularWord": {
      return <LatinDefaultFull word={word} />;
    }
    default:
      return assertNever(word);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface LatinPracticeProps<W extends LatinWord> {
  code: VocabularyCode,
  vocabulary: W[];
  bakedVocabulary: BakedVocabulary;
  answerBodyFn?: (word: W) => React.ReactNode;
}

export function LatinPractice<W extends LatinWord>({
  code,
  vocabulary,
  bakedVocabulary,
  answerBodyFn
}: LatinPracticeProps<W>) {
  return <Practice code={code}
                   questionComponent={LatinQuestionBody}
                   answerComponent={LatinAnswerBody}
                   vocabulary={vocabulary}
                   bakedVocabulary={bakedVocabulary}
                   answerBodyFn={answerBodyFn} />;
}