import { searchWord } from "../WordBase";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekConjunction {
  type: "conjunction";  // "voegwoord"
  ground: string;
  dutch: string;
}

export const GREEK_CONJUNCTION: GreekConjunction[] = [
  {
    type: "conjunction",
    ground: "καί",
    dutch: "en",
  },
  {
    type: "conjunction",
    ground: "δέ",
    dutch: "en, maar (blijft vaak onvertaald)",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BF%E1%BD%96%CE%BD
    type: "conjunction",
    ground: "οὖν",
    dutch: "dus",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%80%CE%BB%CE%BB%CE%AC
    type: "conjunction",
    ground: "ἀλλά",
    dutch: "maar, echter",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BF%E1%BD%94%CF%84%CE%B5
    type: "conjunction",
    ground: "οὔτε ... οὔτε",
    dutch: "niet ... en niet, noch ... noch",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BD%85%CF%84%CE%B5
    type: "conjunction",
    ground: "ὅτε",
    dutch: "wanneer, toen",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B3%CE%AC%CF%81
    type: "conjunction",
    ground: "γᾰ́ρ",
    dutch: "want",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%A4
    type: "conjunction",
    ground: "ἤ",
    dutch: "of, (in een vergelijking) dan",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BC%CE%AD%CE%BD
    type: "conjunction",
    ground: "μέν ... δέ",
    dutch: "wel ... maar, enerzijds ... anderzijds",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BC%CE%AD%CE%BD%CF%84%CE%BF%CE%B9
    type: "conjunction",
    ground: "μέντοι",
    dutch: "echter",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BD%85%CF%80%CF%89%CF%82
    type: "conjunction",
    ground: "ὅπως",
    dutch: "toch",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%84%CE%B5
    type: "conjunction",
    ground: "τε",
    dutch: "en",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BD%85%CF%84%CE%B9
    type: "conjunction",
    ground: "ὅτι",
    dutch: "omdat, dat",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%CE%B9%CF%8C%CF%84%CE%B9
    type: "conjunction",
    ground: "δῐότῐ",
    dutch: "omdat",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B5%E1%BC%B0
    type: "conjunction",
    ground: "εἰ",
    dutch: "als, indien",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BD%A5%CF%83%CF%84%CE%B5
    type: "conjunction",
    ground: "ὥστε",
    dutch: "zodat",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BD%A5%CF%83%CF%80%CE%B5%CF%81
    type: "conjunction",
    ground: "ὥσπερ",
    dutch: "zoals",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%95%CF%89%CF%82
    type: "conjunction",
    ground: "ἕως",
    dutch: "zolang als, totdat",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BF%E1%BD%90%CE%B4%CE%AD
    type: "conjunction",
    ground: "οὐδέ",
    dutch: "en niet",
  },
  // {
  //   type: "conjunction",
  //   ground: "",
  //   dutch: "",
  // },
];

export const searchGreekConjunction = searchWord(GREEK_CONJUNCTION, "Greek conjunction", "ground");