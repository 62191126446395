import { LatinVerb } from "../LatinVerb";
import { LatinConjugatedVerb } from "../LatinWord";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_VERBS: { [key: string]: LatinVerb } = {
// export const LATIN_VERBS = {
  "intrāre": {
    "type": "verb",
    "infinitive": "intrāre",
    "s1Form": "intrō",
    "root": "intr",
    "klass": "-āre",
    "dutch": "binnengaan"
  },
  "portāre": {
    "type": "verb",
    "infinitive": "portāre",
    "s1Form": "portō",
    "root": "port",
    "klass": "-āre",
    "dutch": "dragen"
  },
  "rogāre": {
    "type": "verb",
    "infinitive": "rogāre",
    "s1Form": "rogō",
    "root": "rog",
    "klass": "-āre",
    "dutch": "vragen"
  },
  "aedificāre": {
    "type": "verb",
    "infinitive": "aedificāre",
    "s1Form": "aedificō",
    "root": "aedific",
    "klass": "-āre",
    "dutch": "bouwen"
  },
  "clāmāre": {
    "type": "verb",
    "infinitive": "clāmāre",
    "s1Form": "clāmō",
    "root": "clām",
    "klass": "-āre",
    "dutch": "roepen"
  },
  "dormīre": {
    "type": "verb",
    "infinitive": "dormīre",
    "s1Form": "dormiō",
    "root": "dorm",
    "klass": "-īre",
    "dutch": "slapen"
  },
  "habēre": {
    "type": "verb",
    "infinitive": "habēre",
    "s1Form": "habeō",
    "root": "hab",
    "klass": "-ēre",
    "dutch": "hebben; beschouwen als"
  },
  "cōgitāre": {
    "type": "verb",
    "infinitive": "cōgitāre",
    "s1Form": "cōgitō",
    "root": "cōgit",
    "klass": "-āre",
    "dutch": "(na)denken"
  },
  "pāscere": {
    "type": "verb",
    "infinitive": "pāscere",
    "s1Form": "pāscō",
    "root": "pāsc",
    "klass": "tegere",
    "dutch": "weiden; hoeden; laten grazen"
  },
  "augēre": {
    "type": "verb",
    "infinitive": "augēre",
    "s1Form": "augeō",
    "root": "aug",
    "klass": "-ēre",
    "dutch": "doen groeien; vermeerderen"
  },
  "iūdicāre": {
    "type": "verb",
    "infinitive": "iūdicāre",
    "s1Form": "iūdicō",
    "root": "iūdic",
    "klass": "-āre",
    "dutch": "oordelen"
  },
  "parāre": {
    "type": "verb",
    "infinitive": "parāre",
    "s1Form": "parō",
    "root": "par",
    "klass": "-āre",
    "dutch": "klaarmaken; verwerven"
  },
  "rēgnāre": {
    "type": "verb",
    "infinitive": "rēgnāre",
    "s1Form": "rēgnō",
    "root": "rēgn",
    "klass": "-āre",
    "dutch": "heersen"
  },
  "audēre": {
    "type": "verb",
    "infinitive": "audēre",
    "s1Form": "audeō",
    "root": "aud",
    "klass": "-ēre",
    "dutch": "durven"
  },
  "dēbēre": {
    "type": "verb",
    "infinitive": "dēbēre",
    "s1Form": "dēbeō",
    "root": "dēb",
    "klass": "-ēre",
    "dutch": "moeten; verschuldigd zijn"
  },
  "iacēre": {
    "type": "verb",
    "infinitive": "iacēre",
    "s1Form": "iaceō",
    "root": "iac",
    "klass": "-ēre",
    "dutch": "liggen"
  },
  "fīnīre": {
    "type": "verb",
    "infinitive": "fīnīre",
    "s1Form": "fīniō",
    "root": "fīn",
    "klass": "-īre",
    "dutch": "beëindigen; begrenzen"
  },
  "amāre": {
    "type": "verb",
    "infinitive": "amāre",
    "s1Form": "amō",
    "root": "am",
    "klass": "-āre",
    "dutch": "beminnen; houden van"
  },
  "monēre": {
    "type": "verb",
    "infinitive": "monēre",
    "s1Form": "moneō",
    "root": "mon",
    "klass": "-ēre",
    "dutch": "waarschuwen"
  },
  "tegere": {
    "type": "verb",
    "infinitive": "tegere",
    "s1Form": "tegō",
    "root": "teg",
    "klass": "tegere",
    "dutch": "bedekken; beschermen"
  },
  "audīre": {
    "type": "verb",
    "infinitive": "audīre",
    "s1Form": "audiō",
    "root": "aud",
    "klass": "-īre",
    "dutch": "horen; luisteren naar"
  },
  "capere": {
    "type": "verb",
    "infinitive": "capere",
    "s1Form": "capiō",
    "root": "cap",
    "klass": "capere",
    "dutch": "(in)nemen"
  },
  "dīcere": {
    "type": "verb",
    "infinitive": "dīcere",
    "s1Form": "dīcō",
    "root": "dīc",
    "klass": "tegere",
    "dutch": "zeggen; spreken; noemen"
  },
  "dūcere": {
    "type": "verb",
    "infinitive": "dūcere",
    "s1Form": "dūcō",
    "root": "dūc",
    "klass": "tegere",
    "dutch": "leiden"
  },
  "facere": {
    "type": "verb",
    "infinitive": "facere",
    "s1Form": "faciō",
    "root": "fac",
    "klass": "capere",
    "dutch": "maken; doen"
  },
  "esse": {
    "type": "verb",
    "infinitive": "esse",
    "s1Form": "sum",
    "root": "",
    "klass": "esse",
    "dutch": "zijn; bestaan"
  },
  "posse": {
    "type": "verb",
    "infinitive": "posse",
    "s1Form": "possum",
    "root": "",
    "klass": "posse",
    "dutch": "kunnen"
  },
  "īre": {
    "type": "verb",
    "infinitive": "īre",
    "s1Form": "eō",
    "root": "",
    "klass": "īre",
    "dutch": "gaan"
  },
  "velle": {
    "type": "verb",
    "infinitive": "velle",
    "s1Form": "volō",
    "root": "",
    "klass": "velle",
    "dutch": "willen",
    "explicitConjugations": {
      "active": {
        "indicative": {
          "present": {
            "s1": "volō",
            "s2": "vīs",
            "s3": "vult",
            "p1": "volumus",
            "p2": "vultis",
            "p3": "volunt"
          }
        }
      }
    }
  },
  "nōlle": {
    "type": "verb",
    "infinitive": "nōlle",
    "s1Form": "nōlō",
    "root": "",
    "klass": "velle",
    "dutch": "niet willen",
    "explicitConjugations": {
      "active": {
        "indicative": {
          "present": {
            "s1": "nōlō",
            "s2": "nōn vīs",
            "s3": "nōn vult",
            "p1": "nōlumus",
            "p2": "nōn vultis",
            "p3": "nōlunt"
          }
        }
      }
    }
  },
  "mālle": {
    "type": "verb",
    "infinitive": "mālle",
    "s1Form": "mālō",
    "root": "",
    "klass": "velle",
    "dutch": "liever willen",
    "explicitConjugations": {
      "active": {
        "indicative": {
          "present": {
            "s1": "mālō",
            "s2": "māvīs",
            "s3": "māvult",
            "p1": "mālumus",
            "p2": "māvultis",
            "p3": "mālunt"
          }
        }
      }
    }
  },
  "ferre": {
    "type": "verb",
    "infinitive": "ferre",
    "s1Form": "ferō",
    "root": "",
    "klass": "ferre",
    "dutch": "dragen; brengen"
  },
  "stāre": {
    "type": "verb",
    "infinitive": "stāre",
    "s1Form": "stō",
    "root": "st",
    "klass": "-āre",
    "dutch": "staan; blijven staan"
  },
  "errāre": {
    "type": "verb",
    "infinitive": "errāre",
    "s1Form": "errō",
    "root": "err",
    "klass": "-āre",
    "dutch": "zwerven; zich vergissen"
  },
  "nāvigāre": {
    "type": "verb",
    "infinitive": "nāvigāre",
    "s1Form": "nāvigō",
    "root": "nāvig",
    "klass": "-āre",
    "dutch": "varen"
  },
  "respondēre": {
    "type": "verb",
    "infinitive": "respondēre",
    "s1Form": "respondeō",
    "root": "respond",
    "klass": "-ēre",
    "dutch": "antwoorden"
  },
  "dēsinere": {
    "type": "verb",
    "infinitive": "dēsinere",
    "s1Form": "dēsinō",
    "root": "dēsin",
    "klass": "tegere",
    "dutch": "ophouden"
  },
  "exigere": {
    "type": "verb",
    "infinitive": "exigere",
    "s1Form": "exigō",
    "root": "exig",
    "klass": "tegere",
    "dutch": "uitdrijven; eisen; voltooien"
  },
  "pōnere": {
    "type": "verb",
    "infinitive": "pōnere",
    "s1Form": "pōnō",
    "root": "pōn",
    "klass": "tegere",
    "dutch": "plaatsen; neerleggen"
  },
  "solvere": {
    "type": "verb",
    "infinitive": "solvere",
    "s1Form": "solvō",
    "root": "solv",
    "klass": "tegere",
    "dutch": "losmaken; bevrijden; betalen"
  },
  "trahere": {
    "type": "verb",
    "infinitive": "trahere",
    "s1Form": "trahō",
    "root": "trah",
    "klass": "tegere",
    "dutch": "trekken; rekken"
  },
  "caedere": {
    "type": "verb",
    "infinitive": "caedere",
    "s1Form": "caedō",
    "root": "caed",
    "klass": "tegere",
    "dutch": "hakken; doden"
  },
  "iacere": {
    "type": "verb",
    "infinitive": "iacere",
    "s1Form": "iaciō",
    "root": "iac",
    "klass": "capere",
    "dutch": "werpen"
  },
  "rapere": {
    "type": "verb",
    "infinitive": "rapere",
    "s1Form": "rapiō",
    "root": "rap",
    "klass": "capere",
    "dutch": "grijpen; roven"
  },
  "labōrāre": {
    "type": "verb",
    "infinitive": "labōrāre",
    "s1Form": "labōrō",
    "root": "labōr",
    "klass": "-āre",
    "dutch": "werken; lijden"
  },
  "appārēre": {
    "type": "verb",
    "infinitive": "appārēre",
    "s1Form": "appāreō",
    "root": "appār",
    "klass": "-ēre",
    "dutch": "verschijnen; blijken"
  },
  "dare": {
    "type": "verb",
    "infinitive": "dare",
    "s1Form": "dō",
    "root": "d",
    "klass": "-āre",
    "dutch": "geven"
  },
  "narrāre": {
    "type": "verb",
    "infinitive": "narrāre",
    "s1Form": "narrō",
    "root": "narr",
    "klass": "-āre",
    "dutch": "vertellen"
  },
  "properāre": {
    "type": "verb",
    "infinitive": "properāre",
    "s1Form": "properō",
    "root": "proper",
    "klass": "-āre",
    "dutch": "zich haasten"
  },
  "spectāre": {
    "type": "verb",
    "infinitive": "spectāre",
    "s1Form": "spectō",
    "root": "spect",
    "klass": "-āre",
    "dutch": "(be)kijken; op het oog hebben"
  },
  "gaudēre": {
    "type": "verb",
    "infinitive": "gaudēre",
    "s1Form": "gaudeō",
    "root": "gaud",
    "klass": "-ēre",
    "dutch": "blij zijn"
  },
  "sedēre": {
    "type": "verb",
    "infinitive": "sedēre",
    "s1Form": "sedeō",
    "root": "sed",
    "klass": "-ēre",
    "dutch": "zitten"
  },
  "vidēre": {
    "type": "verb",
    "infinitive": "vidēre",
    "s1Form": "videō",
    "root": "vid",
    "klass": "-ēre",
    "dutch": "zien"
  },
  "aperīre": {
    "type": "verb",
    "infinitive": "aperīre",
    "s1Form": "aperiō",
    "root": "aper",
    "klass": "-īre",
    "dutch": "openen; onthullen"
  },
  "venīre": {
    "type": "verb",
    "infinitive": "venīre",
    "s1Form": "veniō",
    "root": "ven",
    "klass": "-īre",
    "dutch": "komen"
  },
  "invenīre": {
    "type": "verb",
    "infinitive": "invenīre",
    "s1Form": "inveniō",
    "root": "inven",
    "klass": "-īre",
    "dutch": "vinden; ontdekken"
  },
  "appellāre": {
    "type": "verb",
    "infinitive": "appellāre",
    "s1Form": "appellō",
    "root": "appell",
    "klass": "-āre",
    "dutch": "aanspreken; noemen"
  },
  "interrogāre": {
    "type": "verb",
    "infinitive": "interrogāre",
    "s1Form": "interrogō",
    "root": "interrog",
    "klass": "-āre",
    "dutch": "(onder)vragen"
  },
  "ōrnāre": {
    "type": "verb",
    "infinitive": "ōrnāre",
    "s1Form": "ōrnō",
    "root": "ōrn",
    "klass": "-āre",
    "dutch": "versieren; in orde brengen"
  },
  "abesse": {
    "type": "verb",
    "infinitive": "abesse",
    "s1Form": "absum",
    "root": "ab",
    "klass": "esse",
    "dutch": "afwezig zijn; verwijderd zijn"
  },
  "adesse": {
    "type": "verb",
    "infinitive": "adesse",
    "s1Form": "adsum",
    "root": "ad",
    "klass": "esse",
    "dutch": "aanwezig zijn; helpen"
  },
  "superesse": {
    "type": "verb",
    "infinitive": "superesse",
    "s1Form": "supersum",
    "root": "super",
    "klass": "esse",
    "dutch": "overblijven"
  },
  "dolēre": {
    "type": "verb",
    "infinitive": "dolēre",
    "s1Form": "doleō",
    "root": "dol",
    "klass": "-ēre",
    "dutch": "lijden; betreuren"
  },
  "exspectāre": {
    "type": "verb",
    "infinitive": "exspectāre",
    "s1Form": "exspectō",
    "root": "exspect",
    "klass": "-āre",
    "dutch": "opwachten; verwachten"
  },
  "habitāre": {
    "type": "verb",
    "infinitive": "habitāre",
    "s1Form": "habitō",
    "root": "habit",
    "klass": "-āre",
    "dutch": "wonen"
  },
  "postulāre": {
    "type": "verb",
    "infinitive": "postulāre",
    "s1Form": "postulō",
    "root": "postul",
    "klass": "-āre",
    "dutch": "eisen"
  },
  "servāre": {
    "type": "verb",
    "infinitive": "servāre",
    "s1Form": "servō",
    "root": "serv",
    "klass": "-āre",
    "dutch": "bewaren; redden"
  },
  "vocāre": {
    "type": "verb",
    "infinitive": "vocāre",
    "s1Form": "vocō",
    "root": "voc",
    "klass": "-āre",
    "dutch": "roepen; noemen"
  },
  "manēre": {
    "type": "verb",
    "infinitive": "manēre",
    "s1Form": "maneō",
    "root": "man",
    "klass": "-ēre",
    "dutch": "blijven"
  },
  "possidēre": {
    "type": "verb",
    "infinitive": "possidēre",
    "s1Form": "possideō",
    "root": "possid",
    "klass": "-ēre",
    "dutch": "bezitten; beheersen"
  },
  "rīdēre": {
    "type": "verb",
    "infinitive": "rīdēre",
    "s1Form": "rīdeō",
    "root": "rid",
    "klass": "-ēre",
    "dutch": "(uit)lachen"
  },
  "reperīre": {
    "type": "verb",
    "infinitive": "reperīre",
    "s1Form": "reperiō",
    "root": "reper",
    "klass": "-īre",
    "dutch": "vinden; te weten komen"
  },
  "scīre": {
    "type": "verb",
    "infinitive": "scīre",
    "s1Form": "sciō",
    "root": "sc",
    "klass": "-īre",
    "dutch": "weten"
  },
  "nescīre": {
    "type": "verb",
    "infinitive": "nescīre",
    "s1Form": "nesciō",
    "root": "nesc",
    "klass": "-īre",
    "dutch": "niet weten"
  },
  "vigilāre": {
    "type": "verb",
    "infinitive": "vigilāre",
    "s1Form": "vigilō",
    "root": "vigil",
    "klass": "-āre",
    "dutch": "waken"
  },
  "terrēre": {
    "type": "verb",
    "infinitive": "terrēre",
    "s1Form": "terreō",
    "root": "terr",
    "klass": "-ēre",
    "dutch": "bang maken"
  },
  "timēre": {
    "type": "verb",
    "infinitive": "timēre",
    "s1Form": "timeō",
    "root": "tim",
    "klass": "-ēre",
    "dutch": "vrezen; bang zijn"
  },
  "adiuvāre": {
    "type": "verb",
    "infinitive": "adiuvāre",
    "s1Form": "adiuvō",
    "root": "adiuv",
    "klass": "-āre",
    "dutch": "helpen"
  },
  "cūrāre": {
    "type": "verb",
    "infinitive": "cūrāre",
    "s1Form": "cūrō",
    "root": "cūr",
    "klass": "-āre",
    "dutch": "zorgen voor"
  },
  "mōnstrāre": {
    "type": "verb",
    "infinitive": "mōnstrāre",
    "s1Form": "mōnstrō",
    "root": "mōnstr",
    "klass": "-āre",
    "dutch": "wijzen"
  },
  "accipere": {
    "type": "verb",
    "infinitive": "accipere",
    "s1Form": "accipiō",
    "root": "accip",
    "klass": "capere",
    "dutch": "ontvangen; vernemen"
  },
  "incipere": {
    "type": "verb",
    "infinitive": "incipere",
    "s1Form": "incipiō",
    "root": "incip",
    "klass": "capere",
    "dutch": "beginnen"
  },
  "cupere": {
    "type": "verb",
    "infinitive": "cupere",
    "s1Form": "cupiō",
    "root": "cup",
    "klass": "capere",
    "dutch": "verlangen; (graag) willen"
  },
  "interficere": {
    "type": "verb",
    "infinitive": "interficere",
    "s1Form": "interficiō",
    "root": "interfic",
    "klass": "capere",
    "dutch": "doden"
  },
  "fugere": {
    "type": "verb",
    "infinitive": "fugere",
    "s1Form": "fugiō",
    "root": "fug",
    "klass": "capere",
    "dutch": "(ont)vluchten"
  },
  "abicere": {
    "type": "verb",
    "infinitive": "abicere",
    "s1Form": "abiciō",
    "root": "abic",
    "klass": "capere",
    "dutch": "wegwerpen; neerwerpen"
  },
  "corripere": {
    "type": "verb",
    "infinitive": "corripere",
    "s1Form": "corripiō",
    "root": "corrip",
    "klass": "capere",
    "dutch": "vastgrijpen; meesleuren"
  },
  "ēripere": {
    "type": "verb",
    "infinitive": "ēripere",
    "s1Form": "ērip",
    "root": "ēripiō",
    "klass": "capere",
    "dutch": "wegrukken"
  },
  "-spicere": {
    "type": "verb",
    "infinitive": "-spicere",
    "s1Form": "-spiciō",
    "root": "-spic",
    "klass": "capere",
    "dutch": "kijken"
  },
  "adspicere": {
    "type": "verb",
    "infinitive": "adspicere",
    "s1Form": "adspiciō",
    "root": "adspic",
    "klass": "capere",
    "dutch": "aankijken"
  },
  "cōnspicere": {
    "type": "verb",
    "infinitive": "cōnspicere",
    "s1Form": "cōnspiciō",
    "root": "cōnspic",
    "klass": "capere",
    "dutch": "bekijken; bemerken"
  },
  "respicere": {
    "type": "verb",
    "infinitive": "respicere",
    "s1Form": "respiciō",
    "root": "respic",
    "klass": "capere",
    "dutch": "omkijken; rekening houden met"
  },
  "fīerī": {
    "type": "verb",
    "infinitive": "fīerī",
    "s1Form": "fīō",
    "root": "",
    "klass": "fīerī",
    "dutch": "worden; gebeuren; gemaakt worden"
  },
  "abīre": {
    "type": "verb",
    "infinitive": "abīre",
    "s1Form": "abeō",
    "root": "ab",
    "klass": "īre",
    "dutch": "weggaan"
  },
  "adīre": {
    "type": "verb",
    "infinitive": "adīre",
    "s1Form": "adeō",
    "root": "ad",
    "klass": "īre",
    "dutch": "gaan naar; bezoeken"
  },
  "exīre": {
    "type": "verb",
    "infinitive": "exīre",
    "s1Form": "exeō",
    "root": "ex",
    "klass": "īre",
    "dutch": "buitengaan; eindigen"
  },
  "inīre": {
    "type": "verb",
    "infinitive": "inīre",
    "s1Form": "ineō",
    "root": "in",
    "klass": "īre",
    "dutch": "binnengaan; beginnen"
  },
  "praeterīre": {
    "type": "verb",
    "infinitive": "praeterīre",
    "s1Form": "praetereō",
    "root": "praeter",
    "klass": "īre",
    "dutch": "voorbijgaan"
  },
  "redīre": {
    "type": "verb",
    "infinitive": "redīre",
    "s1Form": "redeō",
    "root": "red",
    "klass": "īre",
    "dutch": "terugkeren"
  },
  "trānsīre": {
    "type": "verb",
    "infinitive": "trānsīre",
    "s1Form": "trānseō",
    "root": "trāns",
    "klass": "īre",
    "dutch": "oversteken"
  },
  "necāre": {
    "type": "verb",
    "infinitive": "necāre",
    "s1Form": "necō",
    "root": "nec",
    "klass": "-āre",
    "dutch": "vermoorden"
  },
  "pugnāre": {
    "type": "verb",
    "infinitive": "pugnāre",
    "s1Form": "pugnō",
    "root": "pugn",
    "klass": "-āre",
    "dutch": "vechten"
  },
  "tacēre": {
    "type": "verb",
    "infinitive": "tacēre",
    "s1Form": "taceō",
    "root": "tac",
    "klass": "-ēre",
    "dutch": "zwijgen"
  },
  "tenēre": {
    "type": "verb",
    "infinitive": "tenēre",
    "s1Form": "teneō",
    "root": "ten",
    "klass": "-ēre",
    "dutch": "(vast)houden"
  },
  "excitāre": {
    "type": "verb",
    "infinitive": "excitāre",
    "s1Form": "excitō",
    "root": "excit",
    "klass": "-āre",
    "dutch": "(op)wekken"
  },
  "imperāre": {
    "type": "verb",
    "infinitive": "imperāre",
    "s1Form": "imperō",
    "root": "imper",
    "klass": "-āre",
    "dutch": "bevelen; opeisen"
  },
  "mandāre": {
    "type": "verb",
    "infinitive": "mandāre",
    "s1Form": "mandō",
    "root": "mand",
    "klass": "-āre",
    "dutch": "toevertrouwen; opdragen"
  },
  "solēre": {
    "type": "verb",
    "infinitive": "solēre",
    "s1Form": "soleō",
    "root": "sol",
    "klass": "-ēre",
    "dutch": "gewoon zijn"
  },
  "vincīre": {
    "type": "verb",
    "infinitive": "vincīre",
    "s1Form": "vinciō",
    "root": "vinc",
    "klass": "-īre",
    "dutch": "binden; boeien"
  },
  "laudāre": {
    "type": "verb",
    "infinitive": "laudāre",
    "s1Form": "laudō",
    "root": "laud",
    "klass": "-āre",
    "dutch": "prijzen"
  },
  "nūntiāre": {
    "type": "verb",
    "infinitive": "nūntiāre",
    "s1Form": "nūntiō",
    "root": "nūnti",
    "klass": "-āre",
    "dutch": "melden"
  },
  "optāre": {
    "type": "verb",
    "infinitive": "optāre",
    "s1Form": "optō",
    "root": "opt",
    "klass": "-āre",
    "dutch": "wensen"
  },
  "pārēre": {
    "type": "verb",
    "infinitive": "pārēre",
    "s1Form": "pāreō",
    "root": "pār",
    "klass": "-ēre",
    "dutch": "gehoorzamen"
  },
  "praebēre": {
    "type": "verb",
    "infinitive": "praebēre",
    "s1Form": "praebeō",
    "root": "praeb",
    "klass": "-ēre",
    "dutch": "aanbieden"
  },
  "temptāre": {
    "type": "verb",
    "infinitive": "temptāre",
    "s1Form": "temptō",
    "root": "tempt",
    "klass": "-āre",
    "dutch": "proberen; op de proef stellen"
  },
  "ārdēre": {
    "type": "verb",
    "infinitive": "ārdēre",
    "s1Form": "ārdeō",
    "root": "ārd",
    "klass": "-ēre",
    "dutch": "branden"
  },
  "placēre": {
    "type": "verb",
    "infinitive": "placēre",
    "s1Form": "placeō",
    "root": "plac",
    "klass": "-ēre",
    "dutch": "bevallen; aanstaan"
  },
  "ōrāre": {
    "type": "verb",
    "infinitive": "ōrāre",
    "s1Form": "ōrō",
    "root": "ōr",
    "klass": "-āre",
    "dutch": "bidden; smeken"
  },
  "maerēre": {
    "type": "verb",
    "infinitive": "maerēre",
    "s1Form": "maereō",
    "root": "maer",
    "klass": "-ēre",
    "dutch": "treuren"
  },
  "invītāre": {
    "type": "verb",
    "infinitive": "invītāre",
    "s1Form": "invītō",
    "root": "invīt",
    "klass": "-āre",
    "dutch": "uitnodigen"
  },
  "haerēre": {
    "type": "verb",
    "infinitive": "haerēre",
    "s1Form": "haereō",
    "root": "haer",
    "klass": "-ēre",
    "dutch": "vastzitten"
  },
  "agere": {
    "type": "verb",
    "infinitive": "agere",
    "s1Form": "agō",
    "root": "ag",
    "klass": "tegere",
    "dutch": "(voort)drijven; doen"
  },
  "bibere": {
    "type": "verb",
    "infinitive": "bibere",
    "s1Form": "bibō",
    "root": "bib",
    "klass": "tegere",
    "dutch": "drinken"
  },
  "cognōscere": {
    "type": "verb",
    "infinitive": "cognōscere",
    "s1Form": "cognōscō",
    "root": "cognōsc",
    "klass": "tegere",
    "dutch": "leren kennen; vernemen"
  },
  "emere": {
    "type": "verb",
    "infinitive": "emere",
    "s1Form": "emō",
    "root": "em",
    "klass": "tegere",
    "dutch": "kopen"
  },
  "intellegere": {
    "type": "verb",
    "infinitive": "intellegere",
    "s1Form": "intellegō",
    "root": "intelleg",
    "klass": "tegere",
    "dutch": "begrijpen"
  },
  "legere": {
    "type": "verb",
    "infinitive": "legere",
    "s1Form": "legō",
    "root": "leg",
    "klass": "tegere",
    "dutch": "leren; kiezen; verzamelen"
  },
  "ostendere": {
    "type": "verb",
    "infinitive": "ostendere",
    "s1Form": "ostendō",
    "root": "ostend",
    "klass": "tegere",
    "dutch": "tonen"
  },
  "quaerere": {
    "type": "verb",
    "infinitive": "quaerere",
    "s1Form": "quaerō",
    "root": "quaer",
    "klass": "tegere",
    "dutch": "zoeken; vragen"
  },
  "sūmere": {
    "type": "verb",
    "infinitive": "sūmere",
    "s1Form": "sūmō",
    "root": "sūm",
    "klass": "tegere",
    "dutch": "nemen"
  },
  "vendere": {
    "type": "verb",
    "infinitive": "vendere",
    "s1Form": "vendō",
    "root": "vend",
    "klass": "tegere",
    "dutch": "verkopen"
  },
  "vīvere": {
    "type": "verb",
    "infinitive": "vīvere",
    "s1Form": "vīvō",
    "root": "vīv",
    "klass": "tegere",
    "dutch": "leven"
  },
  "docēre": {
    "type": "verb",
    "infinitive": "docēre",
    "s1Form": "doceō",
    "root": "doc",
    "klass": "-ēre",
    "dutch": "onderwijzen"
  },
  "metuere": {
    "type": "verb",
    "infinitive": "metuere",
    "s1Form": "metuō",
    "root": "metu",
    "klass": "tegere",
    "dutch": "vrezen"
  },
  "cōnsīdere": {
    "type": "verb",
    "infinitive": "cōnsīdere",
    "s1Form": "cōnsīdō",
    "root": "cōnsīd",
    "klass": "tegere",
    "dutch": "gaan zitten"
  },
  "dēpōnere": {
    "type": "verb",
    "infinitive": "dēpōnere",
    "s1Form": "dēpōnō",
    "root": "dēpōn",
    "klass": "tegere",
    "dutch": "neerleggen"
  },
  "discere": {
    "type": "verb",
    "infinitive": "discere",
    "s1Form": "discō",
    "root": "disc",
    "klass": "tegere",
    "dutch": "studeren"
  },
  "lūdere": {
    "type": "verb",
    "infinitive": "lūdere",
    "s1Form": "lūdō",
    "root": "lūd",
    "klass": "tegere",
    "dutch": "bespelen; bespotten"
  },
  "mittere": {
    "type": "verb",
    "infinitive": "mittere",
    "s1Form": "mittō",
    "root": "mitt",
    "klass": "tegere",
    "dutch": "zenden; laten gaan"
  },
  "relinquere": {
    "type": "verb",
    "infinitive": "relinquere",
    "s1Form": "relinquō",
    "root": "relinqu",
    "klass": "tegere",
    "dutch": "achterlaten; verlaten"
  },
  "vertere": {
    "type": "verb",
    "infinitive": "vertere",
    "s1Form": "vertō",
    "root": "vert",
    "klass": "tegere",
    "dutch": "omkeren; veranderen in"
  },
  "vincere": {
    "type": "verb",
    "infinitive": "vincere",
    "s1Form": "vincō",
    "root": "vinc",
    "klass": "tegere",
    "dutch": "overwinnen; overtreffen"
  },
  "vīsere": {
    "type": "verb",
    "infinitive": "vīsere",
    "s1Form": "vīsō",
    "root": "vīs",
    "klass": "tegere",
    "dutch": "bezoeken"
  },
  "recipere": {
    "type": "verb",
    "infinitive": "recipere",
    "s1Form": "recipiō",
    "root": "recip",
    "klass": "capere",
    "dutch": "ontvangen"
  },
  "iubēre": {
    "type": "verb",
    "infinitive": "iubēre",
    "s1Form": "iubeō",
    "root": "iub",
    "klass": "-ēre",
    "dutch": "bevelen"
  },
  "surgere": {
    "type": "verb",
    "infinitive": "surgere",
    "s1Form": "surgō",
    "root": "surg",
    "klass": "tegere",
    "dutch": "opstaan"
  },
  "sentīre": {
    "type": "verb",
    "infinitive": "sentīre",
    "s1Form": "sentiō",
    "root": "sent",
    "klass": "-īre",
    "dutch": "voelen; merken; menen"
  },
  "cadere": {
    "type": "verb",
    "infinitive": "cadere",
    "s1Form": "cadō",
    "root": "cad",
    "klass": "tegere",
    "dutch": "vallen"
  },
  "currere": {
    "type": "verb",
    "infinitive": "currere",
    "s1Form": "currō",
    "root": "curr",
    "klass": "tegere",
    "dutch": "lopen"
  },
  "petere": {
    "type": "verb",
    "infinitive": "petere",
    "s1Form": "petō",
    "root": "pet",
    "klass": "tegere",
    "dutch": "gaan naar; vragen"
  },
  "poscere": {
    "type": "verb",
    "infinitive": "poscere",
    "s1Form": "poscō",
    "root": "posc",
    "klass": "tegere",
    "dutch": "eisen"
  },
  "flēre": {
    "type": "verb",
    "infinitive": "flēre",
    "s1Form": "fleō",
    "root": "fl",
    "klass": "-ēre",
    "dutch": "wenen; bewenen"
  },
  "canere": {
    "type": "verb",
    "infinitive": "canere",
    "s1Form": "canō",
    "root": "can",
    "klass": "tegere",
    "dutch": "zingen; bezingen"
  },
  "fingere": {
    "type": "verb",
    "infinitive": "fingere",
    "s1Form": "fingō",
    "root": "fing",
    "klass": "tegere",
    "dutch": "vormen; verzinnen"
  },
  "fluere": {
    "type": "verb",
    "infinitive": "fluere",
    "s1Form": "fluō",
    "root": "flu",
    "klass": "tegere",
    "dutch": "vloeien"
  },
  "līberāre": {
    "type": "verb",
    "infinitive": "līberāre",
    "s1Form": "līberō",
    "root": "līber",
    "klass": "-āre",
    "dutch": "bevrijden"
  },
  "volāre": {
    "type": "verb",
    "infinitive": "volāre",
    "s1Form": "volō",
    "root": "vol",
    "klass": "-āre",
    "dutch": "vliegen"
  },
  "dēscendere": {
    "type": "verb",
    "infinitive": "dēscendere",
    "s1Form": "dēscendō",
    "root": "dēscend",
    "klass": "tegere",
    "dutch": "afdalen"
  },
  "haurīre": {
    "type": "verb",
    "infinitive": "haurīre",
    "s1Form": "hauriō",
    "root": "haur",
    "klass": "-īre",
    "dutch": "uitscheppen; opslokken"
  },
  "dēicere": {
    "type": "verb",
    "infinitive": "dēicere",
    "s1Form": "dēiciō",
    "root": "dēic",
    "klass": "capere",
    "dutch": "naar beneden werpen; verdrijven"
  },
  "incitāre": {
    "type": "verb",
    "infinitive": "incitāre",
    "s1Form": "incitō",
    "root": "incit",
    "klass": "-āre",
    "dutch": "aanvuren"
  },
  "complēre": {
    "type": "verb",
    "infinitive": "complēre",
    "s1Form": "compleō",
    "root": "compl",
    "klass": "-ēre",
    "dutch": "vullen"
  },
  "dubitāre": {
    "type": "verb",
    "infinitive": "dubitāre",
    "s1Form": "dubitō",
    "root": "dubit",
    "klass": "-āre",
    "dutch": "twijfelen; aarzelen"
  },
  "impōnere": {
    "type": "verb",
    "infinitive": "impōnere",
    "s1Form": "impōnō",
    "root": "impōn",
    "klass": "tegere",
    "dutch": "plaatsen op; opleggen"
  },
  "afferre": {
    "type": "verb",
    "infinitive": "afferre",
    "s1Form": "afferō",
    "root": "af",
    "klass": "ferre",
    "dutch": "brengen"
  },
  "auferre": {
    "type": "verb",
    "infinitive": "auferre",
    "s1Form": "auferō",
    "root": "au",
    "klass": "ferre",
    "dutch": "wegnemen; wegbrengen"
  },
  "offerre": {
    "type": "verb",
    "infinitive": "offerre",
    "s1Form": "offerō",
    "root": "of",
    "klass": "ferre",
    "dutch": "aanbieden"
  },
  "referre": {
    "type": "verb",
    "infinitive": "referre",
    "s1Form": "referō",
    "root": "re",
    "klass": "ferre",
    "dutch": "terugbrengen; rapporteren"
  },
  "trānsferre": {
    "type": "verb",
    "infinitive": "trānsferre",
    "s1Form": "trānsferō",
    "root": "trāns",
    "klass": "ferre",
    "dutch": "overbrengen"
  },
  "expugnāre": {
    "type": "verb",
    "infinitive": "expugnāre",
    "s1Form": "expugnō",
    "root": "expugn",
    "klass": "-āre",
    "dutch": "veroveren"
  },
  "cēdere": {
    "type": "verb",
    "infinitive": "cēdere",
    "s1Form": "cēdō",
    "root": "cēd",
    "klass": "tegere",
    "dutch": "gaan; wijken"
  },
  "discēdere": {
    "type": "verb",
    "infinitive": "discēdere",
    "s1Form": "discēdō",
    "root": "discēd",
    "klass": "tegere",
    "dutch": "uiteengaan; weggaan"
  },
  "colere": {
    "type": "verb",
    "infinitive": "colere",
    "s1Form": "colō",
    "root": "col",
    "klass": "tegere",
    "dutch": "bewerken; bewonen; vereren"
  },
  "custōdīre": {
    "type": "verb",
    "infinitive": "custōdīre",
    "s1Form": "custōdiō",
    "root": "custōd",
    "klass": "-īre",
    "dutch": "bewaken"
  },
  "movēre": {
    "type": "verb",
    "infinitive": "movēre",
    "s1Form": "moveō",
    "root": "mov",
    "klass": "-ēre",
    "dutch": "bewegen; beïnvloeden"
  },
  "accēdere": {
    "type": "verb",
    "infinitive": "accēdere",
    "s1Form": "accēdō",
    "root": "accēd",
    "klass": "tegere",
    "dutch": "naderen"
  },
  "comprehendere": {
    "type": "verb",
    "infinitive": "comprehendere",
    "s1Form": "comprehendō",
    "root": "comprehend",
    "klass": "tegere",
    "dutch": "grijpen"
  },
  "dēdūcere": {
    "type": "verb",
    "infinitive": "dēdūcere",
    "s1Form": "dēdūcō",
    "root": "dēdūc",
    "klass": "tegere",
    "dutch": "naar beneden brengen; wegbrengen"
  },
  "dīmittere": {
    "type": "verb",
    "infinitive": "dīmittere",
    "s1Form": "dīmittō",
    "root": "dīmitt",
    "klass": "tegere",
    "dutch": "wegzenden; laten gaan"
  },
  "cōgere": {
    "type": "verb",
    "infinitive": "cōgere",
    "s1Form": "cōgō",
    "root": "cōg",
    "klass": "tegere",
    "dutch": "bijeenbrengen; dwingen"
  },
  "frangere": {
    "type": "verb",
    "infinitive": "frangere",
    "s1Form": "frangō",
    "root": "frang",
    "klass": "tegere",
    "dutch": "breken"
  },
  "reddere": {
    "type": "verb",
    "infinitive": "reddere",
    "s1Form": "reddō",
    "root": "redd",
    "klass": "tegere",
    "dutch": "teruggeven; maken (tot)"
  },
  "excipere": {
    "type": "verb",
    "infinitive": "excipere",
    "s1Form": "excipiō",
    "root": "excip",
    "klass": "capere",
    "dutch": "uitnemen; opvangen"
  },
  "vulnerāre": {
    "type": "verb",
    "infinitive": "vulnerāre",
    "s1Form": "vulnerō",
    "root": "vulner",
    "klass": "-āre",
    "dutch": "verwonden"
  },
  "gerere": {
    "type": "verb",
    "infinitive": "gerere",
    "s1Form": "gerō",
    "root": "ger",
    "klass": "tegere",
    "dutch": "dragen; voeren"
  },
  "induere": {
    "type": "verb",
    "infinitive": "induere",
    "s1Form": "induō",
    "root": "indu",
    "klass": "tegere",
    "dutch": "aantrekken; bekleden met"
  },
  "appropinquāre": {
    "type": "verb",
    "infinitive": "appropinquāre",
    "s1Form": "appropinquō",
    "root": "appropinqu",
    "klass": "-āre",
    "dutch": "naderen (+ dat)"
  },
  "dēspērāre": {
    "type": "verb",
    "infinitive": "dēspērāre",
    "s1Form": "dēspērō",
    "root": "dēspēr",
    "klass": "-āre",
    "dutch": "wanhopen"
  },
  "spērāre": {
    "type": "verb",
    "infinitive": "spērāre",
    "s1Form": "spērō",
    "root": "spēr",
    "klass": "-āre",
    "dutch": "hopen op"
  },
  "obsidēre": {
    "type": "verb",
    "infinitive": "obsidēre",
    "s1Form": "obsideō",
    "root": "obsid",
    "klass": "-ēre",
    "dutch": "belegeren; bezet houden"
  },
  "prōmittere": {
    "type": "verb",
    "infinitive": "prōmittere",
    "s1Form": "prōmittō",
    "root": "prōmitt",
    "klass": "tegere",
    "dutch": "beloven"
  },
  "scrībere": {
    "type": "verb",
    "infinitive": "scrībere",
    "s1Form": "scrībō",
    "root": "scrīb",
    "klass": "tegere",
    "dutch": "schrijven"
  },
  "vexāre": {
    "type": "verb",
    "infinitive": "vexāre",
    "s1Form": "vexō",
    "root": "vex",
    "klass": "-āre",
    "dutch": "kwellen"
  },
  "exercēre": {
    "type": "verb",
    "infinitive": "exercēre",
    "s1Form": "exerceō",
    "root": "exerc",
    "klass": "-ēre",
    "dutch": "oefenen"
  },
  "certāre": {
    "type": "verb",
    "infinitive": "certāre",
    "s1Form": "certō",
    "root": "cert",
    "klass": "-āre",
    "dutch": "strijden"
  },
  "pervenīre": {
    "type": "verb",
    "infinitive": "pervenīre",
    "s1Form": "perveniō",
    "root": "perven",
    "klass": "-īre",
    "dutch": "aankomen"
  },
  "alere": {
    "type": "verb",
    "infinitive": "alere",
    "s1Form": "alō",
    "root": "al",
    "klass": "tegere",
    "dutch": "voeden"
  },
  "committere": {
    "type": "verb",
    "infinitive": "committere",
    "s1Form": "committō",
    "root": "committ",
    "klass": "tegere",
    "dutch": "begaan; toevertrouwen; beginnen"
  },
  "fallere": {
    "type": "verb",
    "infinitive": "fallere",
    "s1Form": "fallō",
    "root": "fall",
    "klass": "tegere",
    "dutch": "misleiden; ontgaan"
  },
  "pellere": {
    "type": "verb",
    "infinitive": "pellere",
    "s1Form": "pellō",
    "root": "pell",
    "klass": "tegere",
    "dutch": "verdrijven"
  },
  "statuere": {
    "type": "verb",
    "infinitive": "statuere",
    "s1Form": "statuō",
    "root": "statu",
    "klass": "tegere",
    "dutch": "plaatsen; vaststellen; beslissen"
  },
  "trādere": {
    "type": "verb",
    "infinitive": "trādere",
    "s1Form": "trādō",
    "root": "trād",
    "klass": "tegere",
    "dutch": "overhandigen; toevertrouwen"
  },
  "suādēre": {
    "type": "verb",
    "infinitive": "suādēre",
    "s1Form": "suādeō",
    "root": "suād",
    "klass": "-ēre",
    "dutch": "aanraden"
  },
  "carpere": {
    "type": "verb",
    "infinitive": "carpere",
    "s1Form": "carpō",
    "root": "carp",
    "klass": "tegere",
    "dutch": "plukken"
  },
  "claudere": {
    "type": "verb",
    "infinitive": "claudere",
    "s1Form": "claudō",
    "root": "claud",
    "klass": "tegere",
    "dutch": "sluiten"
  },
  "crēscere": {
    "type": "verb",
    "infinitive": "crēscere",
    "s1Form": "crēscō",
    "root": "crēsc",
    "klass": "tegere",
    "dutch": "groeien"
  },
  "occīdere": {
    "type": "verb",
    "infinitive": "occīdere",
    "s1Form": "occīdō",
    "root": "occīd",
    "klass": "tegere",
    "dutch": "doden"
  },
  "prōcēdere": {
    "type": "verb",
    "infinitive": "prōcēdere",
    "s1Form": "prōcēdō",
    "root": "prōcēd",
    "klass": "tegere",
    "dutch": "vooruitgaan"
  },
  "tangere": {
    "type": "verb",
    "infinitive": "tangere",
    "s1Form": "tangō",
    "root": "tang",
    "klass": "tegere",
    "dutch": "aanraken; bereiken; treffen"
  },
  "prōdesse": {
    "type": "verb",
    "infinitive": "prōdesse",
    "s1Form": "prōsum",
    "root": "prōd",
    "klass": "esse",
    "explicitConjugations": {
      "active": {
        "indicative": {
          "present": {
            "s1": "prōsum",
            "p1": "prōsumus",
            "p3": "prōsunt"
          }
        }
      }
    },
    "dutch": "nuttig zijn (+ dat.)"
  },
  "perīre": {
    "type": "verb",
    "infinitive": "perīre",
    "s1Form": "pereō",
    "root": "per",
    "klass": "īre",
    "dutch": "ten onder gaan"
  },
  "iuvāre": {
    "type": "verb",
    "infinitive": "iuvāre",
    "s1Form": "iuvō",
    "root": "iuv",
    "klass": "-āre",
    "dutch": "helpen"
  },
  "vetāre": {
    "type": "verb",
    "infinitive": "vetāre",
    "s1Form": "vetō",
    "root": "vet",
    "klass": "-āre",
    "dutch": "verbieden"
  },
  "abdere": {
    "type": "verb",
    "infinitive": "abdere",
    "s1Form": "abdō",
    "root": "abd",
    "klass": "tegere",
    "dutch": "verbergen"
  },
  "tendere": {
    "type": "verb",
    "infinitive": "tendere",
    "s1Form": "tendō",
    "root": "tend",
    "klass": "tegere",
    "dutch": "spannen; streven; gaan"
  },
  "dēfendere": {
    "type": "verb",
    "infinitive": "dēfendere",
    "s1Form": "dēfendō",
    "root": "dēfend",
    "klass": "tegere",
    "dutch": "afweren; verdedigen"
  },
  "iactāre": {
    "type": "verb",
    "infinitive": "iactāre",
    "s1Form": "iactō",
    "root": "iact",
    "klass": "-āre",
    "dutch": "slingeren",
  },
  "incendere": {
    "type": "verb",
    "infinitive": "incendere",
    "s1Form": "incendō",
    "root": "incend",
    "klass": "tegere",
    "dutch": "in brand steken",
  },
  "ruere": {
    "type": "verb",
    "infinitive": "ruere",
    "s1Form": "ruō",
    "root": "ru",
    "klass": "tegere",
    "dutch": "vooruitstormen; instorten",
  },
  "compōnere": {
    "type": "verb",
    "infinitive": "compōnere",
    "s1Form": "compōnō",
    "root": "compōn",
    "klass": "tegere",
    "dutch": "samenplaatsen; opstellen",
  },
  "convenīre": {
    "type": "verb",
    "infinitive": "convenīre",
    "s1Form": "conveniō",
    "root": "conven",
    "klass": "-īre",
    "dutch": "samenkomen; overeenkomen",
  },
  "prōvidēre": {
    "type": "verb",
    "infinitive": "prōvidēre",
    "s1Form": "prōvideō",
    "root": "prōvid",
    "klass": "-ēre",
    "dutch": "voorzien; zorgen voor",
  },
  "damnāre": {
    "type": "verb",
    "infinitive": "damnāre",
    "s1Form": "damnō",
    "root": "damn",
    "klass": "-āre",
    "dutch": "veroordelen",
  },
  "dēlēre": {
    type: "verb",
    infinitive: "dēlēre",
    s1Form: "dēleō",
    root: "del",
    klass: "-ēre",
    dutch: "vernielen",
  },
  "dēserere": {
    type: "verb",
    infinitive: "dēserere",
    s1Form: "dēserō",
    root: "dēser",
    klass: "tegere",
    dutch: "verlaten",
  },
  "repetere": {
    type: "verb",
    infinitive: "repetere",
    s1Form: "repetō",
    root: "repet",
    klass: "tegere",
    dutch: "teruggaan naar; terugvragen; herhalen",
  },
  "tollere": {
    type: "verb",
    infinitive: "tollere",
    s1Form: "tollō",
    root: "toll",
    klass: "tegere",
    dutch: "opheffen; wegnemen",
  },
  "crēdere": {
    type: "verb",
    infinitive: "crēdere",
    s1Form: "crēdō",
    root: "crēd",
    klass: "tegere",
    dutch: "geloven; vertrouwen; toevertrouwen (+ dat.)",
  },
  "addūcere": {
    type: "verb",
    infinitive: "addūcere",
    s1Form: "dūcō",
    root: "dūc",
    klass: "tegere",
    dutch: "brengen naar",
  },
  "āmittere": {
    type: "verb",
    infinitive: "āmittere",
    s1Form: "mittō",
    root: "mitt",
    klass: "tegere",
    dutch: "verliezen",
  },
  "dēligere": {
    type: "verb",
    infinitive: "dēligere",
    s1Form: "dēligō",
    root: "dēlig",
    klass: "tegere",
    dutch: "uitkiezen",
  },
  "condere": {
    type: "verb",
    infinitive: "condere",
    s1Form: "condō",
    root: "cond",
    klass: "tegere",
    dutch: "stichten; opbergen",
  },
  "mūtāre": {
    type: "verb",
    infinitive: "mūtāre",
    s1Form: "mūtō",
    root: "mūt",
    klass: "-āre",
    dutch: "veranderen; verwisselen",
  },
  "licēre": {
    type: "verb",
    infinitive: "licēre",
    s1Form: "liceō",
    root: "lic",
    klass: "-ēre",
    dutch: "het is toegelaten",
  },
  "perficere": {
    type: "verb",
    infinitive: "perficere",
    s1Form: "perficiō",
    root: "perfic",
    klass: "tegere",
    dutch: "voltooien",
  },
  // "": {
  //   type: "verb",
  //   infinitive: "",
  //   s1Form: "",
  //   root: "",
  //   klass: "",
  //   dutch: "",
  // },
};

export function searchLatinVerb(infinitive: keyof typeof LATIN_VERBS): LatinVerb {
  if (!(infinitive in LATIN_VERBS)) {
    throw new Error(`No Latin verb found for infinitive: ${infinitive}`);
  }
  return LATIN_VERBS[infinitive];
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_CONJUGATED_VERBS: Array<LatinConjugatedVerb> = [
  {
    type: "conjugatedVerb",
    latin: "inquit",
    dutch: "zegt hij; zegt zij",
  },
  {
    type: "conjugatedVerb",
    latin: "accidere; accidit",
    dutch: "het gebeurt",
  },
];

export function searchLatinConjugatedVerb(latin: string): LatinConjugatedVerb {
  const results = LATIN_CONJUGATED_VERBS.filter((verb) => verb.latin === latin);
  if (results.length !== 1) {
    console.error(results);
    throw new Error(`No single Latin conjugated verb found for root: ${latin}`);
  }
  return results[0];
}