import { LatinAdjective, LatinPluralAdjective } from "../LatinWord";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_ADJECTIVES: { [key: string]: LatinAdjective } = {
// export const LATIN_ADJECTIVES = {
  "bon": {
    "type": "adjective",
    "latin": "bonus",
    "latinNS": [
      "bonus",
      "bona",
      "bonum"
    ],
    "root": "bon",
    "dutch": "goed",
    "klass": 1
  },
  "Graec": {
    "type": "adjective",
    "latin": "Graecus",
    "latinNS": [
      "Graecus",
      "Graeca",
      "Graecum"
    ],
    "root": "Graec",
    "dutch": "Grieks",
    "klass": 1
  },
  "īrāt": {
    "type": "adjective",
    "latin": "īrātus + dat.",
    "latinNS": [
      "īrātus",
      "īrāta",
      "īrātum"
    ],
    "root": "īrāt",
    "dutch": "woedend",
    "klass": 1
  },
  "long": {
    "type": "adjective",
    "latin": "longus",
    "latinNS": [
      "longus",
      "longa",
      "longum"
    ],
    "root": "long",
    "dutch": "lang",
    "klass": 1
  },
  "magn": {
    "type": "adjective",
    "latin": "magnus",
    "latinNS": [
      "magnus",
      "magna",
      "magnum"
    ],
    "root": "magn",
    "dutch": "groot; belangrijk",
    "klass": 1
  },
  "medi": {
    "type": "adjective",
    "latin": "medius",
    "latinNS": [
      "medius",
      "media",
      "medium"
    ],
    "root": "medi",
    "dutch": "middelste; het midden van",
    "klass": 1
  },
  "nov": {
    "type": "adjective",
    "latin": "novus",
    "latinNS": [
      "novus",
      "nova",
      "novum"
    ],
    "root": "nov",
    "dutch": "nieuw",
    "klass": 1
  },
  "prīm": {
    "type": "adjective",
    "latin": "prīmus",
    "latinNS": [
      "prīmus",
      "prīma",
      "prīmum"
    ],
    "root": "prīm",
    "dutch": "eerste; de eerste van",
    "klass": 1
  },
  "Rōmān": {
    "type": "adjective",
    "latin": "Rōmānus",
    "latinNS": [
      "Rōmānus",
      "Rōmāna",
      "Rōmānum"
    ],
    "root": "Rōmān",
    "dutch": "Romeins",
    "klass": 1
  },
  "sōl": {
    "type": "adjective",
    "latin": "sōlus",
    "latinNS": [
      "sōlus",
      "sōla",
      "sōlum"
    ],
    "root": "sōl",
    "dutch": "alleen; eenzaam",
    "klass": 1
  },
  "tōt": {
    "type": "adjective",
    "latin": "tōtus",
    "latinNS": [
      "tōtus",
      "tōta",
      "tōtum"
    ],
    "root": "tōt",
    "dutch": "geheel",
    "klass": 1
  },
  "nūll": {
    "type": "adjective",
    "latin": "nūllus",
    "latinNS": [
      "nūllus",
      "nūlla",
      "nūllum"
    ],
    "root": "nūll",
    "dutch": "geen",
    "klass": 1
  },
  "pulchr": {
    "type": "adjective",
    "latin": "pulcher",
    "latinNS": [
      "pulcher",
      "pulchra",
      "pulchrum"
    ],
    "root": "pulchr",
    "dutch": "mooi",
    "klass": "1er"
  },
  "fort": {
    "type": "adjective",
    "latin": "fortis",
    "latinNS": [
      "fortis",
      "fortis",
      "forte"
    ],
    "root": "fort",
    "dutch": "dapper; sterk",
    "klass": 2
  },
  "atrōc": {
    "type": "adjective",
    "latin": "atrōx",
    "latinNS": [
      "atrōx",
      "atrōx",
      "atrōx"
    ],
    "root": "atrōc",
    "dutch": "vreselijk",
    "klass": 2
  },
  "ingent": {
    "type": "adjective",
    "latin": "ingēns",
    "latinNS": [
      "ingēns",
      "ingēns",
      "ingēns"
    ],
    "root": "ingent",
    "dutch": "reusachtig",
    "klass": 2
  },
  "sapient": {
    "type": "adjective",
    "latin": "sapiēns",
    "latinNS": [
      "sapiēns",
      "sapiēns",
      "sapiēns"
    ],
    "root": "sapient",
    "dutch": "wijs; verstandig",
    "klass": 2
  },
  "grav": {
    "type": "adjective",
    "latin": "gravis",
    "latinNS": [
      "gravis",
      "gravis",
      "grave"
    ],
    "root": "grav",
    "dutch": "zwaar; ernstig",
    "klass": 2
  },
  "omn": {
    "type": "adjective",
    "latin": "omnis",
    "latinNS": [
      "omnis",
      "omnis",
      "omne"
    ],
    "root": "omn",
    "dutch": "geheel; elk",
    "klass": 2
  },
  "trīst": {
    "type": "adjective",
    "latin": "trīstis",
    "latinNS": [
      "trīstis",
      "trīstis",
      "trīste"
    ],
    "root": "trīst",
    "dutch": "droevig",
    "klass": 2
  },
  "celer": {
    "type": "adjective",
    "latin": "celer",
    "latinNS": [
      "celer",
      "celeris",
      "celere"
    ],
    "root": "celer",
    "dutch": "snel",
    "klass": 2
  },
  "incolum": {
    "type": "adjective",
    "latin": "incolumis",
    "latinNS": [
      "incolumis",
      "incolumis",
      "incolume"
    ],
    "root": "incolum",
    "dutch": "ongedeerd",
    "klass": 2
  },
  "ali": {
    "type": "adjective",
    "latin": "alius",
    "latinNS": [
      "alius",
      "alia",
      "aliud"
    ],
    "root": "ali",
    "dutch": "een andere",
    "klass": 1
  },
  "alter": {
    "type": "adjective",
    "latin": "alter",
    "latinNS": [
      "alter",
      "altera",
      "alterum"
    ],
    "root": "alter",
    "dutch": "de andere",
    "klass": 1
  },
  "dīvit": {
    "type": "adjective",
    "latin": "dīves",
    "latinNS": [
      "dīves",
      "dīves",
      "dīves"
    ],
    "root": "dīvit",
    "dutch": "rijk",
    "klass": 2
  },
  "veter": {
    "type": "adjective",
    "latin": "vetus",
    "latinNS": [
      "vetus",
      "vetus",
      "vetus"
    ],
    "root": "veter",
    "dutch": "oud",
    "klass": 2
  },
  "crūdēl": {
    "type": "adjective",
    "latin": "crūdēlis",
    "latinNS": [
      "crūdēlis",
      "crūdēlis",
      "crūdēle"
    ],
    "root": "crūdēl",
    "dutch": "wreed",
    "klass": 2
  },
  "potent": {
    "type": "adjective",
    "latin": "potēns",
    "latinNS": [
      "potēns",
      "potēns",
      "potēns"
    ],
    "root": "potent",
    "dutch": "machtig",
    "klass": 2
  },
  "laet": {
    "type": "adjective",
    "latin": "laetus",
    "latinNS": [
      "laetus",
      "laeta",
      "laetum"
    ],
    "root": "laet",
    "dutch": "vrolijk",
    "klass": 1
  },
  "maest": {
    "type": "adjective",
    "latin": "maestus",
    "latinNS": [
      "maestus",
      "maesta",
      "maestum"
    ],
    "root": "maest",
    "dutch": "treurig",
    "klass": 1
  },
  "sacr": {
    "type": "adjective",
    "latin": "sacer",
    "latinNS": [
      "sacer",
      "sacra",
      "sacrum"
    ],
    "root": "sacr",
    "dutch": "heilig; gewijd",
    "klass": "1er"
  },
  "difficil": {
    "type": "adjective",
    "latin": "difficilis",
    "latinNS": [
      "difficilis",
      "difficilis",
      "difficile"
    ],
    "root": "difficil",
    "dutch": "moeilijk",
    "klass": 2
  },
  "lāt": {
    "type": "adjective",
    "latin": "lātus",
    "latinNS": [
      "lātus",
      "lāta",
      "lātum"
    ],
    "root": "lāt",
    "dutch": "breed; uitgestrekt",
    "klass": 1
  },
  "optim": {
    "type": "adjective",
    "latin": "optimus",
    "latinNS": [
      "optimus",
      "optima",
      "optimum"
    ],
    "root": "optim",
    "dutch": "beste; zeer goed",
    "klass": 1
  },
  "timid": {
    "type": "adjective",
    "latin": "timidus",
    "latinNS": [
      "timidus",
      "timida",
      "timidum"
    ],
    "root": "timid",
    "dutch": "bang",
    "klass": 1
  },
  "alt": {
    "type": "adjective",
    "latin": "altus",
    "latinNS": [
      "altus",
      "alta",
      "altum"
    ],
    "root": "alt",
    "dutch": "hoog; diep",
    "klass": 1
  },
  "magnific": {
    "type": "adjective",
    "latin": "magnificus",
    "latinNS": [
      "magnificus",
      "magnifica",
      "magnificum"
    ],
    "root": "magnific",
    "dutch": "prachtig",
    "klass": 1
  },
  "antīqu": {
    "type": "adjective",
    "latin": "antīquus",
    "latinNS": [
      "antīquus",
      "antīqua",
      "antīquum"
    ],
    "root": "antīqu",
    "dutch": "oud",
    "klass": 1
  },
  "mal": {
    "type": "adjective",
    "latin": "malus",
    "latinNS": [
      "malus",
      "mala",
      "malum"
    ],
    "root": "mal",
    "dutch": "slecht",
    "klass": 1
  },
  "stult": {
    "type": "adjective",
    "latin": "stultus",
    "latinNS": [
      "stultus",
      "stulta",
      "stultum"
    ],
    "root": "stult",
    "dutch": "dwaas",
    "klass": 1
  },
  "calid": {
    "type": "adjective",
    "latin": "calidus",
    "latinNS": [
      "calidus",
      "calida",
      "calidum"
    ],
    "root": "calid",
    "dutch": "warm",
    "klass": 1
  },
  "cert": {
    "type": "adjective",
    "latin": "certus",
    "latinNS": [
      "certus",
      "certa",
      "certum"
    ],
    "root": "cert",
    "dutch": "zeker",
    "klass": 1
  },
  "dign": {
    "type": "adjective",
    "latin": "dignus",
    "latinNS": [
      "dignus",
      "digna",
      "dignum"
    ],
    "root": "dign",
    "dutch": "waardig; passend",
    "klass": 1
  },
  "fess": {
    "type": "adjective",
    "latin": "fessus",
    "latinNS": [
      "fessus",
      "fessa",
      "fessum"
    ],
    "root": "fess",
    "dutch": "vermoeid",
    "klass": 1
  },
  "frīgid": {
    "type": "adjective",
    "latin": "frīgidus",
    "latinNS": [
      "frīgidus",
      "frīgida",
      "frīgidum"
    ],
    "root": "frīgid",
    "dutch": "koud; koel",
    "klass": 1
  },
  "grāt": {
    "type": "adjective",
    "latin": "grātus",
    "latinNS": [
      "grātus",
      "grāta",
      "grātum"
    ],
    "root": "grāt",
    "dutch": "dankbaar; aangenaam",
    "klass": 1
  },
  "iūcund": {
    "type": "adjective",
    "latin": "iūcundus",
    "latinNS": [
      "iūcundus",
      "iūcunda",
      "iūcundum"
    ],
    "root": "iūcund",
    "dutch": "aangenaam",
    "klass": 1
  },
  "iūst": {
    "type": "adjective",
    "latin": "iūstus",
    "latinNS": [
      "iūstus",
      "iūsta",
      "iūstum"
    ],
    "root": "iūst",
    "dutch": "rechtvaardig",
    "klass": 1
  },
  "sān": {
    "type": "adjective",
    "latin": "sānus",
    "latinNS": [
      "sānus",
      "sāna",
      "sānum"
    ],
    "root": "sān",
    "dutch": "gezond",
    "klass": 1
  },
  "superb": {
    "type": "adjective",
    "latin": "superbus",
    "latinNS": [
      "superbus",
      "superba",
      "superbum"
    ],
    "root": "superb",
    "dutch": "trots; prachtig",
    "klass": 1
  },
  "clār": {
    "type": "adjective",
    "latin": "clārus",
    "latinNS": [
      "clārus",
      "clāra",
      "clārum"
    ],
    "root": "clār",
    "dutch": "helder; beroemd",
    "klass": 1
  },
  "parv": {
    "type": "adjective",
    "latin": "parvus",
    "latinNS": [
      "parvus",
      "parva",
      "parvum"
    ],
    "root": "parv",
    "dutch": "klein",
    "klass": 1
  },
  "nūd": {
    "type": "adjective",
    "latin": "nūdus",
    "latinNS": [
      "nūdus",
      "nūda",
      "nūdum"
    ],
    "root": "nūd",
    "dutch": "naakt; beroofd van",
    "klass": 1
  },
  "līber": {
    "type": "adjective",
    "latin": "līber",
    "latinNS": [
      "līber",
      "lībera",
      "līberum"
    ],
    "root": "līber",
    "dutch": "vrij",
    "klass": "1r"
  },
  "miser": {
    "type": "adjective",
    "latin": "miser",
    "latinNS": [
      "miser",
      "misera",
      "miserum"
    ],
    "root": "miser",
    "dutch": "ongelukkig",
    "klass": "1r"
  },
  "aegr": {
    "type": "adjective",
    "latin": "aeger",
    "latinNS": [
      "aeger",
      "aegra",
      "aegrum"
    ],
    "root": "aegr",
    "dutch": "ziek",
    "klass": "1er"
  },
  "nigr": {
    "type": "adjective",
    "latin": "niger",
    "latinNS": [
      "niger",
      "nigra",
      "nigrum"
    ],
    "root": "nigr",
    "dutch": "zwart",
    "klass": "1er"
  },
  "maxim": {
    "type": "adjective",
    "latin": "maximus",
    "latinNS": [
      "maximus",
      "maxima",
      "maximum"
    ],
    "root": "maxim",
    "dutch": "zeer groot; grootste",
    "klass": 1
  },
  "nōt": {
    "type": "adjective",
    "latin": "nōtus",
    "latinNS": [
      "nōtus",
      "nōta",
      "nōtum"
    ],
    "root": "nōt",
    "dutch": "bekend",
    "klass": 1
  },
  "vēr": {
    "type": "adjective",
    "latin": "vērus",
    "latinNS": [
      "vērus",
      "vēra",
      "vērum"
    ],
    "root": "vēr",
    "dutch": "waar; echt",
    "klass": 1
  },
  "dūr": {
    "type": "adjective",
    "latin": "dūrus",
    "latinNS": [
      "dūrus",
      "dūra",
      "dūrum"
    ],
    "root": "dūr",
    "dutch": "hard",
    "klass": 1
  },
  "vīv": {
    "type": "adjective",
    "latin": "vīvus",
    "latinNS": [
      "vīvus",
      "vīva",
      "vīvum"
    ],
    "root": "vīv",
    "dutch": "levend",
    "klass": 1
  },
  "plēn": {
    "type": "adjective",
    "latin": "plēnus",
    "latinNS": [
      "plēnus",
      "plēna",
      "plēnum"
    ],
    "root": "plēn",
    "dutch": "vol",
    "klass": 1
  },
  "cupid": {
    "type": "adjective",
    "latin": "cupidus",
    "latinNS": [
      "cupidus",
      "cupida",
      "cupidum"
    ],
    "root": "cupid",
    "dutch": "vol verlangen naar",
    "klass": 1
  },
  "mīr": {
    "type": "adjective",
    "latin": "mīrus",
    "latinNS": [
      "mīrus",
      "mīra",
      "mīrum"
    ],
    "root": "mīr",
    "dutch": "wonderlijk",
    "klass": 1
  },
  "angust": {
    "type": "adjective",
    "latin": "angustus",
    "latinNS": [
      "angustus",
      "angusta",
      "angustum"
    ],
    "root": "angust",
    "dutch": "smal",
    "klass": 1
  },
  "tūt": {
    "type": "adjective",
    "latin": "tūtus",
    "latinNS": [
      "tūtus",
      "tūta",
      "tūtum"
    ],
    "root": "tūt",
    "dutch": "veilig",
    "klass": 1
  },
  "hūmān": {
    "type": "adjective",
    "latin": "hūmānus",
    "latinNS": [
      "hūmānus",
      "hūmāna",
      "hūmānum"
    ],
    "root": "hūmān",
    "dutch": "menselijk; beschaafd",
    "klass": 1
  },
  "aure": {
    "type": "adjective",
    "latin": "aureus",
    "latinNS": [
      "aureus",
      "aurea",
      "aureum"
    ],
    "root": "aure",
    "dutch": "gouden",
    "klass": 1
  },
  "obscūr": {
    "type": "adjective",
    "latin": "obscūrus",
    "latinNS": [
      "obscūrus",
      "obscūra",
      "obscūrum"
    ],
    "root": "obscūr",
    "dutch": "duister; onbeduidend",
    "klass": 1
  },
  "brev": {
    "type": "adjective",
    "latin": "brevis",
    "latinNS": [
      "brevis",
      "brevis",
      "breve"
    ],
    "root": "brev",
    "dutch": "kort",
    "klass": 2
  },
  "mortāl": {
    "type": "adjective",
    "latin": "mortālis",
    "latinNS": [
      "mortālis",
      "mortālis",
      "mortāle"
    ],
    "root": "mortāl",
    "dutch": "sterfelijk",
    "klass": 2
  },
  "nōbil": {
    "type": "adjective",
    "latin": "nōbilis",
    "latinNS": [
      "nōbilis",
      "nōbilis",
      "nōbile"
    ],
    "root": "nōbil",
    "dutch": "adellijk; beroemd",
    "klass": 2
  },
  "audāc": {
    "type": "adjective",
    "latin": "audāx",
    "latinNS": [
      "audāx",
      "audāx",
      "audāx"
    ],
    "root": "audāc",
    "dutch": "moedig; overmoedig",
    "klass": 2
  },
  "ferōc": {
    "type": "adjective",
    "latin": "ferōx",
    "latinNS": [
      "ferōx",
      "ferōx",
      "ferōx"
    ],
    "root": "ferōc",
    "dutch": "woest; uitdagend",
    "klass": 2
  },
  "facil": {
    "type": "adjective",
    "latin": "facilis",
    "latinNS": [
      "facilis",
      "facilis",
      "facile"
    ],
    "root": "facil",
    "dutch": "gemakkelijk",
    "klass": 2
  },
  "lev": {
    "type": "adjective",
    "latin": "levis",
    "latinNS": [
      "levis",
      "levis",
      "leve"
    ],
    "root": "lev",
    "dutch": "licht",
    "klass": 2
  },
  "simil": {
    "type": "adjective",
    "latin": "similis",
    "latinNS": [
      "similis",
      "similis",
      "simile"
    ],
    "root": "simil",
    "dutch": "gelijk aan; gelijkend op",
    "klass": 2
  },
  "ūtil": {
    "type": "adjective",
    "latin": "ūtilis",
    "latinNS": [
      "ūtilis",
      "ūtilis",
      "ūtile"
    ],
    "root": "ūtil",
    "dutch": "nuttig",
    "klass": 2
  },
  "fēlic": {
    "type": "adjective",
    "latin": "fēlix",
    "latinNS": [
      "fēlix",
      "fēlix",
      "fēlix"
    ],
    "root": "fēlic",
    "dutch": "gelukkig",
    "klass": 2
  },
  "pauper": {
    "type": "adjective",
    "latin": "pauper",
    "latinNS": [
      "pauper",
      "pauper",
      "pauper"
    ],
    "root": "pauper",
    "dutch": "arm",
    "klass": 2
  },
  "ācr": {
    "type": "adjective",
    "latin": "ācer",
    "latinNS": [
      "ācer",
      "ācris",
      "ācre"
    ],
    "root": "ācr",
    "dutch": "scherp; fel",
    "klass": 2
  },
  "aetern": {
    "type": "adjective",
    "latin": "aeternus",
    "latinNS": [
      "aeternus",
      "aeterna",
      "aeternum"
    ],
    "root": "aetern",
    "dutch": "eeuwig",
    "klass": 1
  },
  "ēgregi": {
    "type": "adjective",
    "latin": "ēgregius",
    "latinNS": [
      "ēgregius",
      "ēgregia",
      "ēgregium"
    ],
    "root": "ēgregi",
    "dutch": "buitengewoon",
    "klass": 1
  },
  "secund": {
    "type": "adjective",
    "latin": "secundus",
    "latinNS": [
      "secundus",
      "secunda",
      "secundum"
    ],
    "root": "secund",
    "dutch": "tweede; gunstig",
    "klass": 1
  },
  "tacit": {
    "type": "adjective",
    "latin": "tacitus",
    "latinNS": [
      "tacitus",
      "tacita",
      "tacitum"
    ],
    "root": "tacit",
    "dutch": "zwijgend",
    "klass": 1
  },
  "lent": {
    "type": "adjective",
    "latin": "lentus",
    "latinNS": [
      "lentus",
      "lenta",
      "lentum"
    ],
    "root": "lent",
    "dutch": "traag",
    "klass": 1
  },
  "quiēt": {
    "type": "adjective",
    "latin": "quiētus",
    "latinNS": [
      "quiētus",
      "quiēta",
      "quiētum"
    ],
    "root": "quiēt",
    "dutch": "rustig",
    "klass": 1
  },
  "saev": {
    "type": "adjective",
    "latin": "saevus",
    "latinNS": [
      "saevus",
      "saeva",
      "saevum"
    ],
    "root": "saev",
    "dutch": "woest; wreed",
    "klass": 1
  },
  "infer": {
    "type": "adjective",
    "latin": "inferus",
    "latinNS": [
      "inferus",
      "infera",
      "inferum"
    ],
    "root": "infer",
    "dutch": "laaggelegen",
    "klass": 1
  },
  "tant": {
    "type": "adjective",
    "latin": "tantus",
    "latinNS": [
      "tantus",
      "tanta",
      "tantum"
    ],
    "root": "tant",
    "dutch": "zo groot",
    "klass": 1
  },
  "dulc": {
    "type": "adjective",
    "latin": "dulcis",
    "latinNS": [
      "dulcis",
      "dulcis",
      "dulce"
    ],
    "root": "dulc",
    "dutch": "zoet; lief",
    "klass": 2
  },
  "aliēn": {
    "type": "adjective",
    "latin": "aliēnus",
    "latinNS": [
      "aliēnus",
      "aliēna",
      "aliēnum"
    ],
    "root": "aliēn",
    "dutch": "van een ander; vreemd",
    "klass": 1
  },
  "dext": {
    "type": "adjective",
    "latin": "dexter",
    "latinNS": [
      "dexter",
      "dextra",
      "dextrum"
    ],
    "root": "dext",
    "dutch": "rechts",
    "klass": "1er"
  },
  "rēgi": {
    "type": "adjective",
    "latin": "rēgius",
    "latinNS": [
      "rēgius",
      "rēgia",
      "rēgium"
    ],
    "root": "rēgi",
    "dutch": "koninklijk",
    "klass": 1
  },
  "familiār": {
    "type": "adjective",
    "latin": "familiāris",
    "latinNS": [
      "familiāris",
      "familiāris",
      "familiāre"
    ],
    "root": "familiār",
    "dutch": "van het gezin; goede bekende",
    "klass": 2
  },
  "turp": {
    "type": "adjective",
    "latin": "turpis",
    "latinNS": [
      "turpis",
      "turpis",
      "turpe"
    ],
    "root": "turp",
    "dutch": "lelijk; schandelijk",
    "klass": 2
  },
  "tāl": {
    "type": "adjective",
    "latin": "tālis",
    "latinNS": [
      "tālis",
      "tālis",
      "tāle"
    ],
    "root": "tāl",
    "dutch": "zodanig",
    "klass": 2
  },
  "quant": {
    "type": "adjective",
    "latin": "quantus",
    "latinNS": [
      "quantus",
      "quanta",
      "quantum"
    ],
    "root": "quant",
    "dutch": "hoe groot?; (zo groot) als",
    "klass": 1
  },
  "callid": {
    "type": "adjective",
    "latin": "callidus",
    "latinNS": [
      "callidus",
      "callida",
      "callidum"
    ],
    "root": "callid",
    "dutch": "sluw",
    "klass": 1
  },
  "reliqu": {
    "type": "adjective",
    "latin": "reliquus",
    "latinNS": [
      "reliquus",
      "reliqua",
      "reliquum"
    ],
    "root": "reliqu",
    "dutch": "overig",
    "klass": 1
  },
  "aequ": {
    type: "adjective",
    latin: "aequus",
    latinNS: [ "aequus", "aequa", "aequum" ],
    root: "aequ",
    dutch: "gelijk; eerlijk",
    klass: 1,
  },
  "parāt": {
    type: "adjective",
    latin: "parātus",
    latinNS: [ "parātus", "parāta", "parātum" ],
    root: "parāt",
    dutch: "bereid",
    klass: 1,
  },
  "patri": {
    type: "adjective",
    latin: "patrius",
    latinNS: [ "patrius", "patria", "patrium" ],
    root: "patri",
    dutch: "vaderlijk; voorvaderlijk",
    klass: 1,
  },
  "poster": {
    type: "adjective",
    latin: "posterus",
    latinNS: [ "posterus", "postera", "posterum" ],
    root: "poster",
    dutch: "volgend",
    klass: 1,
  },
  "valid": {
    type: "adjective",
    latin: "validus",
    latinNS: [ "validus", "valida", "validum" ],
    root: "valid",
    dutch: "krachtig, sterk",
    klass: 1,
  },
  "crēbr": {
    type: "adjective",
    latin: "crēber",
    latinNS: [ "crēber", "crēbra", "crēbrum" ],
    root: "crēbr",
    dutch: "talrijk",
    klass: 1,
  },
  "proxim": {
    type: "adjective",
    latin: "proximus",
    latinNS: [ "proximus", "proxima", "proximum" ],
    root: "proxim",
    dutch: "dichtstbij; verwant",
    klass: 1,
  },
  // "": {
  //   type: "adjective",
  //   latin: "",
  //   latinNS: [ "", "", "" ],
  //   root: "",
  //   dutch: "",
  //   klass: ,
  // },
};

export function searchLatinAdjective(root: keyof typeof LATIN_ADJECTIVES): LatinAdjective {
  if (!(root in LATIN_ADJECTIVES)) {
    throw new Error(`No Latin adjective found for root: ${root}`);
  }
  return LATIN_ADJECTIVES[root];
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_PLURAL_ADJECTIVES: Array<LatinPluralAdjective> = [
  {
    type: "pluralAdjective",
    latin: "omnēs",
    root: "omni",
    dutch: "alle",
    klass: 2,
  }
];

export function searchLatinPluralAdjective(root: string): LatinPluralAdjective {
  const results = LATIN_PLURAL_ADJECTIVES.filter((pluralAdjective) => pluralAdjective.root === root);
  if (results.length !== 1) {
    console.error(results);
    throw new Error(`No single Latin plural adjective found for root: ${root}`);
  }
  return results[0];
}