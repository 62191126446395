import * as rt from "runtypes";
import { assertNever } from "../../utils/assertNever.tsx";
import { merge } from "../../utils/merge.tsx";
import { Optional } from "../../utils/Optional.tsx";
import { Sex, WordFinal } from "../WordBase.tsx";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type LatinNounDeclension =
  | "1"
  | "2" | "2irr" | "2er" | "2r"
  | "3cons" | "3mixed" | "3irr"
  | "4"
  | "5";

export type LatinNounSex<D extends LatinNounDeclension> =
  D extends "1" ? ("f") & Sex :
    D extends "2" ? ("m" | "n") & Sex :
      D extends "2irr" ? ("m" | "n") & Sex :
        D extends "2er" ? ("m" | "n") & Sex :
          D extends "2r" ? ("m" | "n") & Sex :
            D extends "3cons" ? ("m" | "f" | "n") & Sex :
              D extends "3mixed" ? ("m" | "f" | "n") & Sex :
                D extends "3irr" ? ("m" | "f") & Sex :
                  D extends "4" ? ("m" | "f" | "n") & Sex :
                    D extends "5" ? ("f") & Sex :
                      never;

export const LatinDeclinedNoun = rt.Record({
  nomS: WordFinal,
  accS: WordFinal,
  genS: WordFinal,
  datS: WordFinal,
  ablS: WordFinal,
  vocS: WordFinal,
  nomP: WordFinal,
  accP: WordFinal,
  genP: WordFinal,
  datP: WordFinal,
  ablP: WordFinal,
  vocP: WordFinal,
});
export type LatinDeclinedNoun = rt.Static<typeof LatinDeclinedNoun>;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface LatinNounBase<D extends LatinNounDeclension> {
  type: "noun";
  // nominativeS: string;
  // genitiveS: string;
  root: string;
  dutch: string;
  klass: 1 | "1r" | "1er" | 2 | 3;
  declension: D;
  explicitDeclensions?: Optional<LatinDeclinedNoun>;
  hasSingular: boolean;
  hasPlural: boolean;
  sex: LatinNounSex<D>;
}

export type LatinNoun =
  | LatinNounBase<"1">
  | LatinNounBase<"2">
  | LatinNounBase<"2irr">
  | LatinNounBase<"2er">
  | LatinNounBase<"2r">
  | LatinNounBase<"3cons">
  | LatinNounBase<"3mixed">
  | LatinNounBase<"3irr">
  | LatinNounBase<"4">
  | LatinNounBase<"5">;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type LatinNounDeclensionTable = { [D in LatinNounDeclension]: Record<LatinNounSex<D>, string> };

export function safeDecline(table: LatinNounDeclensionTable, { declension, sex }: LatinNoun): WordFinal {
  // We need this switch statement to allow Typescript to infer the most
  // narrow type for `sex`.
  switch (declension) {
    case "1":
      return table[declension][sex];
    case "2":
      return table[declension][sex];
    case "2irr":
      return table[declension][sex];
    case "2er":
      return table[declension][sex];
    case "2r":
      return table[declension][sex];
    case "3cons":
      return table[declension][sex];
    case "3mixed":
      return table[declension][sex];
    case "3irr":
      return table[declension][sex];
    case "4":
      return table[declension][sex];
    case "5":
      return table[declension][sex];
    default:
      assertNever(declension);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function nomS(noun: LatinNoun): WordFinal {
  return noun?.explicitDeclensions?.nomS;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_NOUN_NOMINATIVE_PLURAL: LatinNounDeclensionTable = {
  "1": {
    "f": "ae",
  },
  "2": {
    "m": "ī",
    "n": "a",
  },
  "2irr": {
    "m": "ī",
    "n": "a",
  },
  "2er": {
    "m": "ī",
    "n": "a",
  },
  "2r": {
    "m": "ī",
    "n": "a",
  },
  "3cons": {
    "m": "ēs",
    "f": "ēs",
    "n": "a",
  },
  "3mixed": {
    "m": "ēs",
    "f": "ēs",
    "n": "a",
  },
  "3irr": {
    "m": "",  // or just empty? or null/undefined?
    "f": "",  // or just empty? or null/undefined?
  },
  "4": {
    "m": "ūs",
    "f": "ūs",
    "n": "ūs",
  },
  "5": {
    "f": "ēs",
  },
};

export function nomP(noun: LatinNoun): WordFinal {
  return noun.hasPlural
    ? noun.explicitDeclensions?.nomP ?? noun.root + safeDecline(LATIN_NOUN_NOMINATIVE_PLURAL, noun)
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_NOUN_ACCUSATIVE_SINGULAR: LatinNounDeclensionTable = {
  "1": {
    "f": "am",
  },
  "2": {
    "m": "um",
    "n": "um",
  },
  "2irr": {
    "m": "um",
    "n": "um",
  },
  "2er": {
    "m": "um",
    "n": "um",
  },
  "2r": {
    "m": "um",
    "n": "um",
  },
  "3cons": {
    "m": "em",
    "f": "em",
    "n": "",
  },
  "3mixed": {
    "m": "em",
    "f": "em",
    "n": "",
  },
  "3irr": {
    "m": "",  // or just empty? or null/undefined?
    "f": "",  // or just empty? or null/undefined?
  },
  "4": {
    "m": "um",
    "f": "um",
    "n": "um",
  },
  "5": {
    "f": "em",
  },
};

export function accS(noun: LatinNoun): WordFinal {
  if (!noun.hasSingular) return undefined;
  const { declension, sex } = noun;
  if (sex === "n") {
    if ((declension === "3cons") || (declension === "3mixed")) {
      return nomS(noun);
    }
  }
  return noun.explicitDeclensions?.accS ?? noun.root + safeDecline(LATIN_NOUN_ACCUSATIVE_SINGULAR, noun);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_NOUN_ACCUSATIVE_PLURAL: LatinNounDeclensionTable = {
  "1": {
    "f": "ās",
  },
  "2": {
    "m": "ōs",
    "n": "a",
  },
  "2irr": {
    "m": "ōs",
    "n": "a",
  },
  "2er": {
    "m": "ōs",
    "n": "a",
  },
  "2r": {
    "m": "ōs",
    "n": "a",
  },
  "3cons": {
    "m": "ēs",
    "f": "ēs",
    "n": "a",
  },
  "3mixed": {
    "m": "ēs",
    "f": "ēs",
    "n": "a",
  },
  "3irr": {
    "m": "",  // or just empty? or null/undefined?
    "f": "",  // or just empty? or null/undefined?
  },
  "4": {
    "m": "ūs",
    "f": "ūs",
    "n": "ūs",
  },
  "5": {
    "f": "ēs",
  },
};

export function accP(noun: LatinNoun): WordFinal {
  return noun.hasPlural
    ? noun.explicitDeclensions?.accP ?? noun.root + safeDecline(LATIN_NOUN_ACCUSATIVE_PLURAL, noun)
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_NOUN_GENITIVE_SINGULAR: LatinNounDeclensionTable = {
  "1": {
    "f": "ae",
  },
  "2": {
    "m": "ī",
    "n": "ī",
  },
  "2irr": {
    "m": "ī",
    "n": "ī",
  },
  "2er": {
    "m": "ī",
    "n": "ī",
  },
  "2r": {
    "m": "ī",
    "n": "ī",
  },
  "3cons": {
    "m": "is",
    "f": "is",
    "n": "is",
  },
  "3mixed": {
    "m": "is",
    "f": "is",
    "n": "is",
  },
  "3irr": {
    "m": "",  // or just empty? or null/undefined?
    "f": "",  // or just empty? or null/undefined?
  },
  "4": {
    "m": "ūs",
    "f": "ūs",
    "n": "ūs",
  },
  "5": {
    "f": "eī",
  },
};

export function genS(noun: LatinNoun): WordFinal {
  return noun.hasSingular
    ? noun.explicitDeclensions?.genS ?? noun.root + safeDecline(LATIN_NOUN_GENITIVE_SINGULAR, noun)
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_NOUN_GENITIVE_PLURAL: LatinNounDeclensionTable = {
  "1": {
    "f": "ārum",
  },
  "2": {
    "m": "ōrum",
    "n": "ōrum",
  },
  "2irr": {
    "m": "ōrum",
    "n": "ōrum",
  },
  "2er": {
    "m": "ōrum",
    "n": "ōrum",
  },
  "2r": {
    "m": "ōrum",
    "n": "ōrum",
  },
  "3cons": {
    "m": "um",
    "f": "um",
    "n": "um",
  },
  "3mixed": {
    "m": "um",
    "f": "um",
    "n": "um",
  },
  "3irr": {
    "m": "",  // or just empty? or null/undefined?
    "f": "",  // or just empty? or null/undefined?
  },
  "4": {
    "m": "uum",
    "f": "uum",
    "n": "uum",
  },
  "5": {
    "f": "ērum",
  },
};

export function genP(noun: LatinNoun): WordFinal {
  return noun.hasPlural
    ? noun.explicitDeclensions?.genP ?? noun.root + safeDecline(LATIN_NOUN_GENITIVE_PLURAL, noun)
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_NOUN_DATIVE_SINGULAR: LatinNounDeclensionTable = {
  "1": {
    "f": "ae",
  },
  "2": {
    "m": "ō",
    "n": "ō",
  },
  "2irr": {
    "m": "ō",
    "n": "ō",
  },
  "2er": {
    "m": "ō",
    "n": "ō",
  },
  "2r": {
    "m": "ō",
    "n": "ō",
  },
  "3cons": {
    "m": "ī",
    "f": "ī",
    "n": "ī",
  },
  "3mixed": {
    "m": "ī",
    "f": "ī",
    "n": "ī",
  },
  "3irr": {
    "m": "",  // or just empty? or null/undefined?
    "f": "",  // or just empty? or null/undefined?
  },
  "4": {
    "m": "uī",
    "f": "uī",
    "n": "uī",
  },
  "5": {
    "f": "eī",
  },
};

export function datS(noun: LatinNoun): WordFinal {
  return noun.hasSingular
    ? noun.explicitDeclensions?.datS ?? noun.root + safeDecline(LATIN_NOUN_DATIVE_SINGULAR, noun)
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_NOUN_DATIVE_PLURAL: LatinNounDeclensionTable = {
  "1": {
    "f": "īs",
  },
  "2": {
    "m": "īs",
    "n": "īs",
  },
  "2irr": {
    "m": "īs",
    "n": "īs",
  },
  "2er": {
    "m": "īs",
    "n": "īs",
  },
  "2r": {
    "m": "īs",
    "n": "īs",
  },
  "3cons": {
    "m": "ibus",
    "f": "ibus",
    "n": "ibus",
  },
  "3mixed": {
    "m": "ibus",
    "f": "ibus",
    "n": "ibus",
  },
  "3irr": {
    "m": "",  // or just empty? or null/undefined?
    "f": "",  // or just empty? or null/undefined?
  },
  "4": {
    "m": "ibus",
    "f": "ibus",
    "n": "ibus",
  },
  "5": {
    "f": "ēbus",
  },
};

export function datP(noun: LatinNoun): WordFinal {
  return noun.hasPlural
    ? noun.explicitDeclensions?.datP ?? noun.root + safeDecline(LATIN_NOUN_DATIVE_PLURAL, noun)
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_NOUN_ABLATIVE_SINGULAR: LatinNounDeclensionTable = {
  "1": {
    "f": "ā",
  },
  "2": {
    "m": "ō",
    "n": "ō",
  },
  "2irr": {
    "m": "ō",
    "n": "ō",
  },
  "2er": {
    "m": "ō",
    "n": "ō",
  },
  "2r": {
    "m": "ō",
    "n": "ō",
  },
  "3cons": {
    "m": "e",
    "f": "e",
    "n": "e",
  },
  "3mixed": {
    "m": "e",
    "f": "e",
    "n": "e",
  },
  "3irr": {
    "m": "",  // or just empty? or null/undefined?
    "f": "",  // or just empty? or null/undefined?
  },
  "4": {
    "m": "ū",
    "f": "ū",
    "n": "ū",
  },
  "5": {
    "f": "ē",
  },
};

export function ablS(noun: LatinNoun): WordFinal {
  return noun.hasSingular
    ? noun.explicitDeclensions?.ablS ?? noun.root + safeDecline(LATIN_NOUN_ABLATIVE_SINGULAR, noun)
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_NOUN_ABLATIVE_PLURAL: LatinNounDeclensionTable = {
  "1": {
    "f": "īs",
  },
  "2": {
    "m": "īs",
    "n": "īs",
  },
  "2irr": {
    "m": "īs",
    "n": "īs",
  },
  "2er": {
    "m": "īs",
    "n": "īs",
  },
  "2r": {
    "m": "īs",
    "n": "īs",
  },
  "3cons": {
    "m": "ibus",
    "f": "ibus",
    "n": "ibus",
  },
  "3mixed": {
    "m": "ibus",
    "f": "ibus",
    "n": "ibus",
  },
  "3irr": {
    "m": "",  // or just empty? or null/undefined?
    "f": "",  // or just empty? or null/undefined?
  },
  "4": {
    "m": "ibus",
    "f": "ibus",
    "n": "ibus",
  },
  "5": {
    "f": "ēbus",
  },
};

export function ablP(noun: LatinNoun): WordFinal {
  return noun.hasPlural
    ? noun.explicitDeclensions?.ablP ?? noun.root + safeDecline(LATIN_NOUN_ABLATIVE_PLURAL, noun)
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function vocS(noun: LatinNoun): WordFinal {
  if (!noun.hasSingular) return undefined;
  if (noun.explicitDeclensions?.vocS) {
    return noun.explicitDeclensions.vocS;
  }
  const { root, declension, sex } = noun;
  switch (declension) {
    case "1":
    case "2":
    case "2irr":
    case "2r":
    case "2er": {
      if (sex === "m") return root + "e";
      return nomS(noun);
    }
    case "3cons":
    case "3mixed":
    case "3irr":
    case "4":
    case "5": {
      return nomS(noun);
    }
    default:
      assertNever(declension);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_NOUN_VOCATIVE_PLURAL: LatinNounDeclensionTable = {
  "1": {
    "f": "ae",
  },
  "2": {
    "m": "ī",
    "n": "a",
  },
  "2irr": {
    "m": "ī",
    "n": "a",
  },
  "2er": {
    "m": "ī",
    "n": "a",
  },
  "2r": {
    "m": "ī",
    "n": "a",
  },
  "3cons": {
    "m": "ēs",
    "f": "ēs",
    "n": "a",
  },
  "3mixed": {
    "m": "ēs",
    "f": "ēs",
    "n": "a",
  },
  "3irr": {
    "m": "",  // or just empty? or null/undefined?
    "f": "ēs",  // or just empty? or null/undefined?
  },
  "4": {
    "m": "ūs",
    "f": "ūs",
    "n": "ūs",
  },
  "5": {
    "f": "ēs",
  },
};

export function vocP(noun: LatinNoun): WordFinal {
  return noun.hasPlural
    ? noun.explicitDeclensions?.vocP ?? noun.root + safeDecline(LATIN_NOUN_VOCATIVE_PLURAL, noun)
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function decline(noun: LatinNoun): LatinDeclinedNoun {
  return {
    nomS: nomS(noun),
    accS: accS(noun),
    genS: genS(noun),
    datS: datS(noun),
    ablS: ablS(noun),
    vocS: vocS(noun),
    nomP: nomP(noun),
    accP: accP(noun),
    genP: genP(noun),
    datP: datP(noun),
    ablP: ablP(noun),
    vocP: vocP(noun),
  };
}

export function nominative(noun: LatinNoun): string {
  if (noun.hasSingular) {
    return merge(nomS(noun));
  }
  return merge(nomP(noun));
}