import { useEffect, useState } from "react";
import { isValidVocabularyCode, VocabularyCode } from "../data/Data";
import { useParams } from "react-router";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function useVocabularyCodeFromParams() {
  const params = useParams();
  const [ code, setCode ] = useState<VocabularyCode | null>(null);
  useEffect(() => {
    const parsedCode = params?.code ?? "";
    if (isValidVocabularyCode(parsedCode)) {
      setCode(parsedCode);
    }
  }, [ params ]);
  return code;
}