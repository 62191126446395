import { assertNever } from "../../utils/assertNever";
import { GreekAdjective } from "./GreekAdjectives";
import { GreekAdverb } from "./GreekAdverbs";
import { GreekConjunction } from "./GreekConjunctions";
import { GreekCountingWord } from "./GreekCountingWord";
import { GreekImpersonalVerb } from "./GreekImpersonalVerb";
import { GreekNoun } from "./GreekNouns";
import { GreekNumeral } from "./GreekNumeral";
import { GreekParticle } from "./GreekParticles";
import { GreekPrefix } from "./GreekPrefix";
import { GreekPrepositionAccusative, GreekPrepositionDative, GreekPrepositionGenitive } from "./GreekPrepositions";
import { GreekDemonstrativePronoun, GreekInterrogativePronoun } from "./GreekPronouns";
import { GreekSuffix } from "./GreekSuffixes";
import { GreekVerb } from "./GreekVerbs";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type GreekWord =
  | GreekAdjective
  | GreekAdverb
  | GreekConjunction
  | GreekCountingWord
  | GreekDemonstrativePronoun
  | GreekImpersonalVerb
  | GreekInterrogativePronoun
  | GreekNoun
  | GreekNumeral
  | GreekParticle
  | GreekPrefix
  | GreekPrepositionAccusative
  | GreekPrepositionDative
  | GreekPrepositionGenitive
  | GreekSuffix
  | GreekVerb
  ;

export function toDutchType(word: GreekWord): string {
  switch (word.type) {
    case "adjective":
      return "Adjectief";
    case "adverb":
      return "Bijwoord";
    case "conjunction":
      return "Voegwoord";
    case "countingWord":
      return "Telwoord";
    case "demonstrativePronoun":
      return "Aanwijzend voornaamwoord";
    case "impersonalVerb":
      return "Onpersoonlijk werkwoord";
    case "interrogativePronoun":
      return "Vragend voornaamwoord";
    case "noun":
      return "Substantief";
    case "numeral":
      return "Hoofdtelwoord";
    case "particle":
      return "Partikel";
    case "prefix":
      return "Voorvoegsel";
    case "prepositionAccusative":
      return "Voorzetsel (+ accusatief)";
    case "prepositionDative":
      return "Voorzetsel (+ datief)";
    case "prepositionGenitive":
      return "Voorzetsel (+ genitief)";
    case "suffix":
      return "Achtervoegsel";
    case "verb":
      return "Werkwoord";
    default:
      return assertNever(word);
  }
}

export function toForeignTitle(word: GreekWord): string {
  switch (word.type) {
    case "adjective":
    case "adverb":
    case "conjunction":
    case "countingWord":
    case "demonstrativePronoun":
    case "impersonalVerb":
    case "interrogativePronoun":
    case "noun":
    case "numeral":
    case "particle":
    case "prefix":
    case "prepositionAccusative":
    case "prepositionDative":
    case "prepositionGenitive":
    case "suffix":
    case "verb":
      return word.ground;
    // case "adjective":
    default:
      return assertNever(word);
  }
}

export function toDutchTitle(word: GreekWord): string {
  return word.dutch;
}

const TRANSLITERATION_MAP: Record<string, string> = {
  'α': 'a', 'ά': 'a', 'ἀ': 'a', 'ἁ': 'ha', 'ἄ': 'a', 'ἅ': 'ha', 'ἂ': 'a', 'ἃ': 'ha', 'ᾶ': 'a', 'ᾰ': 'a', 'ᾱ': 'a', 'ᾰ̓': 'a', 'ᾴ': 'ai', 'ᾰ̓́': 'a', 'ᾰ́': 'a', 'ᾱ́': 'a',
  'β': 'b',
  'γ': 'g',
  'δ': 'd',
  'ε': 'e', 'έ': 'e', 'ἐ': 'e', 'ἑ': 'he', 'ἔ': 'e', 'ἕ': 'he',
  'ζ': 'z',
  'η': 'e', 'ή': 'e', 'ἠ': 'e', 'ἡ': 'he', 'ἤ': 'e', 'ἥ': 'he', 'ἢ': 'e', 'ἣ': 'he', 'ῆ': 'e', 'ἦ': 'e', 'ῄ': 'ei',
  'θ': 'th',
  'ι': 'i', 'ί': 'í', 'ἰ': 'i', 'ἱ': 'hi', 'ἴ': 'í', 'ἵ': 'hí', 'ἲ': 'ì', 'ἳ': 'hì', 'ῖ': 'î', 'ῑ': 'i', 'ῐ': 'i', 'ἶ': 'i', 'ῐ́': 'i', 'ῑ́': 'i', 'ἱ̈': 'i',
  'κ': 'k',
  'λ': 'l',
  'μ': 'm',
  'ν': 'n',
  'ξ': 'x',
  'ο': 'o', 'ό': 'o', 'ὀ': 'o', 'ὁ': 'ho', 'ὄ': 'o', 'ὅ': 'ho', 'ὸ': 'o',
  'π': 'p',
  'ρ': 'r',
  'ῥ': 'rh',
  'σ': 's', 'ς': 's',
  'τ': 't',
  'υ': 'u', 'ύ': 'u', 'ὐ': 'u', 'ὑ': 'hu', 'ὔ': 'u', 'ὕ': 'hu', 'ὒ': 'u', 'ὓ': 'hu', 'ῦ': 'u', 'ῠ': 'u', 'ῡ': 'u', 'ὖ': 'u', 'ὗ': 'hu', 'ῠ̔': 'hu', 'ῠ́': 'u', 'ῡ́': 'u', 'ῠ̔́': 'hu',
  'φ': 'ph', '': 'h',
  'χ': 'ch',
  'ψ': 'ps',
  'ω': 'o', 'ώ': 'o', 'ὠ': 'o', 'ὡ': 'ho', 'ὤ': 'o', 'ὥ': 'ho', 'ὢ': 'o', 'ὣ': 'ho', 'ῶ': 'o', 'ῴ': 'oi', 'ῷ': 'oi',
  // ---
  'Α': 'A', 'Ά': 'A', 'Ἀ': 'A', 'Ἁ': 'Ha', 'Ἄ': 'A', 'Ἅ': 'Ha', 'Ἂ': 'A', 'Ἃ': 'Ha',
  'Β': 'B',
  'Γ': 'G',
  'Δ': 'D',
  'Ε': 'E', 'Έ': 'E', 'Ἐ': 'E', 'Ἑ': 'He', 'Ἔ': 'E', 'Ἕ': 'He',
  'Ζ': 'Z',
  'Η': 'E', 'Ή': 'E', 'Ἠ': 'E', 'Ἡ': 'He', 'Ἤ': 'E', 'Ἥ': 'He', 'Ἢ': 'E', 'Ἣ': 'He',
  'Θ': 'Th',
  'Ι': 'I', 'Ί': 'I', 'Ἰ': 'I', 'Ἱ': 'Hi', 'Ἴ': 'I', 'Ἵ': 'Hi', 'Ἲ': 'I', 'Ἳ': 'Hi',
  'Κ': 'K',
  'Λ': 'L',
  'Μ': 'M',
  'Ν': 'N',
  'Ξ': 'X',
  'Ο': 'O', 'Ό': 'O', 'Ὀ': 'O', 'Ὁ': 'Ho', 'Ὄ': 'O', 'Ὅ': 'Ho',
  'Π': 'P',
  'Ρ': 'R',
  'Σ': 'S',
  'Τ': 'T',
  'Υ': 'U', 'Ύ': 'U', 'Ὑ': 'Hu', 'Ὓ': 'Hu', 'Ὕ': 'Hu', 'Ὗ': 'Hu',
  'Φ': 'Ph',
  'Χ': 'Ch',
  'Ψ': 'Ps',
  'Ω': 'O', 'Ώ': 'O', 'Ὠ': 'O', 'Ὡ': 'Ho', 'Ὤ': 'O', 'Ὥ': 'Ho', 'Ὢ': 'O', 'Ὣ': 'Ho',
};

export function searchify(word: string): string {
  const despacedWord = word.replace(/[\s\p{P}]/gu, "");
  let transliterated = '';
  let i = 0;
  while (i < despacedWord.length) {
    // if (loud(word)) console.log(`${i} < ${despacedWord.length}`)
    let match = null;
    const maxLength = despacedWord.length - i; // Max possible length for the substring

    // Check substrings from longest to shortest
    for (let len = maxLength; len > 0; len--) {
        const substring = despacedWord.slice(i, i + len);
        if (substring in TRANSLITERATION_MAP) {
            match = TRANSLITERATION_MAP[substring];
            i += len;
            break;
        }
    }

    if (match) {
        transliterated += match;
    } else {
        const char = despacedWord[i];
        console.error(`Character of '${despacedWord}[${i}-${maxLength}]' not found in transliteration map: '${char}'.`);
        transliterated += char; // Add the character as-is
        i++;
    }
  }
  return transliterated
    .normalize("NFD")
    .toLowerCase();
}

export function searchifySearchTerm(word: string): string {
  return word
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[\s\p{P}]/gu, "")
    .toLowerCase();
}