import * as rt from "runtypes";
import { assertNever } from "../utils/assertNever";
import { GREEK_2024_NEA_THALASSA_1_VOCABULARY } from "./greek/2024/NeaThalassa1";
import * as fromGreekWord from "./greek/GreekWord";
import { LATIN_2023_PEGASUS_NOVUS_1_VOCABULARY } from "./latin/2023/PegasusNovus1";
import { LATIN_2024_PEGASUS_NOVUS_2_VOCABULARY } from "./latin/2024/PegasusNovus2";
import * as fromLatinWord from "./latin/LatinWord";
import { Language } from "./Language";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const VOCABULARIES = {
  '2023-pegasus-novus-1': LATIN_2023_PEGASUS_NOVUS_1_VOCABULARY,
  '2024-pegasus-novus-2': LATIN_2024_PEGASUS_NOVUS_2_VOCABULARY,
  '2024-nea-thalassa-1': GREEK_2024_NEA_THALASSA_1_VOCABULARY,
};

export type VocabularyCode = keyof typeof VOCABULARIES;

export function isValidVocabularyCode(code: string): code is VocabularyCode {
  return code in VOCABULARIES;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const BakedWord = rt.Record({
  dbIndex: rt.Number,
  logicalIndex: rt.Number,
  type: rt.String,
  foreign: rt.String,
  foreignSearch: rt.String,
  dutch: rt.String,
});
export type BakedWord = rt.Static<typeof BakedWord>;

export const BakedVocabulary = rt.Array(BakedWord);
export type BakedVocabulary = rt.Static<typeof BakedVocabulary>;

export function searchify(language: Language): ((str: string) => string) {
  switch(language) {
    case "la":
      return fromLatinWord.searchify;
    case "gr":
      return fromGreekWord.searchify;
    default:
      assertNever(language);
  }
}

export function bake(key: VocabularyCode): { language: Language, bakedVocabulary: BakedVocabulary } {
  switch (key) {
    case "2023-pegasus-novus-1": {
      const { language, vocabulary, } = VOCABULARIES[key];
      const searchifyFn = searchify(language);
      return {
        language,
        bakedVocabulary: vocabulary.map((word, arrayIndex) => ({
          dbIndex: arrayIndex,
          // In PN 1 from 2023 we just use the order in the vocabulary as the
          // index... in later textbooks we enter the index manually, to allow a
          // single index to occur multiple times (textbooks often do this to
          // allow for meanings or word types for a single entry).
          logicalIndex: arrayIndex,
          type: fromLatinWord.toDutchType(word),
          foreign: fromLatinWord.toForeignTitle(word),
          foreignSearch: searchifyFn(fromLatinWord.toForeignTitle(word)),
          dutch: fromLatinWord.toDutchTitle(word),
        })),
      };
    }
    case "2024-pegasus-novus-2": {
      const { language, vocabulary, } = VOCABULARIES[key];
      const searchifyFn = searchify(language);
      return {
        language,
        bakedVocabulary: vocabulary.map((word, arrayIndex) => ({
          dbIndex: arrayIndex,
          logicalIndex: word._2024_pegasus_novus_2.logicalIndex,
          type: fromLatinWord.toDutchType(word),
          foreign: fromLatinWord.toForeignTitle(word),
          foreignSearch: searchifyFn(fromLatinWord.toForeignTitle(word)),
          dutch: fromLatinWord.toDutchTitle(word),
        })),
      };
    }
    case "2024-nea-thalassa-1": {
      const { language, vocabulary, } = VOCABULARIES[key];
      const searchifyFn = searchify(language);
      return {
        language,
        bakedVocabulary: vocabulary.map((word, arrayIndex) => ({
          dbIndex: arrayIndex,
          logicalIndex: word._2024_nea_thalassa_1.logicalIndex,
          type: fromGreekWord.toDutchType(word),
          foreign: fromGreekWord.toForeignTitle(word),
          foreignSearch: searchifyFn(fromGreekWord.toForeignTitle(word)),
          dutch: fromGreekWord.toDutchTitle(word),
        })),
      };
    }
    default:
      assertNever(key);
  }
}