import React from "react";
import axios from "axios";
import * as rt from "runtypes";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const SessionServerData = rt.Union(
  rt.Record({
    logged_in: rt.Literal(true),
    email: rt.String,
    user_id: rt.Number
  }),
  rt.Record({
    logged_in: rt.Literal(false)
  })
);
type SessionServerData = rt.Static<typeof SessionServerData>;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface Session {
  email: string;
  user_id: number;
}

const SessionContext = React.createContext<Session | null>(null);

export const useSession = (): Session | null => {
  return React.useContext(SessionContext);
};

interface SessionProviderProps {
  children: React.ReactNode;
}

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const [ session, setSession ] = React.useState<Session | null>(null);

  React.useEffect(() => {
    // Can't make the effect async, so we define a function and call it.
    async function fetchSession() {
      const response = await axios.get('/session-info');
      if (response.status !== 200) {
        // TODO: handle.
      }
      const data = response.data;
      if (SessionServerData.guard(data)) {
        if (data.logged_in === true) {
          setSession({ email: data.email, user_id: data.user_id });
        } else {
          setSession(null);
        }
      } else {
        // TODO: handle.
      }
    }
    fetchSession();
  }, []);

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
};