import axios from "axios";
import * as rt from "runtypes";
import { VocabularyCode } from "../data/Data";
import { assertNever } from "../utils/assertNever";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const Score = rt.Union(
  rt.Literal(0),
  rt.Literal(1),
  rt.Literal(2),
  rt.Literal(3),
  rt.Literal(4),
);
export type Score = rt.Static<typeof Score>;

export function scoreToColor(score: Score): string {
  switch(score) {
    case 0: return "secondary";
    case 1: return "danger";
    case 2: return "warning";
    case 3: return "success";
    case 4: return "primary";
    default: assertNever(score);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const ScoreMap = rt.Dictionary(Score, rt.Number);
export type ScoreMap = rt.Static<typeof ScoreMap>;

export const IndicesByScoreMap = rt.Dictionary(rt.Array(rt.Number), Score);
export type IndicesByScoreMap = rt.Static<typeof IndicesByScoreMap>;

export function createIndicesByScoreMap(): IndicesByScoreMap {
  return {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
  };
}

export async function forCode(deckCode: VocabularyCode): Promise<[ ScoreMap, IndicesByScoreMap ]> {
  try {
    const response = await axios.get(`/decks/${deckCode}/scores`);
    const { data: forward } = response;
    if (ScoreMap.guard(forward)) {
      const reverse = createIndicesByScoreMap();
      Object.entries(forward).forEach(([ index, score ]) => {
        reverse[score].push(Number(index));
      });
      return [ forward, reverse ];
    } else {
      // TODO: handle properly.
      return [ {}, createIndicesByScoreMap() ];
    }
  } catch (error) {
    // TODO: handle properly.
    console.error(error);
    return [ {}, createIndicesByScoreMap() ];
  }
}

export async function update(deckCode: VocabularyCode, cardIndex: number, s: number): Promise<void> {
  try {
    await axios.patch(`/decks/${deckCode}/scores/${cardIndex}`, { s });
  } catch (error) {
    // TODO: handle properly.
    console.error(error);
  }
}