import { useEffect, useState } from "react";
import { Sex, WordFinal } from "../../data/WordBase.tsx";
import { LatinAdjective, toDutchTitle } from "../../data/latin/LatinWord.tsx";
import "../WordFull.scss";
import "./LatinAdjectiveFull.scss";
import { merge } from "../../utils/merge.tsx";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_ADJECTIVE_MODELS: Record<LatinAdjective["klass"], string> = {
  1: "-us, -a, -um",
  "1r": "-er, -era, -erum",
  "1er": "-er, -ra, -rum",
  2: "tweede klasse",
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function nomS(sex: Sex, adjective: LatinAdjective): WordFinal {
  return adjective.hasSingular
    ? adjective?.explicitDeclensions?.nomS?.[sex]
    : undefined;
}

function nomSComplete(adjective: LatinAdjective): string {
  return adjective.hasSingular
    ? [
        merge(nomS("m", adjective), "/"),
        merge(nomS("f", adjective), "/"),
        merge(nomS("n", adjective), "/"),
      ].filter(x => x !== "").join(", ")
    : "";
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_ADJECTIVE_NOMINATIVE_PLURAL: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "ī",
    "f": "ae",
    "n": "a",
  },
  "1r": {
    "m": "ī",
    "f": "ae",
    "n": "a",
  },
  "1er": {
    "m": "ī",
    "f": "ae",
    "n": "a",
  },
  2: {
    "m": "ēs",
    "f": "ēs",
    "n": "ia",
  },
};

export function nomP(sex: Sex, adjective: LatinAdjective): WordFinal {
  return adjective.hasPlural
    ? adjective?.explicitDeclensions?.nomP?.[sex] ?? adjective.root + LATIN_ADJECTIVE_NOMINATIVE_PLURAL[adjective.klass][sex]
    : undefined;
}

function nomPComplete(adjective: LatinAdjective): string {
  return adjective.hasPlural
    ? [
        merge(nomP("m", adjective), "/"),
        merge(nomP("f", adjective), "/"),
        merge(nomP("n", adjective), "/"),
      ].filter(x => x !== "").join(", ")
    : "";
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_ADJECTIVE_ACCUSATIVE_SINGULAR: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "um",
    "f": "am",
    "n": "um",
  },
  "1r": {
    "m": "um",
    "f": "am",
    "n": "um",
  },
  "1er": {
    "m": "um",
    "f": "am",
    "n": "um",
  },
  2: {
    "m": "em",
    "f": "em",
    "n": "e",
  },
};

export function accS(sex: Sex, adjective: LatinAdjective): WordFinal {
  return adjective.hasSingular
    ? adjective?.explicitDeclensions?.accS?.[sex] ?? adjective.root + LATIN_ADJECTIVE_ACCUSATIVE_SINGULAR[adjective.klass][sex]
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_ADJECTIVE_ACCUSATIVE_PLURAL: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "ōs",
    "f": "ās",
    "n": "a",
  },
  "1r": {
    "m": "ōs",
    "f": "ās",
    "n": "a",
  },
  "1er": {
    "m": "ōs",
    "f": "ās",
    "n": "a",
  },
  2: {
    "m": "ēs",
    "f": "ēs",
    "n": "ia",
  },
};

export function accP(sex: Sex, adjective: LatinAdjective): WordFinal {
  return adjective.hasPlural
    ? adjective?.explicitDeclensions?.accP?.[sex] ?? adjective.root + LATIN_ADJECTIVE_ACCUSATIVE_PLURAL[adjective.klass][sex]
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_ADJECTIVE_GENITIVE_SINGULAR: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "ī",
    "f": "ae",
    "n": "ī",
  },
  "1r": {
    "m": "ī",
    "f": "ae",
    "n": "ī",
  },
  "1er": {
    "m": "ī",
    "f": "ae",
    "n": "ī",
  },
  2: {
    "m": "is",
    "f": "is",
    "n": "is",
  },
};

export function genS(sex: Sex, adjective: LatinAdjective): WordFinal {
  return adjective.hasSingular
    ? adjective?.explicitDeclensions?.genS?.[sex] ?? adjective.root + LATIN_ADJECTIVE_GENITIVE_SINGULAR[adjective.klass][sex]
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_ADJECTIVE_GENITIVE_PLURAL: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "ōrum",
    "f": "ārum",
    "n": "ōrum",
  },
  "1r": {
    "m": "ōrum",
    "f": "ārum",
    "n": "ōrum",
  },
  "1er": {
    "m": "ōrum",
    "f": "ārum",
    "n": "ōrum",
  },
  2: {
    "m": "ium",
    "f": "ium",
    "n": "ium",
  },
};

export function genP(sex: Sex, adjective: LatinAdjective): WordFinal {
  return adjective.hasPlural
    ? adjective?.explicitDeclensions?.genP?.[sex] ?? adjective.root + LATIN_ADJECTIVE_GENITIVE_PLURAL[adjective.klass][sex]
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_ADJECTIVE_DATIVE_SINGULAR: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "ō",
    "f": "ae",
    "n": "ō",
  },
  "1r": {
    "m": "ō",
    "f": "ae",
    "n": "ō",
  },
  "1er": {
    "m": "ō",
    "f": "ae",
    "n": "ō",
  },
  2: {
    "m": "ī",
    "f": "ī",
    "n": "ī",
  },
};

export function datS(sex: Sex, adjective: LatinAdjective): WordFinal {
  return adjective.hasSingular
    ? adjective?.explicitDeclensions?.datS?.[sex] ?? adjective.root + LATIN_ADJECTIVE_DATIVE_SINGULAR[adjective.klass][sex]
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_ADJECTIVE_DATIVE_PLURAL: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "īs",
    "f": "īs",
    "n": "īs",
  },
  "1r": {
    "m": "īs",
    "f": "īs",
    "n": "īs",
  },
  "1er": {
    "m": "īs",
    "f": "īs",
    "n": "īs",
  },
  2: {
    "m": "ibus",
    "f": "ibus",
    "n": "ibus",
  },
};

export function datP(sex: Sex, adjective: LatinAdjective): WordFinal {
  return adjective.hasPlural
    ? adjective?.explicitDeclensions?.datP?.[sex] ?? adjective.root + LATIN_ADJECTIVE_DATIVE_PLURAL[adjective.klass][sex]
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_ADJECTIVE_ABLATIVE_SINGULAR: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "ō",
    "f": "ā",
    "n": "ō",
  },
  "1r": {
    "m": "ō",
    "f": "ā",
    "n": "ō",
  },
  "1er": {
    "m": "ō",
    "f": "ā",
    "n": "ō",
  },
  2: {
    "m": "ī",
    "f": "ī",
    "n": "ī",
  },
};

export function ablS(sex: Sex, adjective: LatinAdjective): WordFinal {
  return adjective.hasSingular
    ? adjective?.explicitDeclensions?.ablS?.[sex] ?? adjective.root + LATIN_ADJECTIVE_ABLATIVE_SINGULAR[adjective.klass][sex]
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_ADJECTIVE_ABLATIVE_PLURAL: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "īs",
    "f": "īs",
    "n": "īs",
  },
  "1r": {
    "m": "īs",
    "f": "īs",
    "n": "īs",
  },
  "1er": {
    "m": "īs",
    "f": "īs",
    "n": "īs",
  },
  2: {
    "m": "ibus",
    "f": "ibus",
    "n": "ibus",
  },
};

export function ablP(sex: Sex, adjective: LatinAdjective): WordFinal {
  return adjective.hasPlural
    ? adjective?.explicitDeclensions?.ablP?.[sex] ?? adjective.root + LATIN_ADJECTIVE_ABLATIVE_PLURAL[adjective.klass][sex]
    : undefined;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface LatinAdjectiveFullProps {
  word: LatinAdjective;
}

function LatinAdjectiveFull({ word }: LatinAdjectiveFullProps) {
  const [ singularDeclension, setSingularDeclension ] = useState<boolean>(false);
  const [ pluralDeclension, setPluralDeclension ] = useState<boolean>(false);

  useEffect(() => {
    setSingularDeclension(false);
    setPluralDeclension(false);
  }, [ word ]);

  const toggleSingularDeclension: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    setSingularDeclension(!singularDeclension);
  }

  const togglePluralDeclension: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    setPluralDeclension(!pluralDeclension);
  }

  const { hasSingular, hasPlural, klass, } = word;

  const singularDeclensionTable = hasSingular ?
    <table className="table table-sm">
      <tbody>
      <tr>
        <th colSpan={4} className="title">
          <a href="#" onClick={toggleSingularDeclension}>{singularDeclension ? "▼" : "▶"} Enkelvoud</a>
        </th>
      </tr>
      {
        singularDeclension ?
          <>
            <tr>
              <th className="sex"></th>
              <th className="sex">M.</th>
              <th className="sex">V.</th>
              <th className="sex">O.</th>
            </tr>
            <tr>
              <th className="declension">Nominatief</th>
              <td>{merge(nomS("m", word))}</td>
              <td>{merge(nomS("f", word))}</td>
              <td>{merge(nomS("n", word))}</td>
            </tr>
            <tr>
              <th className="declension">Accusatief</th>
              <td>{merge(accS("m", word))}</td>
              <td>{merge(accS("f", word))}</td>
              <td>{merge(accS("n", word))}</td>
            </tr>
            <tr>
              <th className="declension">Genitief</th>
              <td>{merge(genS("m", word))}</td>
              <td>{merge(genS("f", word))}</td>
              <td>{merge(genS("n", word))}</td>
            </tr>
            <tr>
              <th className="declension">Datief</th>
              <td>{merge(datS("m", word))}</td>
              <td>{merge(datS("f", word))}</td>
              <td>{merge(datS("n", word))}</td>
            </tr>
            <tr>
              <th className="declension">Ablatief</th>
              <td>{merge(ablS("m", word))}</td>
              <td>{merge(ablS("f", word))}</td>
              <td>{merge(ablS("n", word))}</td>
            </tr>
          </> : null
      }
      </tbody>
    </table> : null;

  const pluralDeclensionTable = hasPlural ?
    <table className="table table-sm">
      <tbody>
      <tr>
        <th colSpan={4} className="title">
          <a href="#" onClick={togglePluralDeclension}>{pluralDeclension ? "▼" : "▶"} Meervoud</a>
        </th>
      </tr>
      {
        pluralDeclension ?
          <>
            <tr>
              <th className="sex"></th>
              <th className="sex">M.</th>
              <th className="sex">V.</th>
              <th className="sex">O.</th>
            </tr>
            <tr>
              <th className="declension">Nominatief</th>
              <td>{merge(nomP("m", word))}</td>
              <td>{merge(nomP("f", word))}</td>
              <td>{merge(nomP("n", word))}</td>
            </tr>
            <tr>
              <th className="declension">Accusatief</th>
              <td>{merge(accP("m", word))}</td>
              <td>{merge(accP("f", word))}</td>
              <td>{merge(accP("n", word))}</td>
            </tr>
            <tr>
              <th className="declension">Genitief</th>
              <td>{merge(genP("m", word))}</td>
              <td>{merge(genP("f", word))}</td>
              <td>{merge(genP("n", word))}</td>
            </tr>
            <tr>
              <th className="declension">Datief</th>
              <td>{merge(datP("m", word))}</td>
              <td>{merge(datP("f", word))}</td>
              <td>{merge(datP("n", word))}</td>
            </tr>
            <tr>
              <th className="declension">Ablatief</th>
              <td>{merge(ablP("m", word))}</td>
              <td>{merge(ablP("f", word))}</td>
              <td>{merge(ablP("n", word))}</td>
            </tr>
          </> : null
      }
      </tbody>
    </table> : null

  return (
    <div className="word-full adjective">
      <div className="word-full-title">
        {hasSingular ? nomSComplete(word) : (hasPlural ? nomPComplete(word) : "")}
        <span className="word-full-model float-end">{LATIN_ADJECTIVE_MODELS[klass]}</span>
      </div>
      <div className="word-full-conjugation adjective-full-conjugation">
        {singularDeclensionTable}
        {pluralDeclensionTable}
      </div>
      <div className="word-full-translation">
        {toDutchTitle(word)}
      </div>
    </div>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export default LatinAdjectiveFull;