import { Link, Outlet } from "react-router-dom";
import { SessionLinks } from "../components/SessionLinks";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

function Root() {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link to="/" className="navbar-brand">
            Vocabularium
            <i className="bi bi-house-fill" style={{ marginLeft: "1.5rem" }} />
          </Link>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item"><Link to="/data/2023-pegasus-novus-1" className="nav-link">PN 1</Link></li>
              <li className="nav-item"><Link to="/data/2024-pegasus-novus-2" className="nav-link">PN 2</Link></li>
              <li className="nav-item"><Link to="/data/2024-nea-thalassa-1" className="nav-link">NT 1</Link></li>
            </ul>
            <div className="d-flex">
              <SessionLinks />
              <div style={{ marginLeft: "1em" }}>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item navbar-text">
                    Cum &nbsp;
                    <span className="text-danger"><i className="bi bi-heart-fill" /></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="container-md">
        <Outlet />
      </div>
    </>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export default Root;