import { BakedVocabulary, VocabularyCode } from "../../data/Data.tsx";
import { GreekWord } from "../../data/greek/GreekWord.tsx";
import { assertNever } from "../../utils/assertNever.tsx";
import "../Practice.scss";
import { Practice } from "../Practice.tsx";
import { Gr } from "../T.tsx";
import GreekDefaultFull from "./GreekDefaultFull.tsx";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

function GreekQuestionBody({ word }: { word: GreekWord }): React.ReactNode {
  switch (word.type) {
    case "adjective":
    case "adverb":
    case "conjunction":
    case "countingWord":
    case "demonstrativePronoun":
    case "noun":
    case "numeral":
    case "impersonalVerb":
    case "interrogativePronoun":
    case "prefix":
    case "prepositionAccusative":
    case "prepositionDative":
    case "prepositionGenitive":
    case "particle":
    case "suffix":
    case "verb": {
      return <div><Gr>{word.ground}</Gr></div>;
    }
    default:
      return assertNever(word);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

function GreekAnswerBody({ word }: { word: GreekWord }): React.ReactNode {
  switch (word.type) {
    case "adjective":
    case "adverb":
    case "conjunction":
    case "countingWord":
    case "demonstrativePronoun":
    case "noun":
    case "numeral":
    case "impersonalVerb":
    case "interrogativePronoun":
    case "prefix":
    case "prepositionAccusative":
    case "prepositionDative":
    case "prepositionGenitive":
    case "particle":
    case "suffix":
    case "verb": {
      return <GreekDefaultFull word={word} />;
    }
    default:
      return assertNever(word);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface GreekPracticeProps<W extends GreekWord> {
  code: VocabularyCode,
  vocabulary: W[];
  bakedVocabulary: BakedVocabulary;
  answerBodyFn?: (word: W) => React.ReactNode;
}

export function GreekPractice<W extends GreekWord>({
  code,
  vocabulary,
  bakedVocabulary,
  answerBodyFn
}: GreekPracticeProps<W>) {
  return <Practice code={code}
                   questionComponent={GreekQuestionBody}
                   answerComponent={GreekAnswerBody}
                   vocabulary={vocabulary}
                   bakedVocabulary={bakedVocabulary}
                   answerBodyFn={answerBodyFn} />;
}